import React from 'react'

import { SvgProps } from '@components/svg/types'

const BuildingBlocksSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
  fill,
  stroke,
  strokeColor,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    left: '0deg',
    right: '180deg',
    top: '90deg',
    down: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '37'}
      height={height || '20'}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'none'}
      stroke={strokeColor || 'none'}
      viewBox="0 0 37 20"
      strokeWidth={stroke}
    >
      <path
        d="M2 19V8H11V19"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19H12.0005"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.36304 7.79309H11.6374"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.98203 10H4V10.982H4.98203V10Z" fill="#606060" />
      <path d="M6.96409 10H5.98206V10.982H6.96409V10Z" fill="#606060" />
      <path d="M8.94615 10H7.96411V10.982H8.94615V10Z" fill="#606060" />
      <path d="M4.98203 12.9821H4V13.9641H4.98203V12.9821Z" fill="#606060" />
      <path
        d="M6.96409 12.9821H5.98206V13.9641H6.96409V12.9821Z"
        fill="#606060"
      />
      <path
        d="M8.94615 12.9821H7.96411V13.9641H8.94615V12.9821Z"
        fill="#606060"
      />
      <path d="M4.98203 15.9641H4V16.9461H4.98203V15.9641Z" fill="#606060" />
      <path
        d="M6.96409 15.9641H5.98206V16.9461H6.96409V15.9641Z"
        fill="#606060"
      />
      <path
        d="M8.94615 15.9641H7.96411V16.9461H8.94615V15.9641Z"
        fill="#606060"
      />
      <path
        d="M14 19V4H23V19"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 19H24.0005"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.363 3.79309H23.6374"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.982 9H16V9.98203H16.982V9Z" fill="#606060" />
      <path d="M16.982 6H16V6.98203H16.982V6Z" fill="#606060" />
      <path d="M18.9641 9H17.9821V9.98203H18.9641V9Z" fill="#606060" />
      <path d="M18.9641 6H17.9821V6.98203H18.9641V6Z" fill="#606060" />
      <path d="M20.9461 9H19.9641V9.98203H20.9461V9Z" fill="#606060" />
      <path d="M20.9461 6H19.9641V6.98203H20.9461V6Z" fill="#606060" />
      <path d="M16.982 11.9821H16V12.9641H16.982V11.9821Z" fill="#606060" />
      <path
        d="M18.9641 11.9821H17.9821V12.9641H18.9641V11.9821Z"
        fill="#606060"
      />
      <path
        d="M20.9461 11.9821H19.9641V12.9641H20.9461V11.9821Z"
        fill="#606060"
      />
      <path d="M16.982 14.9641H16V15.9461H16.982V14.9641Z" fill="#606060" />
      <path
        d="M18.9641 14.9641H17.9821V15.9461H18.9641V14.9641Z"
        fill="#606060"
      />
      <path
        d="M20.9461 14.9641H19.9641V15.9461H20.9461V14.9641Z"
        fill="#606060"
      />
      <path
        d="M26 19V1H35V19"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 19H36.0005"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.363 0.793091H35.6374"
        stroke="#606060"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M28.982 9H28V9.98203H28.982V9Z" fill="#606060" />
      <path d="M28.982 6H28V6.98203H28.982V6Z" fill="#606060" />
      <path d="M28.982 3H28V3.98203H28.982V3Z" fill="#606060" />
      <path d="M30.9641 9H29.9821V9.98203H30.9641V9Z" fill="#606060" />
      <path d="M30.9641 6H29.9821V6.98203H30.9641V6Z" fill="#606060" />
      <path d="M30.9641 3H29.9821V3.98203H30.9641V3Z" fill="#606060" />
      <path d="M32.9461 9H31.9641V9.98203H32.9461V9Z" fill="#606060" />
      <path d="M32.9461 6H31.9641V6.98203H32.9461V6Z" fill="#606060" />
      <path d="M32.9461 3H31.9641V3.98203H32.9461V3Z" fill="#606060" />
      <path d="M28.982 11.9821H28V12.9641H28.982V11.9821Z" fill="#606060" />
      <path
        d="M30.9641 11.9821H29.9821V12.9641H30.9641V11.9821Z"
        fill="#606060"
      />
      <path
        d="M32.9461 11.9821H31.9641V12.9641H32.9461V11.9821Z"
        fill="#606060"
      />
      <path d="M28.982 14.9641H28V15.9461H28.982V14.9641Z" fill="#606060" />
      <path
        d="M30.9641 14.9641H29.9821V15.9461H30.9641V14.9641Z"
        fill="#606060"
      />
      <path
        d="M32.9461 14.9641H31.9641V15.9461H32.9461V14.9641Z"
        fill="#606060"
      />
    </svg>
  )
}

export default BuildingBlocksSvg
