import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface GalleryData {
  type: 'image' | 'video'
  videoSrc?: string
  imageSrc?: string
  showcaseRoom: string
}

export interface FloorPlateGalleryData {
  level: string
  block: string
  assets: Array<GalleryData>
}

export interface FloorPlateGalleryPayload extends PayloadInterface {
  data: Array<FloorPlateGalleryData>
}

type FloorPlateGalleryType = {
  projectName: string
}

export const floorPlateGalleryApi = createApi({
  reducerPath: 'floorPlateGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getFloorPlateGallery: builder.query<
      FloorPlateGalleryPayload,
      FloorPlateGalleryType
    >({
      query: (params: FloorPlateGalleryType) => ({
        url: `/v1/${params.projectName}/floor-plates${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  floorPlateGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetFloorPlateGalleryQuery } = floorPlateGalleryApi
