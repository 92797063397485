import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  Configurations as ConfigurationInterface,
  RootStateTypeExtra,
  UnitFilterInterface,
} from '../types'

export interface RangeInterface {
  min: number
  max: number
}

export interface UnitFilterInterfaceOptional {
  range?: RangeInterface
  configurations?: ConfigurationInterface
  aspect?: string
  showAvailable?: boolean
  apply?: boolean
}

const unitFilter = localStorage.getItem('unitFilter')

const initialFilter = {
  range: {
    min: 0,
    max: 0,
  },
  aspect: 'All',
  configurations: {
    bedroom: 0,
    study: 0,
    bathroom: 0,
    powderRoom: 0,
    carpark: 0,
  },
  showAvailable: false,
  apply: false,
} as UnitFilterInterface

let initialState = { ...initialFilter }

if (unitFilter) {
  initialState = JSON.parse(unitFilter)
}

export const unitFilterSlice = createSlice({
  name: 'unitFilter',
  initialState,
  reducers: {
    setFilter: (
      state: UnitFilterInterface,
      action: PayloadAction<UnitFilterInterfaceOptional>
    ) => {
      localStorage.setItem(
        'unitFilter',
        JSON.stringify({ ...state, ...action.payload })
      )
      return {
        ...state,
        ...action.payload,
      }
    },
    resetFilter: () => {
      localStorage.setItem('unitFilter', JSON.stringify(initialFilter))
      return initialFilter
    },
    removeFilter: () => {
      localStorage.removeItem('unitFilter')
      return initialFilter
    },
  },
})

export const { setFilter, resetFilter, removeFilter } = unitFilterSlice.actions

export const selectFilteredUnit = (state: RootStateTypeExtra) =>
  state.unitFilter

export default unitFilterSlice.reducer
