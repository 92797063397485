import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

const currentOfflineMode = localStorage.getItem('offlineMode') === 'true'
const currentFirebasePort = localStorage.getItem('firebasePort')
const currentAssetPort = localStorage.getItem('assetPort')
const currentRoom = localStorage.getItem('room')

export interface AppConfigInterface {
  offlineMode: boolean
  firebasePort: string
  assetPort: string
  room: string
}

const initialState = {
  offlineMode: currentOfflineMode || false,
  firebasePort: currentFirebasePort || '',
  assetPort: currentAssetPort || '',
  room: currentRoom || '',
} as AppConfigInterface

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setOfflineMode: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem('offlineMode', JSON.stringify(action.payload))
      return {
        ...state,
        offlineMode: action.payload,
      }
    },
    setFirebasePort: (state, action: PayloadAction<string>) => {
      localStorage.setItem('firebasePort', action.payload)
      return {
        ...state,
        firebasePort: action.payload,
      }
    },
    setAssetPort: (state, action: PayloadAction<string>) => {
      localStorage.setItem('assetPort', action.payload)
      return {
        ...state,
        ...{ assetPort: action.payload },
      }
    },
    setRoom: (state, action: PayloadAction<string>) => {
      localStorage.setItem('room', action.payload)
      return {
        ...state,
        ...{ room: action.payload },
      }
    },
    removeAppConfig: (state) => {
      localStorage.removeItem('offlineMode')
      localStorage.removeItem('firebasePort')
      localStorage.removeItem('assetPort')
      localStorage.removeItem('room')
      return {
        ...state,
        offlineMode: false,
        firebasePort: '',
        assetPort: '',
        room: '',
      }
    },
  },
})

export const {
  setOfflineMode,
  setFirebasePort,
  setAssetPort,
  setRoom,
  removeAppConfig,
} = appConfigSlice.actions

export const selectOfflineMode = (state: RootStateTypeExtra) => state.appConfig

export default appConfigSlice.reducer
