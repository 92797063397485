import React from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { connect, useDispatch } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import Routes from '@src/routes'

import { setLoadingIndicatorVisibility } from '@store/actionSlices/loadingIndicator'
import type {
  ProjectIdentity,
  ReleaseVersionInterface,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import ReleaseHandler from '@components/release-handler'

import ConnectionRefused from '@pages/errors'

import { useRenewUserTokenMutation } from '@api/authentication'

import useConnectionIndicator from '@utilities/connection-indicator'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import { hasToken } from '@utilities/helper'
import LocalStorageCleanerUtil from '@utilities/local-storage-cleaner-util'
import { notifySuccess } from '@utilities/notifier'
import styleUtil from '@utilities/style-util'

import { TokenPayloadInterface, setToken } from './store/actionSlices/token'

interface FirebaseStateType extends RootStateFirebase {
  devsuite: SessionMap
  map: SessionMap
  showcaseReleaseInfoFirebase: ReleaseVersionInterface
  projectIdentity: ProjectIdentity
  token: TokenPayloadInterface
  isOnline: boolean
}

const App = ({
  devsuite,
  map,
  showcaseReleaseInfoFirebase,
  projectIdentity,
  token,
  isOnline,
}: FirebaseStateType) => {
  styleUtil()

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
    map,
  })

  const [isIframe, setIsIframe] = React.useState(false)
  const [session, setSession] = React.useState<Array<string>>([])
  const [tokenRenewalIntervalId, setTokenRenewalIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()
  const [tokenRenewalState, setTokenRenewalState] = React.useState(false)

  const [
    renewUserToken,
    {
      isLoading: reefreshTokenIsLoading,
      data: refreshTokenData,
      error: refreshTokenError,
    },
  ] = useRenewUserTokenMutation()

  const { connected, sessionOwners: firebaseSessionOwners } = devsuite || {}

  const dispatch = useDispatch()
  useConnectionIndicator({ token, isOnline })

  LocalStorageCleanerUtil({ token })

  const handleTokenRenewalProcess = () => {
    if (!hasToken(token)) {
      return
    }

    const {
      access_token: { expires_at: expiresAt },
      refresh_token: { token: refreshToken },
    } = token

    const currentTime = new Date().getTime()
    const tokenExpiryTime = new Date(expiresAt).getTime()
    const timeDifference = tokenExpiryTime - currentTime
    const timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24)
    if (timeDifferenceInDays <= 7) {
      renewUserToken({ refresh_token: refreshToken })
    }
  }

  const resetTokenRenewalInterval = () => {
    if (tokenRenewalIntervalId) {
      clearInterval(tokenRenewalIntervalId)
      setTokenRenewalIntervalId(undefined)
    }
  }

  const setTokenRenewalInterval = () => {
    if (!hasToken(token)) {
      resetTokenRenewalInterval()
      return
    }

    if (!tokenRenewalIntervalId) {
      setTokenRenewalIntervalId(
        setInterval(() => setTokenRenewalState(true), 1000 * 86400)
      )
    }
  }

  const handleAppId = (appIdleKey: string) => {
    if (appIdleKey) {
      firebaseControlQuery.update({
        [`appIdleKey`]: '',
      })
    }
  }

  const handleCientCheck = (client: boolean) => {
    if (!client) {
      firebaseControlQuery.update({
        [`client.showcase`]: true,
      })
    }
  }

  React.useEffect(() => {
    if (!devsuite) {
      return
    }
    if (!connected) {
      setSession([])
      return
    }
    firebaseSessionOwners.forEach((res) => {
      if (!session.find((ses: string) => res.name === ses)) {
        if (res.name && res.email) {
          notifySuccess(`${res.name} is Connected`)
          setSession([...session, res.name])
        }
      }
    })
  }, [firebaseSessionOwners])

  React.useEffect(() => {
    dispatch(setLoadingIndicatorVisibility(reefreshTokenIsLoading))
  }, [reefreshTokenIsLoading])

  React.useEffect(() => {
    if (refreshTokenData) {
      const {
        data: { user },
      } = refreshTokenData
      dispatch(setToken(user as TokenPayloadInterface))
    }
  }, [refreshTokenData])

  React.useEffect(() => {
    if (refreshTokenError) {
      console.error('Failed to renew the user token.', refreshTokenError)
    }
  }, [refreshTokenError])

  React.useEffect(() => {
    setTokenRenewalInterval()
  }, [token])

  React.useEffect(() => {
    if (tokenRenewalState) {
      handleTokenRenewalProcess()
      setTokenRenewalState(false)
    }
  }, [tokenRenewalState])

  React.useEffect(() => {
    setIsIframe(window.self !== window.top)
  }, [])

  React.useEffect(() => {
    if (devsuite) {
      const {
        appIdleKey,
        client: { showcase },
      } = devsuite

      handleAppId(appIdleKey)
      handleCientCheck(showcase)
    }
  }, [devsuite])

  if (isIframe) {
    return <ConnectionRefused />
  }

  return (
    <>
      <ReactNotifications />
      <Routes {...{ session: devsuite, mapdefault: map, projectIdentity }} />
      {hasToken(token) && showcaseReleaseInfoFirebase && (
        <ReleaseHandler
          showcaseReleaseInfoFirebase={showcaseReleaseInfoFirebase}
        />
      )}
    </>
  )
}

export default compose(
  connect(
    ({
      projectIdentity,
      firestore: {
        data: { devsuite, map, showcaseReleaseInfoFirebase },
      },
      token,
      connectionIndicator: { isOnline },
    }: RootStateFirebase) => ({
      devsuite,
      map,
      showcaseReleaseInfoFirebase,
      projectIdentity,
      token,
      isOnline,
    })
  ),
  firestoreConnect(({ projectIdentity }: RootStateFirebase) => {
    const FirebaseQuery: Array<any> = [
      {
        collection: 'session-base',
        doc: 'map',
        storeAs: 'map',
      },
      {
        collection: 'release',
        doc: 'showcase',
        storeAs: 'showcaseReleaseInfoFirebase',
      },
    ]
    const { projectName, sessionId, lightMap } = projectIdentity
    if (projectName) {
      let showcaseQury = FirebaseQuery

      if (sessionId) {
        showcaseQury = [
          ...showcaseQury,
          {
            collection: 'devsuite',
            doc: projectName,
            subcollections: [{ collection: 'session', doc: sessionId }],
            storeAs: 'devsuite',
          },
        ]
      }
      if (lightMap) {
        showcaseQury = [
          ...showcaseQury,
          {
            collection: 'devsuite',
            doc: projectName,
            subcollections: [{ collection: 'light-map', doc: lightMap }],
            storeAs: 'lightMap',
          },
        ]
      }
      return showcaseQury
    }
    return FirebaseQuery
  })
)(App) as React.ComponentType
