import { Dot } from 'pure-react-carousel'
import React from 'react'

export interface PlainDotInterface {
  index: number
  currentSlide: number
  setCurrentSlide: (arg: number) => void
  className?: string
}

const PlainDot = ({
  index,
  currentSlide,
  setCurrentSlide,
  className = '',
}: PlainDotInterface) => (
  <Dot
    className={`m-2 w-2 transform bg-white transition duration-300 ease-in-out drop-shadow-70 hover:scale-110 ${
      currentSlide === index ? 'h-20' : 'h-12 opacity-50'
    } ${className}`}
    slide={index}
    onClick={() => setCurrentSlide(index)}
  />
)

export default PlainDot
