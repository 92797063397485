import { fabric } from 'fabric'

import { Polygon as InteractivePolygon } from '@api/interactive-plan'

import LabelUtil from './label'

export interface CanvasData {
  image: string
  polygons: Array<Polygon>
}

declare global {
  interface Window {
    canvasData: CanvasData
    hasLabel?: boolean
  }
}

export interface CustomObject extends fabric.Object {
  edit?: boolean
  points?: any
}

export interface CustomGroup extends fabric.Group {
  arrayIndex: number
  onGroupSelect?: () => void
  id: string
  label?: string
}

export interface Polygon extends InteractivePolygon {
  markerColour?: string
  activeByDefault?: boolean
  onClick?: () => void
  postFix?: string | number
  disabled?: boolean
}

const Events = ({ labelPrefix }: { labelPrefix: string }) => {
  const labelUtil = LabelUtil()

  const removeAllLabelGroups = (canvas: fabric.Canvas | null) => {
    if (canvas?.getObjects()) {
      canvas.getObjects().forEach((obj) => {
        if (obj.type === 'labelGroup') {
          canvas?.remove(obj)
          canvas?.renderAll()
        }
      })
    }
  }

  const getPolygonLabel = (poly: Polygon) => {
    if (poly?.label) {
      return poly.label
    }
    return `${labelPrefix || ''} ${poly?.groupId || 'No Id'} ${
      poly?.postFix || ''
    }`
  }

  const mouseMove = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (window.hasLabel) {
      if (e.target?.isType('group')) {
        const activeGroup = e.target as CustomGroup
        const selectedIndex = activeGroup?.arrayIndex
        const contextPointer = canvas?.getPointer(e.e)
        removeAllLabelGroups(canvas)
        const label = getPolygonLabel(window.canvasData.polygons[selectedIndex])
        const labelGroup = labelUtil.createLabel({
          label,
          groupOverride: {
            left: contextPointer?.x,
            top: (contextPointer?.y || 0) - 50,
          } as fabric.Group,
        })
        canvas?.add(labelGroup)
        canvas?.renderAll()
      }
    }
  }

  const mouseOver = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (e.target?.isType('group')) {
      const activeGroup = e.target as CustomGroup
      const selectedIndex = activeGroup?.arrayIndex
      const noPolyHover =
        !window.canvasData.polygons[selectedIndex]?.noPolyHover
      activeGroup?.forEachObject((obj) => {
        if (obj.name === 'PolyGroup') {
          const group = obj as fabric.Group
          group.forEachObject((innerObj) => {
            if (innerObj?.isType('polygon') && noPolyHover) {
              innerObj?.animate(
                {
                  fill:
                    window.canvasData.polygons[selectedIndex]?.color ||
                    'rgba(255, 255, 255, 0.1)',
                  opacity: 0.7,
                },
                {
                  onChange: canvas?.renderAll.bind(canvas),
                  duration: 300,
                  easing: fabric.util.ease.easeInSine,
                }
              )
            }
          })
        } else if (obj.isType('group')) {
          const group = obj as fabric.Group
          let maxRadius = 0

          group.forEachObject((innerObj) => {
            if (innerObj?.name === 'outerCircle') {
              const foundCircle = innerObj as fabric.Circle
              maxRadius = (foundCircle?.radius || 0) / 2
            }
          })

          group.forEachObject((innerObj) => {
            if (innerObj?.name === 'innerCircle') {
              const circle = innerObj as fabric.Circle
              circle?.animate(
                { radius: maxRadius || 11 },
                {
                  onChange: canvas?.renderAll.bind(canvas),
                  duration: 300,
                  easing: fabric.util.ease.easeInSine,
                }
              )
            }
          })
        }
      })
    }
  }

  const mouseOut = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (e.target?.isType('group')) {
      const activeGroup = e.target as CustomGroup
      const selectedIndex = activeGroup?.arrayIndex
      const noPolyHover =
        !window.canvasData.polygons[selectedIndex]?.noPolyHover
      if (window.hasLabel) {
        removeAllLabelGroups(canvas)
      }
      activeGroup?.forEachObject((obj) => {
        if (obj.name === 'PolyGroup') {
          const group = obj as fabric.Group
          group.forEachObject((innerObj) => {
            if (innerObj?.isType('polygon') && noPolyHover) {
              innerObj?.animate(
                {
                  fill: window.canvasData.polygons[selectedIndex]
                    ?.activeByDefault
                    ? window.canvasData.polygons[selectedIndex]?.color
                    : 'rgba(255, 255, 255, 0.1)',
                  opacity: window.canvasData.polygons[selectedIndex]
                    ? 0.7
                    : 0.1,
                },
                {
                  onChange: canvas?.renderAll.bind(canvas),
                  duration: 300,
                  easing: fabric.util.ease.easeInSine,
                }
              )
            }
          })
        } else if (obj.isType('group')) {
          const group = obj as fabric.Group
          group.forEachObject((innerObj) => {
            if (innerObj?.isType('circle')) {
              const circle = innerObj as fabric.Circle
              if (circle.radius !== 18) {
                circle?.animate(
                  { radius: 5 },
                  {
                    onChange: canvas?.renderAll.bind(canvas),
                    duration: 300,
                    easing: fabric.util.ease.easeInSine,
                  }
                )
              }
            }
          })
        }
      })
    }
  }

  const mouseUp = (e: fabric.IEvent, canvas: fabric.Canvas | null) => {
    if (!canvas) {
      return
    }
    if (e.target?.isType('group')) {
      const activeGroup = e.target as CustomGroup
      if (activeGroup?.onGroupSelect) {
        const mousePoint = e.pointer as fabric.Point
        const newzoom = 3
        fabric.util.animate({
          startValue: canvas?.getZoom(),
          endValue: newzoom,
          duration: 1000,
          onChange: (zoomvalue) => {
            const canvasRect = new fabric.Rect({
              width: canvas?.width,
              height: canvas?.height,
              fill: '#000',
              opacity: 0,
              objectCaching: false,
            })
            canvasRect?.animate('opacity', 1, {
              duration: 1000,
              easing: fabric.util.ease.easeInExpo,
            })
            canvas?.add(canvasRect)
            canvas?.zoomToPoint(mousePoint, zoomvalue)
            canvas?.renderAll()
          },
          onComplete: () => {
            if (activeGroup?.onGroupSelect) {
              activeGroup.onGroupSelect()
            }
          },
        })
      }
    }
  }

  return {
    mouseUp,
    mouseOver,
    mouseOut,
    mouseMove,
  }
}

export default Events
