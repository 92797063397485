import { CarouselProvider } from 'pure-react-carousel'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { DesignInterface, setDesign } from '@store/actionSlices/design'
import type { RootStateFirebase, SessionMap } from '@store/types'

import DataHandler from '@components/data-handler'
import Footer from '@components/footer'
import GalleryHandler, { SlideTrigger } from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import Navigation from '@components/navigation'

import { GalleryData, selectFromResult, useGetDesignQuery } from '@api/design'

import getSession from '@utilities/firebase-util'
import { generateId } from '@utilities/generate-id-util'

export interface DeisgnProps {
  session: SessionMap | undefined
  projectName: string
  design: Array<DesignInterface>
}

const Design = ({ session, projectName, design }: DeisgnProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()
  const [activeLabel, setActiveLabel] = React.useState(0)
  const [isConnected, setIsConnected] = React.useState(false)
  const [transitionEffectId, setTransitionEffectId] = React.useState(
    generateId(10)
  )
  const [transitionTime, setTransitionTime] = React.useState(1000)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const designPayload = useGetDesignQuery({ projectName }, { selectFromResult })

  React.useEffect(() => {
    const { gallery } = designPayload

    if (design.length === 0 && gallery.length > 0) {
      dispatch(setDesign(gallery))
    }
  }, [designPayload])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        design: {
          galleryControl: {
            activeTabIndex: activeTabIndexFirebase,
            transitionTime: transitionTimeFirebase,
            isPlaying: isPlayingFirebase,
          },
        },
      } = session
      setIsConnected(connected)
      setActiveLabel(activeTabIndexFirebase)
      setTransitionTime(transitionTimeFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  React.useEffect(() => {
    setTransitionEffectId(generateId(10))
  }, [activeLabel])

  const carouselData = React.useMemo(
    () =>
      design.length > 0
        ? {
            slides: design[activeLabel].items.map(
              (res: GalleryData) => res.imageSource
            ),
            labels: design[activeLabel].items.map(
              (res: GalleryData, index) => ({
                label: `label-${index}`,
                sublabel: `sublabel-${index}`,
              })
            ),
          }
        : { slides: [], labels: [] },
    [design, activeLabel]
  )

  return (
    <div>
      <Navigation />
      <DataHandler
        payload={{
          ...designPayload,
          data: design,
        }}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={design.length}
          dragEnabled={false}
          orientation="vertical"
          currentSlide={activeLabel}
          className="ipad-p:w-1100px"
          interval={transitionTime}
          isPlaying={isSlideShowPlaying}
          disableAnimation
          disableKeyboard
        >
          {!isConnected && (
            <IdleTimeHandler>
              <div className="relative z-10">
                <div className="absolute flex h-screen items-center pl-8">
                  <SlideTrigger
                    type="label"
                    items={design.map((content) => ({ text: content.label }))}
                    setCurrentSlide={setActiveLabel}
                    currentSlide={activeLabel}
                    dotContainerRef={dotContainerRef}
                  />
                </div>
              </div>
            </IdleTimeHandler>
          )}
        </CarouselProvider>

        <TransitionGroup>
          <CSSTransition
            key={transitionEffectId}
            classNames="transition-toTop"
            timeout={500}
          >
            <GalleryHandler
              galleryName="design"
              galleryControl={session?.design.galleryControl}
              slides={carouselData.slides}
              labels={carouselData.labels}
              isConnected={isConnected}
            />
          </CSSTransition>
        </TransitionGroup>
      </DataHandler>
      <Footer linkClasses={{ designClass: 'underline' }} />
    </div>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    design,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    design,
  })
)(Design)
