import React from 'react'

import { SvgProps } from '@components/svg/types'

const StudySvg = ({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{
      ...styles,
    }}
    width={width || '48'}
    height={height || '48'}
    fill="none"
    stroke={strokeColor || 'currentColor'}
    viewBox="0 0 26 26"
    strokeWidth={stroke || 1}
  >
    <path d="M9.33288 3.00785L5.93967 5.4088C5.93967 5.4088 4.83149 3.58558 6.37815 2.5467C7.92481 1.50781 9.33288 3.00785 9.33288 3.00785Z" />
    <path d="M5.9053 2.96183C5.8917 2.96183 5.8781 2.96183 5.86515 2.96118C5.34182 2.93981 4.92471 2.5091 4.92471 1.98124C4.92407 1.43913 5.36319 1 5.9053 1C6.44741 1 6.88654 1.43913 6.88654 1.98124C6.88654 2.06738 6.87553 2.15028 6.85481 2.22995" />
    <path d="M2.28153 6.58626C2.82345 6.58626 3.26277 6.14694 3.26277 5.60502C3.26277 5.06309 2.82345 4.62378 2.28153 4.62378C1.73961 4.62378 1.30029 5.06309 1.30029 5.60502C1.30029 6.14694 1.73961 6.58626 2.28153 6.58626Z" />
    <path d="M5.24789 2.70789L3.00885 4.94692" />
    <path d="M2.59306 6.58563L4.12872 11.3176" />
    <path d="M6.50499 12.633C6.50499 12.633 6.72974 11.3079 5.43178 11.3176C4.12864 11.3273 3.26205 11.3176 3.26205 11.3176C3.26205 11.3176 1.76201 11.214 1.96927 12.633" />
    <path d="M1.37215 24.358L4.20482 15.5425" />
    <path d="M24.587 24.358L21.3916 15.5425" />
    <path d="M19.7386 10.6479H10.9729C10.7676 10.6479 10.6011 10.4815 10.6011 10.2761V4.7417C10.6011 4.53639 10.7676 4.36993 10.9729 4.36993H19.7386C19.9439 4.36993 20.1104 4.53639 20.1104 4.7417V10.2761C20.1104 10.4815 19.9439 10.6479 19.7386 10.6479Z" />
    <path d="M10.6011 9.30914H20.1104" />
    <rect
      x="0.738693"
      y="13.3984"
      width="24.119"
      height="1.6441"
      rx="0.82205"
    />
    <path d="M14.1202 13.5595V10.9154M16.7643 13.5595V10.9154" />
  </svg>
)

export default StudySvg
