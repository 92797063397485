import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  Dot,
} from 'pure-react-carousel'
import React, { useContext } from 'react'
import { connect, useDispatch } from 'react-redux'

import { setBuildingPageConfigByKey } from '@store/actionSlices/buildingPageConfig'
import type { RootStateFirebase, SessionMap } from '@store/types'

import { GallerySlide, GallerySlider } from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import Player from '@components/player'

import { GalleryData } from '@api/floor-plate-gallery'
import { MappingCollection } from '@api/interactive-plan'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import getSession from '@utilities/firebase-util'
import handleMouseWheel from '@utilities/gallery-image-control-util'
import { generateId } from '@utilities/generate-id-util'

import FloorplanCanvas from './floorplan-canvas'

export interface CarouselContentProps {
  slideContent: Array<GalleryData>
  isFullWidth: boolean
  session: SessionMap | undefined
  activeLevel: string
  activeBlock: string
  floorPlan: MappingCollection
  transitionType?: string
}

const canvasKey = generateId(10)

const FloorCarouselContent = ({
  slideContent,
  isFullWidth,
  session,
  activeLevel,
  activeBlock,
  floorPlan,
  transitionType,
}: CarouselContentProps) => {
  const dispatch = useDispatch()

  const [floorPlanImagePath, setFloorPlanImagePath] = React.useState('')
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = React.useState(
    carouselContext.state.currentSlide
  )
  const [sessionConnected, setSessionConnected] = React.useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)

  React.useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  React.useEffect(() => {
    if (activeLevel !== '' || activeBlock !== '') {
      const mapName = activeBlock
        ? `${activeBlock}-${activeLevel}`
        : activeLevel
      if (mapName in floorPlan) {
        setFloorPlanImagePath(floorPlan[mapName]?.image ?? '')
      }
    }
  }, [activeLevel, activeBlock])

  React.useEffect(() => {
    if (session) {
      const { connected: sessionConnectedFirebase } = session
      setSessionConnected(sessionConnectedFirebase)
    }
  }, [session])

  const updateUnitPanelState = (arg: React.SetStateAction<boolean>) => {
    setIsVideoPlaying(arg)
    if (sessionConnected === false) {
      dispatch(
        setBuildingPageConfigByKey({
          key: 'unitPanelFolded',
          value: arg === true,
        })
      )
    }
  }

  return (
    <div
      onWheel={(e) => {
        if (!isVideoPlaying) {
          handleMouseWheel(e, 'nextButton', 'prevButton')
        }
      }}
    >
      <ButtonNext id="nextButton" className="hidden">
        Next button
      </ButtonNext>
      <ButtonBack id="prevButton" className="hidden">
        Prev button
      </ButtonBack>

      {!sessionConnected && (
        <IdleTimeHandler>
          <div
            className={`absolute right-0 z-10 flex h-full items-center pr-7 ${
              isVideoPlaying ? 'hidden' : ''
            }`}
          >
            <div className="no-scrollbar grid max-h-screen-60vh justify-items-center overflow-hidden overflow-y-auto">
              <Dot
                key={floorPlanImagePath}
                className={`m-2 h-14 w-14 rounded-lg bg-transparent transition-size drop-shadow-70 ${
                  currentSlide === 0 ? 'h-18 w-18' : ''
                }`}
                slide={0}
                onClick={() => setCurrentSlide(0)}
              >
                <div
                  className="relative h-full w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
                  style={{
                    ...setBackground(
                      AssetHandler({
                        url: floorPlanImagePath,
                        type: 'new',
                        noSpliceUrl: true,
                      }),
                      { gradiant: currentSlide === 0 ? 0 : 0.5 }
                    ),
                  }}
                ></div>
              </Dot>
              {slideContent.map((thumbnail, index) => (
                <Dot
                  key={thumbnail.imageSrc}
                  className={`m-2 h-14 w-14 rounded-lg bg-transparent transition-size ${
                    currentSlide === index + 1 ? 'h-18 w-18' : ''
                  }`}
                  slide={index + 1}
                  onClick={() => setCurrentSlide(index + 1)}
                >
                  <div
                    className="relative h-full w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
                    style={{
                      ...setBackground(
                        AssetHandler({
                          url: thumbnail.imageSrc ?? '',
                          type: 'legacy',
                          noSpliceUrl: false,
                        }),
                        { gradiant: currentSlide === index + 1 ? 0 : 0.5 }
                      ),
                    }}
                  ></div>
                </Dot>
              ))}
            </div>
          </div>
        </IdleTimeHandler>
      )}

      <GallerySlider transitionType={transitionType}>
        <GallerySlide index={0} transitionType={transitionType}>
          <div className="background-cover">
            <FloorplanCanvas
              key={canvasKey}
              session={session}
              isFullWidth={isFullWidth}
              ratio="min"
            />
          </div>
        </GallerySlide>
        {slideContent.map((value, index) => (
          <GallerySlide
            key={`${value.imageSrc}-gallery`}
            index={index + 1}
            transitionType={transitionType}
          >
            <div
              className="background-cover"
              style={{
                ...setBackground(
                  AssetHandler({ url: value.imageSrc ?? '', type: 'legacy' }),
                  { gradiant: value.type === 'image' ? 0 : 0.5 }
                ),
              }}
            >
              {value?.type === 'video' && (
                <Player
                  galleryName="floorGallery"
                  getPlayerState={updateUnitPanelState}
                  videoUrl={AssetHandler({
                    url: value.videoSrc ?? '',
                    type: 'legacy',
                  })}
                  isActive={currentSlide === index + 1}
                  playerControls={session?.floorGallery?.playerControl}
                />
              )}
            </div>
          </GallerySlide>
        ))}
        {sessionConnected && (
          <GallerySlide
            index={slideContent.length}
            transitionType={transitionType}
          >
            <div className="background-cover">
              <FloorplanCanvas
                key={canvasKey}
                session={session}
                isFullWidth={isFullWidth}
                ratio="min"
              />
            </div>
          </GallerySlide>
        )}
      </GallerySlider>
    </div>
  )
}

export default connect(
  ({
    firestore,
    building: { activeLevel, activeBlock },
    interactivePlan: { floorplan },
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    activeLevel,
    activeBlock,
    floorPlan: floorplan,
  })
)(FloorCarouselContent)
