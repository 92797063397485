import { TokenPayloadInterface } from '@store/actionSlices/token'

import {
  EnvisionVRConfigurationInterface,
  SnaploaderConfigurationInterface,
} from '@api/config'

function truncateTextWithEllipsis(
  text: string,
  length: number,
  end: number
): string {
  return text.length > length ? `${text.substring(0, end)}...` : text
}

function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const newParams = params
          const [key, value] = param.split('=')
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return newParams
        }, {})
    : {}
}

const hasToken = (token: TokenPayloadInterface): boolean => {
  const {
    access_token: { token: accessToken },
    refresh_token: { token: refreshToken },
  } = token

  return accessToken !== '' && refreshToken !== ''
}

const hasSnaploader = (
  snapConfig: SnaploaderConfigurationInterface
): boolean => {
  const { active, sceneSnapId, sceneModelId } = snapConfig
  return active && sceneSnapId !== '' && sceneModelId !== ''
}

const hasEnvisionVR = (config: EnvisionVRConfigurationInterface): boolean => {
  if (config === undefined) return false
  const { active, url } = config
  return active && url !== ''
}

const getFirebasePort = (): string => {
  const firebasePort = localStorage.getItem('firebasePort')
  return firebasePort === null ? '' : firebasePort
}

const getAssetPort = (): string => {
  const assetPort = localStorage.getItem('assetPort')
  return assetPort === null ? '' : assetPort
}

const roomQueryString = (): string => {
  const room = localStorage.getItem('room')
  return room === null ? '' : `?showcaseRoom=${room}`
}

export {
  getQueryStringParams,
  truncateTextWithEllipsis,
  hasToken,
  hasSnaploader,
  hasEnvisionVR,
  getFirebasePort,
  getAssetPort,
  roomQueryString,
}
