import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface GalleryData {
  imageSource: string
  imageName: string
}

export interface BrochureData {
  label: string
  Images: Array<GalleryData>
}

export interface BrochurePayload extends PayloadInterface {
  data: Array<BrochureData>
}

type BrochureType = {
  projectName: string
}

export const brochureApi = createApi({
  reducerPath: 'brochureApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBrochure: builder.query<BrochurePayload, BrochureType>({
      query: (params: BrochureType) => ({
        url: `/v1/${params.projectName}/brochure${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  brochureData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetBrochureQuery } = brochureApi
