import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase } from '@store/types'

export interface LoadingIndicatorInterface {
  loadingIndicator: { isVisible: boolean }
}

const LoadingIndicator = ({ loadingIndicator }: LoadingIndicatorInterface) => {
  if (!loadingIndicator.isVisible) return null

  return (
    <div className="fixed inset-0 z-50 bg-gray-100 bg-opacity-50">
      <div
        style={{ borderTopColor: 'transparent' }}
        className="border-white-400 absolute left-1/2 top-1/2 h-16 w-16 animate-spin rounded-full border-4 border-solid"
      />
    </div>
  )
}

export default connect(({ loadingIndicator }: RootStateFirebase) => ({
  loadingIndicator,
}))(LoadingIndicator)
