import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { BuildingInterface } from '@store/actionSlices/building'
import { resetFilter, setFilter } from '@store/actionSlices/unitFilter'
import { RootStateType } from '@store/store'
import {
  Configurations as ConfigurationInterface,
  RangeInterface,
  UnitFilterInterface,
} from '@store/types'

import Range from '@components/range'
import { CloseCircleSvg } from '@components/svg'

import { PriceRange } from '@api/config'

import AspectList from './aspect-list'
import BedConfig from './bed-config'

export interface ConfigPopupProps {
  isOpen: boolean
  toggle: (arg: boolean) => void
  unitFilter: UnitFilterInterface
  className?: string
  prices: PriceRange
  showPrice: boolean
  building: BuildingInterface
  zIndex?: string
}

const BuildingFilterPopup = ({
  isOpen,
  toggle,
  unitFilter,
  prices,
  showPrice = true,
  className = 'max-h-screen',
  building,
  zIndex = 'z-30',
}: ConfigPopupProps) => {
  const dispatch = useDispatch()

  const [showAvailable, setShowAvailable] = React.useState(
    unitFilter.showAvailable
  )
  const [configurations, setConfigurations] =
    React.useState<ConfigurationInterface>(unitFilter.configurations)
  const [range, setRange] = React.useState<RangeInterface>(unitFilter.range)
  const [aspect, setAspect] = React.useState<string>(unitFilter.aspect)

  const handleRangeOnchange = (rangeValue: { max: number; min: number }) => {
    dispatch(setFilter({ range: rangeValue }))
  }

  const handleAspectOnchange = (value: string) => {
    dispatch(setFilter({ aspect: value }))
  }

  const handleConfigurationOnchange = (configs: ConfigurationInterface) => {
    dispatch(setFilter({ configurations: configs }))
  }

  const handleShowAvailable = (checked: boolean) =>
    dispatch(setFilter({ showAvailable: checked }))

  const reset = () => {
    dispatch(resetFilter())
    dispatch(
      setFilter({ range: { min: prices.minPrice, max: prices.maxPrice } })
    )
  }

  const apply = () => {
    toggle(false)
    dispatch(setFilter({ apply: true }))
  }

  React.useEffect(() => {
    setConfigurations(unitFilter.configurations)
    setAspect(unitFilter.aspect)
    setRange(unitFilter.range)
    setShowAvailable(unitFilter.showAvailable)
    setAspect(unitFilter.aspect)
  }, [unitFilter])

  return (
    <div className="relative">
      <div
        className={`absolute ${zIndex} w-auto transform rounded-tr-5xl bg-neutral-grey px-9 duration-500 ease-in-out ${className} left-0 max-w-md ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } shadow-2xl`}
      >
        <div className="mt-18 flex w-72 flex-row items-center justify-between">
          <span className="text-2xb">Filter</span>
          <button
            onClick={() => toggle(!isOpen)}
            type="button"
            className="-mt-18 flex items-center"
          >
            <CloseCircleSvg
              className="cursor-pointer"
              width="34"
              height="34"
              fill="hsl(1, 0%, 0%)"
            />
          </button>
        </div>
        <div className="h-screen w-72 pt-5">
          {showPrice && (
            <div className="my-4">
              <div className="relative text-base">
                <span>Price Range</span>
              </div>
              <div className="bg-slider h-18">
                <Range
                  min={prices.minPrice}
                  max={prices.maxPrice}
                  minValue={range.min}
                  maxValue={range.max}
                  onChangeSliderRange={handleRangeOnchange}
                />
              </div>
            </div>
          )}

          <div className="my-4">
            <div className="relative">
              <span>Configuration</span>
            </div>
            <div className="bg-slider">
              <BedConfig
                configs={configurations}
                onChange={handleConfigurationOnchange}
              />
            </div>
          </div>
          <div className="relative my-4">
            <AspectList
              aspects={building.aspects}
              aspect={aspect}
              onChange={handleAspectOnchange}
            />
          </div>

          <div className="my-4">
            <div className="mb-4 flex items-center">
              <label
                htmlFor="onlyShowAvailable"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                <input
                  id="onlyShowAvailable"
                  type="checkbox"
                  name="onlyShowAvailable"
                  checked={showAvailable}
                  onChange={(e) => handleShowAvailable(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
                <span className="pl-2 text-gray-500">Only Show Available</span>
              </label>
            </div>
          </div>

          <div className="my-4">
            <div className="flex flex-row items-center justify-between">
              <button
                type="button"
                className="flex items-center"
                onClick={() => reset()}
              >
                <span>Reset all filters</span>
              </button>
              <button
                disabled={unitFilter.apply}
                type="button"
                className={`flex items-center rounded px-4 py-2 text-white ${
                  unitFilter.apply
                    ? 'bg-gray-700'
                    : 'bg-blue-500 hover:bg-blue-700'
                }`}
                onClick={() => apply()}
              >
                <span>Apply filters</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    unitFilter,
    projectConfig: { prices, longhand, showPrice },
    building,
  }: RootStateType) => ({
    projectName,
    unitFilter,
    prices,
    longhand,
    showPrice,
    building,
  })
)(BuildingFilterPopup)
