import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setExternalViews } from '@store/actionSlices/externalViews'
import { setVideoGallery } from '@store/actionSlices/videoGallery'
import { RootStateFirebase } from '@store/types'

import {
  BookOpenSvg,
  ExplorerSvg,
  FloorplanSvg,
  VideoSvg,
} from '@components/svg'

import {
  ExternalViewsData,
  selectFromResult as selectFromExternalViewsResult,
  useGetExternalViewsQuery,
} from '@api/external-views'
import {
  VideoGalleryData,
  selectFromResult as selectFromVideoGalleryResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

export interface DropdownMenuInterface {
  isDropdownMenuOpen: boolean
  isConfigPopupOpen: boolean
  toggleDropdownMenu: (arg: boolean) => void
  toggleConfigPopup: (arg: boolean) => void
  projectName: string
  videoGallery: Array<VideoGalleryData>
  externalViews: Array<ExternalViewsData>
}

const DropdownMenu = ({
  isDropdownMenuOpen,
  isConfigPopupOpen,
  toggleDropdownMenu,
  toggleConfigPopup,
  projectName,
  videoGallery,
  externalViews,
}: DropdownMenuInterface) => {
  const dispatch = useDispatch()

  const [skipVideoGalleryApi, setSkipVideoGalleryApi] = React.useState(true)
  const [skipExternalViewsApi, setSkipExternalViewsApi] = React.useState(true)

  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName },
    {
      selectFromResult: selectFromVideoGalleryResult,
      skip: skipVideoGalleryApi,
    }
  )

  React.useEffect(() => {
    if (videoGallery.length === 0 && skipVideoGalleryApi) {
      setSkipVideoGalleryApi(false)
    }
  }, [videoGallery])

  React.useEffect(() => {
    const { videoGalleryData, isLoaded } = videoGalleryPayload
    if (isLoaded && videoGalleryData.length > 0 && videoGallery.length === 0) {
      dispatch(setVideoGallery(videoGalleryData))
    }
  }, [videoGalleryPayload, videoGallery])

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName },
    {
      selectFromResult: selectFromExternalViewsResult,
      skip: skipExternalViewsApi,
    }
  )

  React.useEffect(() => {
    if (externalViews.length === 0 && skipExternalViewsApi) {
      setSkipExternalViewsApi(false)
    }
  }, [externalViews])

  React.useEffect(() => {
    const { externalViewsData, isLoaded } = externalViewsPayload
    if (
      isLoaded &&
      externalViewsData.length > 0 &&
      externalViews.length === 0
    ) {
      dispatch(setExternalViews(externalViewsData))
    }
  }, [externalViewsPayload, externalViews])

  const handleConfigPopupToggle = () => {
    toggleDropdownMenu(false)
    toggleConfigPopup(!isConfigPopupOpen)
  }

  const history = useHistory()

  return (
    <div
      className={`relative z-20 ${
        isDropdownMenuOpen ? 'visible' : 'invisible'
      }`}
    >
      <div
        className={`absolute right-8 top-20 block transform duration-300 ease-in-out ${
          isDropdownMenuOpen ? 'translate-y-0' : '-translate-y-40'
        }`}
      >
        <div className="pb-3 pt-1">
          <button
            className="rounded-full bg-white drop-shadow-70"
            title="Config Popup"
            onClick={handleConfigPopupToggle}
            type="button"
          >
            <FloorplanSvg className="h-10 w-10 text-gray-600" />
          </button>
        </div>
        <div className="pb-3">
          <button
            className="rounded-full bg-white drop-shadow-70"
            title="Brochure"
            type="button"
            onClick={() => history.push('brochure')}
          >
            <BookOpenSvg className="h-10 w-10 text-gray-600" />
          </button>
        </div>
        {videoGallery.length > 0 && (
          <div className="pb-3">
            <button
              className="rounded-full bg-white drop-shadow-70"
              title="Video Gallery"
              type="button"
              onClick={() => history.push('video-gallery')}
            >
              <VideoSvg className="h-10 w-10 text-gray-600" />
            </button>
          </div>
        )}
        {externalViews.length > 0 && (
          <div>
            <button
              className="rounded-full bg-white  drop-shadow-70"
              title="External View"
              type="button"
              onClick={() => history.push('external-views')}
            >
              <ExplorerSvg className="h-10 w-10 text-gray-600" rotate="top" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    videoGallery,
    externalViews,
  }: RootStateFirebase) => ({
    projectName,
    videoGallery,
    externalViews,
  })
)(DropdownMenu)
