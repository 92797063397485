import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
} from 'pure-react-carousel'
import React from 'react'

import type { GalleryControlInterface } from '@store/types'

import IdleTimeHandler from '@components/idle-time-handler'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import handleMouseWheel from '@utilities/gallery-image-control-util'
import { generateId } from '@utilities/generate-id-util'

import GalleryProvider from './gallery-provider'
import GallerySlide from './gallery-slide'
import GallerySlider from './gallery-slider'
import { setScrollPosition } from './helper'
import SlideTrigger from './slide-trigger'

export interface GalleryHandlerInterface {
  galleryName: string
  galleryControl?: GalleryControlInterface
  slides: Array<string>
  labels?: Array<{
    label: string
    sublabel: string
  }>
  gradiant?: number
  legacyAssets?: boolean
  isConnected?: boolean
  dotType?: 'dot' | 'text' | 'thumbnail'
}

const GalleryHandler = ({
  galleryName,
  galleryControl,
  slides,
  labels,
  gradiant,
  legacyAssets = true,
  isConnected = false,
  dotType = 'dot',
}: GalleryHandlerInterface) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const innernextButton = generateId(10)
  const innerprevButton = generateId(10)
  const innerfirstButton = generateId(10)
  const innerlastButton = generateId(10)

  return (
    <GalleryProvider
      galleryName={galleryName}
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      dragEnabled={false}
      disableKeyboard
      className="ipad-p:w-screen-lg"
      originalSlides={slides}
      galleryControl={galleryControl}
    >
      {(slideContent, activeIndex, setActiveIndex) => (
        <div
          onWheel={(e) => {
            handleMouseWheel(e, innernextButton, innerprevButton)
            setScrollPosition(dotContainerRef)
          }}
        >
          <ButtonNext id={innernextButton} className="hidden">
            Next button
          </ButtonNext>
          <ButtonBack id={innerprevButton} className="hidden">
            Prev button
          </ButtonBack>
          <ButtonFirst id={innerfirstButton} className="hidden">
            First button
          </ButtonFirst>
          <ButtonLast id={innerlastButton} className="hidden">
            Last button
          </ButtonLast>
          {!isConnected && slides.length > 1 && (
            <IdleTimeHandler>
              <div className="relative z-10">
                <div className="absolute right-10 flex h-screen items-center ipad-p:right-32">
                  <SlideTrigger
                    type={dotType}
                    items={
                      labels
                        ? labels.map((label) => ({
                            text: label.label,
                            subText: label.sublabel,
                          }))
                        : []
                    }
                    setCurrentSlide={setActiveIndex}
                    currentSlide={activeIndex}
                    nextButtonId={innernextButton}
                    previousButtonId={innerprevButton}
                    dotContainerRef={dotContainerRef}
                  />
                </div>
              </div>
            </IdleTimeHandler>
          )}
          <GallerySlider transitionType={galleryControl?.transitionType}>
            {slideContent.map((source, index) => (
              <GallerySlide
                transitionType={galleryControl?.transitionType}
                index={index}
                key={source}
              >
                <div
                  className="background-cover"
                  style={{
                    ...setBackground(
                      AssetHandler({
                        url: source,
                        type: legacyAssets ? 'legacy' : 'new',
                      }),
                      {
                        gradiant: gradiant || 0,
                      }
                    ),
                  }}
                ></div>
              </GallerySlide>
            ))}
          </GallerySlider>
        </div>
      )}
    </GalleryProvider>
  )
}

export default GalleryHandler
