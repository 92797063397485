import React from 'react'

import { ChevronDoubleSvg } from '@components/svg'

export interface DropDownProps {
  id: string
  items: Array<string>
  value: string
  label?: string
  onSelect: (arg: string) => void
  icon: React.ReactNode
  className?: string
}

const DropDown = ({
  id,
  onSelect,
  label,
  items,
  value,
  icon,
  className = 'bg-transparent text-white drop-shadow-70',
}: DropDownProps) => {
  const [isOpen, toggleOpen] = React.useState(false)

  return (
    <div className="relative mr-auto w-full">
      <div id={id}>
        <button
          type="button"
          onClick={() => toggleOpen(!isOpen)}
          className={`flex w-full items-center rounded border-2 border-white px-2 py-1 ${className}`}
        >
          <div className="mr-2">{icon}</div>
          <div className="flex w-full justify-between">
            <span>
              {label} {value}
            </span>
            <div className="ml-2">
              <ChevronDoubleSvg
                width="20px"
                height="20px"
                stroke={2}
                rotate={isOpen ? 'right' : 'left'}
              />
            </div>
          </div>
        </button>
        <div
          onBlur={() => toggleOpen(false)}
          className="no-scrollbar absolute left-0 top-12 w-full overflow-hidden overflow-y-scroll rounded bg-white text-center shadow-xl transition-all duration-300 ease-in-out z-5"
          style={{
            height: isOpen
              ? `${(items.length < 10 ? items.length : 10) * 32}px`
              : '0px',
          }}
        >
          <ul>
            {items.map((item: string) => (
              <li key={item}>
                <button
                  className={`w-full cursor-pointer px-2 py-1 hover:bg-gray-400 ${
                    value === item ? 'bg-gray-300' : ''
                  }`}
                  type="button"
                  onClick={() => {
                    toggleOpen(false)
                    onSelect(item)
                  }}
                >
                  {label} {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DropDown
