import { ButtonBack, ButtonNext, Slide, Slider } from 'pure-react-carousel'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import type { RootStateFirebase, SessionMap } from '@store/types'

import {
  GallerySlide,
  GallerySlider,
  SlideTrigger,
} from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import Player from '@components/player'

import { VideoGalleryData } from '@api/video-gallery'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import getSession from '@utilities/firebase-util'
import handleMouseWheel from '@utilities/gallery-image-control-util'

export interface CarouselContentProps {
  session: SessionMap | undefined
  slideContent: Array<VideoGalleryData>
  getCtrlVisibility: (arg: React.SetStateAction<boolean>) => void
  ctrlsHidden: boolean
  transitionType?: string
}

const CarouselContent = ({
  session,
  slideContent,
  ctrlsHidden,
  getCtrlVisibility,
  transitionType,
}: CarouselContentProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const [currentSlide, setCurrentSlide] = useState(0)

  const getDotItems = () =>
    slideContent.map((content: VideoGalleryData) => ({
      text: content.title,
    }))

  React.useEffect(() => {
    if (session) {
      const {
        videoGallery: {
          galleryControl: { activeItemIndex: activeSlideIndexFirebase },
        },
      } = session
      setCurrentSlide(activeSlideIndexFirebase)
    }
  }, [session])

  return (
    <div
      onWheel={(e) => {
        if (!ctrlsHidden) {
          handleMouseWheel(e, 'nextButton', 'prevButton')
        }
      }}
      className="relative"
      data-testid="video-gallery"
    >
      <div className="absolute right-10 flex h-screen items-center">
        <ButtonNext id="nextButton" className="hidden">
          Next button
        </ButtonNext>
        <ButtonBack id="prevButton" className="hidden">
          Prev button
        </ButtonBack>

        {!ctrlsHidden && (
          <IdleTimeHandler>
            <SlideTrigger
              type="text"
              items={getDotItems()}
              setCurrentSlide={setCurrentSlide}
              currentSlide={currentSlide}
              nextButtonId="nextButton"
              previousButtonId="prevButton"
              dotContainerRef={dotContainerRef}
            />
          </IdleTimeHandler>
        )}
      </div>

      <GallerySlider transitionType={transitionType}>
        {slideContent.map((value, index) => (
          <GallerySlide
            index={index}
            key={value.title}
            transitionType={transitionType}
          >
            <div
              className="background-cover"
              style={{
                ...setBackground(
                  AssetHandler({ url: value.previewImageSrc, type: 'legacy' }),
                  { gradiant: 0.5 }
                ),
              }}
            >
              {index === currentSlide && (
                <Player
                  galleryName="videoGallery"
                  getPlayerState={getCtrlVisibility}
                  videoUrl={AssetHandler({
                    url: value.src,
                    type: 'legacy',
                  })}
                  isActive={index === currentSlide}
                  playerControls={session?.videoGallery?.playerControl}
                />
              )}
            </div>
          </GallerySlide>
        ))}
      </GallerySlider>
    </div>
  )
}

export default connect(({ firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
}))(CarouselContent)
