import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { BuildingInterface, setBuilding } from '@store/actionSlices/building'
import { RootStateFirebase, UnitFilterInterface } from '@store/types'

import { BuildingBlocksSvg, ChevronSvg } from '@components/svg'

import { Level, Unit } from '@api/building'

export interface ComponentPropsInterface {
  sessionConnected: boolean
  unitFilter: UnitFilterInterface
  building: BuildingInterface
}

const LevelDropdown = ({
  sessionConnected,
  unitFilter,
  building,
}: ComponentPropsInterface) => {
  const AVAILABLE_STATUS = 'available'
  const dispatch = useDispatch()

  const [isLevelsOpen, toggleLevels] = React.useState(false)
  const [levelSelect, setLevelSelect] = React.useState<Array<string>>([])

  const changeLevel = (lvl: string) => {
    toggleLevels(false)
    dispatch(
      setBuilding({
        ...building,
        activeLevel: lvl,
        activeUnit: '',
      })
    )
  }

  const getLevelOptions = (onlyShowAvailable?: boolean) => {
    const levelList: Level[] = [...building.levels]
    if (onlyShowAvailable) {
      levelList.filter((lvl) =>
        lvl.data.filter((unt: Unit) => unt.metas.status === AVAILABLE_STATUS)
      )
    }

    const levels: Array<string> = []
    levelList.forEach((res) => {
      if (building.activeBlock) {
        if (res.data.find((lvl) => lvl.blockId === building.activeBlock)) {
          levels.push(res.level)
        }
      } else {
        levels.push(res.level)
      }
    })
    setLevelSelect(levels)
  }

  React.useEffect(() => {
    getLevelOptions(unitFilter.showAvailable)
  }, [building, unitFilter])

  return (
    <div id="levelDropdown" className="relative w-full sm:w-auto">
      <button
        type="button"
        onClick={(e) =>
          sessionConnected ? e.preventDefault() : toggleLevels(!isLevelsOpen)
        }
        className="flex w-full items-center justify-between rounded border-2 border-white bg-white px-2 py-1"
      >
        <div className="mr-2">
          <BuildingBlocksSvg className="h-8 w-8" />
        </div>
        <p className="whitespace-nowrap">Level {building.activeLevel}</p>
        {!sessionConnected && (
          <div className="ml-2 transform-gpu transition-all">
            <ChevronSvg
              className="text-gray-500"
              rotate={isLevelsOpen ? 'top' : 'down'}
              width="30"
              height="30"
            />
          </div>
        )}
      </button>
      <div
        onBlur={() => toggleLevels(false)}
        className="no-scrollbar absolute left-0 top-10 w-full overflow-hidden overflow-y-scroll rounded bg-white text-center shadow-xl transition-all duration-300 ease-in-out z-5"
        style={{
          height: isLevelsOpen
            ? `${(levelSelect.length < 10 ? levelSelect.length : 10) * 32}px`
            : '0px',
        }}
      >
        <ul>
          {levelSelect.map((level: string) => (
            <li key={level}>
              <button
                className={`w-full cursor-pointer px-2 py-1 hover:bg-gray-400 ${
                  building.activeLevel === level ? 'bg-gray-400' : ''
                }`}
                type="button"
                onClick={() => changeLevel(level)}
              >
                Level {level}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
export default connect(({ unitFilter, building }: RootStateFirebase) => ({
  unitFilter,
  building,
}))(LevelDropdown)
