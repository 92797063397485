import React from 'react'

export const setScrollPosition = (
  dotContainerRef: React.RefObject<HTMLDivElement>
) => {
  const containerElem = dotContainerRef.current as HTMLElement | undefined
  const collection = document.getElementsByClassName(
    'carousel__dot--selected ad-dot'
  )
  const activeElement = collection[0] as HTMLElement | undefined

  if (containerElem && activeElement) {
    const dropdownRect = containerElem.getBoundingClientRect()
    const activeElemRect = activeElement.getBoundingClientRect()
    const overScroll = activeElement.offsetHeight

    if (activeElemRect.bottom + overScroll > dropdownRect.bottom) {
      containerElem.scrollTop = Math.min(
        activeElement.offsetTop +
          activeElement.clientHeight -
          containerElem.offsetHeight +
          overScroll,
        containerElem.scrollHeight
      )
    } else if (activeElemRect.top - overScroll < dropdownRect.top) {
      containerElem.scrollTop = Math.max(
        activeElement.offsetTop - overScroll * 2,
        0
      )
    }
  }
}

export default {}
