import { CarouselContext } from 'pure-react-carousel'
import React from 'react'

import {
  DotInterface,
  LabelInterface,
  TextInterface,
  ThumbnailInterface,
} from '@store/types'

import { ChevronSvg } from '@components/svg'

import { generateId } from '@utilities/generate-id-util'

import { setScrollPosition } from '../helper'
import LabelDot from './label-dot'
import PlainDot from './plain-dot'
import TextDot from './text-dot'
import ThumbnailDot from './thumbnail-dot'

export interface SlideTriggerInterface {
  type: string
  items:
    | Array<DotInterface>
    | Array<TextInterface>
    | Array<LabelInterface>
    | Array<ThumbnailInterface>
  setCurrentSlide: (arg: number) => void
  currentSlide: number
  nextButtonId?: string
  previousButtonId?: string
  dotContainerRef: any
  alignClass?: string
}

const SlideTrigger = ({
  type,
  items,
  setCurrentSlide,
  currentSlide,
  nextButtonId,
  previousButtonId,
  dotContainerRef,
  alignClass,
}: SlideTriggerInterface) => {
  const carouselContext = React.useContext(CarouselContext)

  React.useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  const dotAlignStyle = () => {
    let position = ''
    if (type === 'text') {
      position = 'text-right'
    } else if (type === 'thumbnail') {
      position = 'text-center'
    } else {
      position = 'text-left'
    }
    return position
  }

  return (
    <div className={`relative z-10 ${alignClass || dotAlignStyle()}`}>
      {items.length > 7 && previousButtonId && (
        <div
          className={`pb-2 text-center ${
            currentSlide === 0 ? 'invisible' : 'visible'
          }`}
        >
          <button
            type="button"
            className="rounded-full bg-gray-700 opacity-50"
            onClick={() => {
              document.getElementById(previousButtonId)?.click()
              setScrollPosition(dotContainerRef)
            }}
          >
            <ChevronSvg rotate="top" className="h-6 w-6 text-white" />
          </button>
        </div>
      )}

      <div
        ref={dotContainerRef}
        className={
          items.length > 7 ? 'no-scrollbar h-376px overflow-y-auto' : ''
        }
      >
        {items.map((item: any, index: number) => (
          <div key={generateId(10)}>
            {type === 'dot' && (
              <PlainDot
                index={index}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                className="ad-dot"
              />
            )}
            {type === 'text' && (
              <TextDot
                item={item}
                index={index}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            )}
            {type === 'label' && (
              <LabelDot
                item={item}
                index={index}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            )}
            {type === 'thumbnail' && items.length > 0 && (
              <ThumbnailDot
                item={item}
                index={index}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            )}
          </div>
        ))}
      </div>

      {items.length > 7 && nextButtonId && (
        <div
          className={`pt-2 text-center  ${
            currentSlide === items.length - 1 ? 'invisible' : 'visible'
          }`}
        >
          <button
            type="button"
            className="rounded-full bg-gray-700 opacity-50"
            onClick={() => {
              document.getElementById(nextButtonId)?.click()
              setScrollPosition(dotContainerRef)
            }}
          >
            <ChevronSvg rotate="down" className="h-6 w-6 text-white" />
          </button>
        </div>
      )}
    </div>
  )
}

export default SlideTrigger
