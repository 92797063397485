import React from 'react'

import { CloseCircleSvg } from '@components/svg'

export interface ModalInterface {
  toggleModal: (arg: boolean) => void
  terminateSession: () => void
  disconnectSession: () => void
  sessionId: string
  isVisible: boolean
  connected: boolean
}

const SessionModal = ({
  sessionId,
  isVisible,
  toggleModal,
  terminateSession,
  disconnectSession,
  connected,
}: ModalInterface) => (
  <div
    id="default-modal"
    aria-hidden="true"
    className={`h-modal fixed left-0 right-0 top-4 z-50 transform items-center justify-center overflow-y-auto overflow-x-hidden duration-500
    ease-in-out md:inset-0 md:h-full ${
      isVisible ? 'translate-y-0' : '-translate-y-full'
    }
    `}
  >
    <div className="m-auto mt-32 h-full w-full max-w-2xl px-4 md:h-auto">
      <div className="rounded-lg bg-white shadow dark:bg-gray-700">
        <div className="flex items-center justify-between rounded-t border-b p-5 dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
            Remote Session
          </h3>
          {!connected && (
            <button
              type="button"
              onClick={() => toggleModal(false)}
              className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="default-modal"
            >
              <CloseCircleSvg fill="#000000" />
            </button>
          )}
        </div>
        <div className="space-y-6 p-6">
          <p className="text-center leading-relaxed text-gray-500  dark:text-gray-400">
            {connected
              ? 'This session is active. You can now control the app via your showcase remote.'
              : 'A remote session has been created. Please enter the Session ID bellow in your Showcase Controller App to connect to this client remotely.'}
          </p>
          <div
            className={`flex items-center justify-center text-2xl leading-relaxed ${
              connected && 'text-bold text-lime-600'
            }`}
          >
            {sessionId}
            <span className="relative ml-2 flex h-3 w-3">
              <span
                className={`absolute inline-flex h-full w-full rounded-full bg-lime-400 opacity-75 ${
                  connected ? 'animate-ping bg-lime-400' : 'bg-lime-400'
                }`}
              ></span>
              <span
                className={`relative inline-flex h-3 w-3 rounded-full ${
                  connected ? 'bg-lime-500' : 'bg-gray-400'
                }`}
              ></span>
            </span>
          </div>
          {!connected && (
            <p className="text-center leading-relaxed text-gray-500  dark:text-gray-400">
              Terminate this Session to prevent this popup from appearing.
            </p>
          )}
        </div>
        <div className="flex items-center rounded-b border-t border-gray-200 p-6 dark:border-gray-600">
          {connected ? (
            <button
              onClick={disconnectSession}
              data-modal-toggle="default-modal"
              type="button"
              className="ml-auto rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Disconnect Session
            </button>
          ) : (
            <button
              onClick={terminateSession}
              data-modal-toggle="default-modal"
              type="button"
              className="ml-auto rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Terminate Session
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default SessionModal
