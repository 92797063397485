import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase } from '@store/types'

import { Metas } from '@api/building'
import {
  Shortlist,
  ShortlistDocument,
  selectFromResult,
  useGetShortlistDocumentsQuery,
  usePostShortlistedMutation,
} from '@api/shortlisted'

import AssetHandler from '@utilities/asset-handler'
import InputHandler from '@utilities/form-util'
import { notifyError, notifySuccess } from '@utilities/notifier'

export interface FormDataType {
  name: string
  mobile: string
  email: string
  agent?: string
  agentMobile?: string
  agentEmail?: string
  documents: Array<ShortlistDocument>
}

export interface FormProps {
  setFormData(formData?: React.SetStateAction<FormDataType>): void
  formData: FormDataType
  shortlistUnits?: Array<Shortlist>
  showPrice?: boolean
  projectIdentity: ProjectIdentity
}

const ShortlistForm = ({
  shortlistUnits = [],
  formData,
  setFormData,
  showPrice = false,
  projectIdentity,
}: FormProps) => {
  const MAXIMUM_DOCUMENT_LENGTH_TO_SEND =
    Number(process.env.REACT_APP_MAXIMUM_DOCUMENT_LENGTH_TO_SEND) || 6
  const { projectId, projectName } = projectIdentity
  const [documents, setDocuments] = React.useState([])
  const [sendShortlist, result] = usePostShortlistedMutation()
  const [showLoader, setShowLoader] = React.useState(false)

  const [formValid, setFromValid] = React.useState(false)

  const [fullName, setFullName] = React.useState({
    value: '',
    isValid: false,
    message: '',
  })

  const [mobile, setMobile] = React.useState({
    value: '',
    isValid: false,
    message: '',
  })

  const [email, setEmail] = React.useState({
    value: '',
    isValid: false,
    message: '',
  })

  const [agent, setAgent] = React.useState({
    value: '',
    isValid: false,
    message: '',
  })

  const [agentMobile, setAgentMobile] = React.useState({
    value: '',
    isValid: false,
    message: '',
  })

  const [agentEmail, setAgentEmail] = React.useState({
    value: '',
    isValid: false,
    message: '',
  })

  React.useEffect(() => {
    if (!fullName.isValid || !mobile.isValid || !email.isValid || showLoader) {
      setFromValid(false)
      return
    }
    setFromValid(true)
  }, [fullName, mobile, email, showLoader])

  const bucketUrlLegacy = process.env.REACT_APP_BUCKET_LEGACY

  const handleDocuments = (key: string, val: boolean) => {
    let formDocuments = formData.documents.filter((e) => e)
    const docKey = parseInt(key, 10)

    if (val) {
      formDocuments.push(documents[docKey])
    } else {
      formDocuments = formDocuments.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(documents[docKey])
      )
    }

    formDocuments = formDocuments.filter(
      (element, index) => formDocuments.indexOf(element) === index
    )

    setFormData({ ...formData, ...{ documents: formDocuments } })
  }

  const getUnitImageUrl = (metas: Metas) => {
    const { floorImage, viewLineImages } = metas
    if (floorImage) {
      return `${projectId}/floorplans/preview/${floorImage}`
    }
    if (viewLineImages && viewLineImages.length > 0) {
      const [viewLineImage] = viewLineImages
      return `${projectId}/views/${viewLineImage.src}`
    }

    return ''
  }

  const onSubmit = () => {
    setFormData({
      ...formData,
      ...{
        name: fullName.value,
        mobile: mobile.value,
        email: email.value,
        agent: agent.value,
        agentMobile: agentMobile.value,
        agentEmail: agentEmail.value,
      },
    })

    const formDocuments = formData.documents
      .filter((formDocument) => formDocument)
      .map((doc) => {
        const { fileName } = doc
        return {
          title: fileName,
          url: encodeURI(
            `${bucketUrlLegacy}/${projectId}/documents/${fileName}`
          ),
        }
      })

    const formItems = shortlistUnits.map((unitObj) => {
      const { name, metas } = unitObj
      const { price, bedConfig, totalArea } = metas

      const imageUrl = AssetHandler({
        url: getUnitImageUrl(metas),
        type: 'legacy',
        noSpliceUrl: true,
      })

      return {
        name,
        configuration: bedConfig,
        price: showPrice ? `$${price.toLocaleString()}` : '',
        area: totalArea.toString(),
        image: imageUrl,
      }
    })

    const payload = {
      projectName,
      title: projectName.split('-').join(' '),
      name: fullName.value,
      mobile: mobile.value,
      email: email.value,
      agent: agent.value,
      agentMobile: agentMobile.value,
      agentEmail: agentEmail.value,
      items: formItems,
      documents: formDocuments,
    }

    sendShortlist(payload)
  }

  const validateDocumentLength = (): boolean =>
    formData.documents.length <= MAXIMUM_DOCUMENT_LENGTH_TO_SEND

  const shortlistDocumentsResponse = useGetShortlistDocumentsQuery(
    { projectName },
    { selectFromResult }
  )

  React.useEffect(() => {
    if (shortlistUnits.length === 0) {
      setFullName({ ...fullName, ...{ value: '' } })
      setMobile({ ...mobile, ...{ value: '' } })
      setEmail({ ...email, ...{ value: '' } })
      setAgent({ ...agent, ...{ value: '' } })
      setAgentMobile({ ...agentMobile, ...{ value: '' } })
      setAgentEmail({ ...agentEmail, ...{ value: '' } })
      setFormData({
        name: '',
        mobile: '',
        email: '',
        agent: '',
        agentMobile: '',
        documents: [],
      })
    }
  }, [shortlistUnits])

  React.useEffect(() => {
    const { documents: shortlistDocuments, isLoaded } =
      shortlistDocumentsResponse
    if (shortlistDocuments.length > 0 && isLoaded && documents.length === 0) {
      setDocuments(shortlistDocuments)
    }
  }, [shortlistDocumentsResponse])

  React.useEffect(() => {
    if (!result.isUninitialized) {
      if (result.isLoading) {
        setShowLoader(true)
      } else {
        setShowLoader(false)

        if (result.isSuccess) {
          notifySuccess(
            'Thank you, your shortlist has been sent to your email.'
          )
        }

        if (result.isError) {
          notifyError('Something went wrong, please try again.')
        }
      }
    }
  }, [result])

  return (
    <div className="relative flex h-full flex-col justify-between font-GTWalsheimPro text-white">
      <div className="no-scrollbar flex flex-grow flex-col gap-7 overflow-y-auto p-4 lg-h-new:gap-0 md-h-new:gap-0 sm-h-new:gap-0">
        <span className="text-3xl lg-h-new:hidden md-h-new:hidden sm-h-new:hidden">
          Email Shortlist
        </span>
        <form>
          <div className="mb-7 md-h-new:mb-5 sm-h-new:mb-4">
            <input
              type="text"
              name="name"
              maxLength={50}
              disabled={shortlistUnits.length < 1}
              required
              value={fullName.value}
              className={`underline-transparent w-full placeholder-gray-400 ${
                !fullName.isValid && fullName.value
                  ? 'border-red-500'
                  : 'border-white'
              }`}
              placeholder="Name*"
              onChange={(e) => InputHandler(e, setFullName)}
            />
            {fullName.message && (
              <p className="text-lg italic text-red-400">{fullName.message}</p>
            )}
          </div>
          <div className="mb-7 md-h-new:mb-5 sm-h-new:mb-4">
            <input
              type="text"
              name="mobile"
              maxLength={50}
              disabled={shortlistUnits.length < 1}
              required
              value={mobile.value}
              className={`underline-transparent w-full placeholder-gray-400 ${
                !mobile.isValid && mobile.value
                  ? 'border-red-500'
                  : 'border-white'
              }`}
              placeholder="+61 0000 000 000*"
              onChange={(e) => InputHandler(e, setMobile)}
            />
            {mobile.message && (
              <p className="text-lg italic text-red-400">{mobile.message}</p>
            )}
          </div>
          <div className="mb-7 md-h-new:mb-5 sm-h-new:mb-4">
            <input
              type="email"
              name="email"
              maxLength={50}
              disabled={shortlistUnits.length < 1}
              required
              value={email.value}
              className={`underline-transparent w-full placeholder-gray-400 ${
                !email.isValid && email.value
                  ? 'border-red-500'
                  : 'border-white'
              }`}
              placeholder="Email*"
              onChange={(e) => InputHandler(e, setEmail)}
            />
            {email.message && (
              <p className="text-lg italic text-red-400">{email.message}</p>
            )}
          </div>

          <div className="mb-7 md-h-new:mb-5 sm-h-new:mb-4">
            <input
              type="text"
              name="agent"
              maxLength={50}
              disabled={shortlistUnits.length < 1}
              value={agent.value}
              className="underline-transparent w-full border-white placeholder-gray-400"
              placeholder="Agent Name"
              onChange={(e) => InputHandler(e, setAgent)}
            />
            {agent.message && (
              <p className="text-lg italic text-red-400">{agent.message}</p>
            )}
          </div>

          <div className="mb-7 md-h-new:mb-5 sm-h-new:mb-4">
            <input
              type="text"
              name="agentMobile"
              maxLength={50}
              disabled={shortlistUnits.length < 1}
              value={agentMobile.value}
              className="underline-transparent w-full border-white placeholder-gray-400"
              placeholder="+61 0000 000 000"
              onChange={(e) => InputHandler(e, setAgentMobile)}
            />
            {agentMobile.message && (
              <p className="text-lg italic text-red-400">
                {agentMobile.message}
              </p>
            )}
          </div>

          <div className="mb-7 md-h-new:mb-12 sm-h-new:mb-8">
            <input
              type="email"
              name="agentEmail"
              maxLength={50}
              disabled={shortlistUnits.length < 1}
              value={agentEmail.value}
              className={`underline-transparent w-full placeholder-gray-400 ${
                !agentEmail.isValid && agentEmail.value
                  ? 'border-red-500'
                  : 'border-white'
              }`}
              placeholder="Agent Email"
              onChange={(e) => InputHandler(e, setAgentEmail)}
            />
            {agentEmail.message && (
              <p className="text-lg italic text-red-400">
                {agentEmail.message}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-6">
            {documents?.map((doc: ShortlistDocument, documentIndex) => (
              <div key={doc.id}>
                <label
                  className="checkbox-white"
                  htmlFor={`document_${documentIndex}`}
                >
                  <input
                    id={`document_${documentIndex}`}
                    name={`document_${documentIndex}`}
                    type="checkbox"
                    disabled={shortlistUnits.length < 1}
                    onChange={(e) =>
                      handleDocuments(`${documentIndex}`, e.target.checked)
                    }
                    checked={formData.documents.includes(doc) ?? false}
                  />
                  <span className="inline-flex items-center justify-end pl-2 text-xs">
                    {doc.fileName}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </form>
      </div>

      <div className="bg-mainColour w-full grow-0 py-3 pr-5 text-black shadow-submit">
        <div className="flex items-center justify-between">
          <span className="ml-4 text-red-500">
            {!validateDocumentLength() &&
              `You can attach a maximum of ${MAXIMUM_DOCUMENT_LENGTH_TO_SEND} items`}
          </span>
          <button
            type="button"
            disabled={
              !formValid ||
              shortlistUnits.length < 1 ||
              !validateDocumentLength()
            }
            className="inline-flex items-center rounded border-none bg-white px-2 py-2 text-xl text-black disabled:opacity-50"
            onClick={onSubmit}
          >
            {showLoader && (
              <div
                style={{ borderTopColor: 'transparent' }}
                className="ml-1 mr-2 mt-1 h-5 w-5 animate-spin rounded-full border-2 border-dotted border-black"
              ></div>
            )}
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(ShortlistForm)
