import React from 'react'

import { SvgProps } from '@components/svg/types'

const PauseSvg = ({ width, height, className, size, styles }: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  )
}

export default PauseSvg
