import React from 'react'
import { useDispatch } from 'react-redux'

import { removeAppConfig } from '@store/actionSlices/appConfig'
import { resetBuildingPageConfig } from '@store/actionSlices/buildingPageConfig'
import {
  removeProjectIdentity,
  setProjectIdentity,
} from '@store/actionSlices/project'
import { removeToken } from '@store/actionSlices/token'
import type {
  ProjectIdentity,
  SessionMap as SessionMapInterface,
} from '@store/types'

import ClearCache from '@components/cache-clear'
import IdleTimeHandler from '@components/idle-time-handler'
import ConfirmationModal from '@components/modals'
import {
  BulbSvg,
  DotsVerticalSvg,
  ImageSvg,
  LogoutSvg,
  OfflineSvg,
  StatusSvg,
} from '@components/svg'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { createSessionId } from '@utilities/generate-id-util'
import { getFirebasePort } from '@utilities/helper'
import ResetUtil from '@utilities/reset-util'

import AssetModal from './modals/asset'
import LightsModal from './modals/lights'
import OfflineModeModal from './modals/offline-mode'
import SessionModal from './modals/session'

export interface OptionsInterface {
  className: string
  projectIdentity: ProjectIdentity
  session: SessionMapInterface | undefined
  map: SessionMapInterface | undefined
  hasLightMap: boolean
}

const SideOptions = ({
  className,
  projectIdentity,
  map,
  session,
  hasLightMap,
}: OptionsInterface) => {
  const dispatch = useDispatch()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity, map })
  const resetUtil = ResetUtil()

  const [optionsActive, toggleOptions] = React.useState(false)
  const [showSessionModal, toggleSessionModal] = React.useState(false)
  const [showAssetModal, toggleAssetModal] = React.useState(false)
  const [showLightsModal, toggleLightsModal] = React.useState(false)
  const [showOfflineModeModal, toggleOfflineModeModal] = React.useState(false)
  const [isConnected, setIsConected] = React.useState(false)
  const [showClearCacheModal, toggleClearCacheModal] = React.useState(false)
  const [clearCacheModalAction, setClearCacheModalAction] = React.useState('')

  const createSessionFireStore = async ({
    sessionId,
    projectName,
  }: ProjectIdentity) => {
    if (sessionId && projectName) {
      const response = await firebaseControlQuery.createSession({
        sessionId,
        projectName,
      })
      if (!response) {
        alert('Session creation experienced a conflict. Please try again.')
        dispatch(
          setProjectIdentity({
            ...projectIdentity,
            sessionId: '',
          })
        )
        toggleSessionModal(false)
      }
    }
  }

  const deleteSessionFireStore = async () => {
    const { sessionId, projectName } = projectIdentity
    if (sessionId && projectName) {
      await firebaseControlQuery.updateConnection(false)
      await firebaseControlQuery.deleteSession()
    }
  }

  const createSession = () => {
    toggleSessionModal(!showSessionModal)
    if (!projectIdentity.sessionId) {
      const updatedProjectIdentity = {
        ...projectIdentity,
        sessionId: createSessionId(),
      }
      dispatch(setProjectIdentity(updatedProjectIdentity))
      createSessionFireStore(updatedProjectIdentity)
    }
  }

  const disconnectSession = () => {
    const { sessionId, projectName } = projectIdentity
    if (sessionId && projectName && map) {
      firebaseControlQuery.updateConnection(false)
    }
  }

  const terminateSession = () => {
    toggleSessionModal(false)
    deleteSessionFireStore()
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        sessionId: '',
      })
    )
    dispatch(resetBuildingPageConfig())
  }

  const logout = () => {
    const firebasePort = getFirebasePort()
    dispatch(removeToken())
    dispatch(removeProjectIdentity())
    deleteSessionFireStore()
    dispatch(removeAppConfig())
    resetUtil.resetLocalStorage()
    if (firebasePort !== '') window.location.reload()
  }

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        vision: { sessionModal },
      } = session
      setIsConected(connected)
      if (connected) {
        toggleOptions(sessionModal)
        toggleSessionModal(sessionModal)
      }
      return
    }
    const { sessionId } = projectIdentity
    if (sessionId) {
      toggleSessionModal(true)
    }
  }, [session])

  return (
    <>
      <IdleTimeHandler>
        <div
          className={`flex h-screen transform items-center justify-center duration-300 ease-in-out 
         ${className} ${optionsActive ? 'translate-y-6' : 'translate-y-0'}`}
        >
          <div>
            <div
              className={`mb-3 ${
                optionsActive
                  ? 'relative translate-x-0'
                  : 'absolute translate-x-40'
              }`}
              style={optionsActive ? {} : { top: '38%' }}
            >
              {map && (
                <button
                  onClick={createSession}
                  type="button"
                  className={`block w-10 transform border-none duration-300 ease-in-out ${
                    optionsActive ? 'translate-x-0' : 'translate-x-40'
                  }`}
                >
                  <StatusSvg className="h-10 w-10 text-white drop-shadow-70" />
                </button>
              )}
              <ClearCache
                toggleModal={(toggle) => toggleClearCacheModal(toggle)}
                modalAction={clearCacheModalAction}
                getModalAction={setClearCacheModalAction}
                className={optionsActive ? 'translate-x-0' : 'translate-x-40'}
                projectName={projectIdentity.projectName}
              />
              <button
                onClick={() => toggleAssetModal(!showAssetModal)}
                type="button"
                className={`block w-10 transform border-none duration-300 ease-in-out ${
                  optionsActive ? 'translate-x-0' : 'translate-x-40'
                }`}
              >
                <ImageSvg className="h-10 w-10 text-white drop-shadow-70" />
              </button>
              <button
                onClick={() => toggleOfflineModeModal(!showOfflineModeModal)}
                type="button"
                className={`block w-10 transform border-none duration-300 ease-in-out ${
                  optionsActive ? 'translate-x-0' : 'translate-x-40'
                }`}
              >
                <OfflineSvg className="h-10 w-10 text-white drop-shadow-70" />
              </button>
              <button
                onClick={() => toggleLightsModal(!showLightsModal)}
                type="button"
                className={`block w-10 transform border-none duration-300 ease-in-out ${
                  optionsActive ? 'translate-x-0' : 'translate-x-40'
                }`}
              >
                <BulbSvg className="h-10 w-10 text-white drop-shadow-70" />
              </button>
              <button
                onClick={logout}
                type="button"
                className={`block transform border-none align-middle duration-700 ease-in-out ${
                  optionsActive ? 'translate-x-0' : 'translate-x-40'
                }`}
              >
                <LogoutSvg className="h-10 w-10 text-white drop-shadow-70" />
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => toggleOptions(!optionsActive)}
              >
                <DotsVerticalSvg className="h-10 w-10 text-white drop-shadow-70" />
              </button>
            </div>
          </div>
        </div>
      </IdleTimeHandler>
      <SessionModal
        isVisible={showSessionModal}
        connected={isConnected}
        sessionId={projectIdentity.sessionId}
        terminateSession={terminateSession}
        disconnectSession={disconnectSession}
        toggleModal={(toggle) => toggleSessionModal(toggle)}
      />
      <AssetModal
        isVisible={showAssetModal}
        toggleModal={(toggle) => toggleAssetModal(toggle)}
      />
      <LightsModal
        lightMap={projectIdentity.lightMap}
        isVisible={showLightsModal}
        hasLightMap={hasLightMap}
        toggleModal={(toggle) => toggleLightsModal(toggle)}
      />

      <OfflineModeModal
        isVisible={showOfflineModeModal}
        toggleModal={(toggle) => toggleOfflineModeModal(toggle)}
      />

      <ConfirmationModal
        isVisible={showClearCacheModal}
        toggleModal={(toggle) => toggleClearCacheModal(toggle)}
        getModalAction={setClearCacheModalAction}
        message="The matrix data will be cleaned from the cache, Click OK to confirm"
      />
    </>
  )
}

export default SideOptions
