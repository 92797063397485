import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setBuildingPageConfigByKey } from '@store/actionSlices/buildingPageConfig'
import { RootStateFirebase } from '@store/types'

import {
  BuildingBlocksSvg,
  CloseCircleSvg,
  InfoCircleSvg,
} from '@components/svg'

import { Unit } from '@api/building'

export interface configPopupProps {
  activeLevel: string
  activeUnit: Unit | undefined
  isUnitPanelFolded: boolean
  isUnitInfoPopupOpen: boolean
  isConnected: boolean
  showPrice: boolean
  hideSoldPrice: boolean
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
}

const UnitInfoPopup = ({
  activeLevel,
  activeUnit,
  isUnitPanelFolded,
  isUnitInfoPopupOpen,
  isConnected,
  showPrice,
  hideSoldPrice,
  hideUnitInfoName,
  hideInfoPanelAspect,
}: configPopupProps) => {
  const STATUS_SOLD = 'sold'
  const dispatch = useDispatch()

  const numberWithThousandSeparator = (number: string) => {
    const floatNumber = parseFloat(number).toFixed(0)
    return floatNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const canWeShowPrice =
    showPrice && !(activeUnit?.metas.status === STATUS_SOLD && hideSoldPrice)

  return (
    <div className="relative">
      <div
        className={`absolute z-30 transform rounded-tl-5xl bg-neutral-grey px-8 pt-11 duration-500 ease-in-out ${
          isConnected || isUnitPanelFolded
            ? 'h-screen'
            : 'h-screen-92vh sm-h:h-screen-91vh'
        } bottom-0  right-0 max-w-md ${
          isUnitInfoPopupOpen ? 'translate-x-0' : 'translate-x-full'
        } shadow-2xl`}
      >
        {!isConnected && (
          <button
            onClick={() => {
              dispatch(
                setBuildingPageConfigByKey({
                  key: 'unitInfoPopupOpen',
                  value: false,
                })
              )
            }}
            type="button"
            className="flex items-center"
          >
            <CloseCircleSvg
              className="mr-3 cursor-pointer"
              fill="hsl(1, 0%, 0%)"
            />
          </button>
        )}
        <div className="h-screen pt-5">
          <div className="h-3/4 overflow-hidden md:w-72 xl-h:h-5/6 md-h:h-5/6 sm-h:h-4/5">
            <div className="flex justify-between">
              {activeUnit?.blockId && (
                <div className="flex flex-col items-start">
                  <span className="text-sm text-gray-400">Block</span>
                  <span className="text-2xl capitalize text-black">
                    {activeUnit?.blockId}
                  </span>
                </div>
              )}
            </div>
            <div className="no-scrollbar h-full overflow-x-auto overflow-y-scroll">
              <div className="block pb-2 pt-4">
                <div className="text-base">
                  <span>Description</span>
                </div>
              </div>
              <div className="flex flex-col justify-between divide-y rounded bg-white px-4 py-2">
                <div className="flex items-center justify-between py-2">
                  <span className="flex items-center">
                    <BuildingBlocksSvg className="h-8 w-8" />
                    <span className="text-lg text-gray-400">Level</span>
                  </span>
                  <span className="text-lg text-black">{activeLevel}</span>
                </div>
                <div className="flex items-center py-2">
                  <span className="-ml-2">
                    <InfoCircleSvg fill="gray" width="32" height="32" />
                  </span>
                  <span className="text-lg capitalize">
                    {activeUnit?.metas?.bedConfig}
                  </span>
                </div>
                {!hideUnitInfoName && (
                  <div className="flex items-center justify-between py-2">
                    <div className="text-lg text-gray-400">
                      <span>Name</span>
                    </div>
                    <div className="text-left text-lg">
                      <span>Apt. {activeUnit?.name}</span>
                    </div>
                  </div>
                )}
                {!hideInfoPanelAspect && (
                  <div className="flex items-center justify-between py-2">
                    <div className="text-lg text-gray-400">
                      <span>Aspect</span>
                    </div>
                    <div className="text-left text-lg">
                      <span>{activeUnit?.metas?.aspect}</span>
                    </div>
                  </div>
                )}
              </div>

              {canWeShowPrice && (
                <div className="flex w-full flex-col items-start justify-between pt-6">
                  <div className="pb-2 text-base">
                    <span>Price</span>
                  </div>
                  <div className="flex w-full items-center justify-between rounded bg-white px-4 py-2 text-lg">
                    <span>
                      $
                      {numberWithThousandSeparator(
                        String(activeUnit?.metas?.price) ?? '0'
                      )}
                    </span>
                    <span className="text-sm text-gray-400">
                      $
                      {numberWithThousandSeparator(
                        String(activeUnit?.metas?.pricem2) ?? '0'
                      )}{' '}
                      (m<sup>2</sup>)
                    </span>
                  </div>
                </div>
              )}
              <div className="block pt-6">
                <div className="pb-2 text-base">
                  <span>
                    Area in (m<sup>2</sup>)
                  </span>
                </div>
                <div className="flex flex-col divide-y rounded bg-white px-4 py-2">
                  <div className="py-2">
                    <span className="mr-4 text-lg text-gray-400">Total</span>
                    <div className="float-right text-3xl">
                      <span className="mr-2 text-lg">
                        {Number(activeUnit?.metas?.totalArea)}
                      </span>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="mr-4 text-lg text-gray-400">Internal</span>
                    <div className="float-right text-3xl">
                      <span className="mr-2 text-lg">
                        {Number(activeUnit?.metas?.internalArea)}
                      </span>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="mr-4 text-lg text-gray-400">External</span>
                    <div className="float-right text-3xl">
                      <span className="mr-2 text-lg">
                        {Number(activeUnit?.metas?.externalArea)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectConfig: {
      showPrice,
      hideSoldPrice,
      hideUnitInfoName,
      hideInfoPanelAspect,
    },
  }: RootStateFirebase) => ({
    showPrice,
    hideSoldPrice,
    hideUnitInfoName,
    hideInfoPanelAspect,
  })
)(UnitInfoPopup)
