import { ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel'
import React from 'react'

import { GallerySlide, GallerySlider } from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import { ArrowSquareSvg } from '@components/svg'

import { TeamsInfo } from '@api/teams'

import AssetHandler from '@utilities/asset-handler'
import { generateId } from '@utilities/generate-id-util'

export interface SlideWrapperProps {
  activeSlide: number
  getActiveSlide: (arg: number) => void
  isConnected: boolean
  slideData: Array<TeamsInfo>
}

const SlideWrapper = ({
  activeSlide,
  getActiveSlide,
  isConnected,
  slideData,
}: SlideWrapperProps) => {
  const nextButton = generateId(10)
  const prevButton = generateId(10)

  const carouselContext = React.useContext(CarouselContext)

  React.useEffect(() => {
    const onChange = () => {
      getActiveSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <>
      <IdleTimeHandler>
        <div
          className={`m-12 flex gap-10 ${
            slideData.length > 1 ? '' : 'invisible'
          }`}
        >
          <ButtonBack
            id={prevButton}
            className={`${activeSlide === 0 ? 'opacity-50' : ''} ${
              isConnected ? 'invisible' : ''
            }`}
          >
            <ArrowSquareSvg />
          </ButtonBack>
          <ButtonNext
            id={nextButton}
            className={`${
              activeSlide === slideData.length - 1 ? 'opacity-50' : ''
            } ${isConnected ? 'invisible' : ''}`}
          >
            <ArrowSquareSvg rotate="right" />
          </ButtonNext>
        </div>
      </IdleTimeHandler>
      <div className="relative">
        <div className="absolute -left-36">
          <GallerySlider className="w-800px" classNameTrayWrap="w-400px">
            {slideData.map((team, key) => (
              <GallerySlide index={key} key={team.imageName}>
                <div
                  className={`bg-secondaryColour mr-10 flex h-36 items-center justify-center rounded-md p-10 transition delay-200 ease-in-out ${
                    activeSlide === key ? '' : 'opacity-30'
                  }`}
                >
                  <img
                    alt={team.imageName}
                    src={AssetHandler({
                      url: team.imageSource,
                      type: 'legacy',
                    })}
                  />
                </div>
              </GallerySlide>
            ))}
          </GallerySlider>
        </div>
      </div>
    </>
  )
}

export default SlideWrapper
