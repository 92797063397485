import React from 'react'

import Container from '@components/container'

export interface SomethingWentWrongProps {
  message?: string
}

const SomethingWentWrong = ({
  message = 'Whoops, looks like something went wrong.',
}: SomethingWentWrongProps) => (
  <Container
    className="flex items-center justify-center"
    background={{
      url: '/images/devsuite-bg.jpg',
      type: 'new',
      noSpliceUrl: true,
    }}
  >
    <div className="flex h-2/6 w-7/12 items-center justify-center rounded-xl bg-gradient-to-tr from-gray-100 to-gray-800">
      <h1 className="text-5xl sm:text-4xl">{message}</h1>
    </div>
  </Container>
)

export default SomethingWentWrong
