import { Slider, SliderProps } from 'pure-react-carousel'
import React from 'react'

import { GalleryControlInterface } from '@store/types'

export type GallerySliderInterface = SliderProps &
  (
    | {
        galleryControl?: GalleryControlInterface
        transitionType?: never
      }
    | {
        galleryControl?: never
        transitionType: string
      }
  )

const GallerySlider = ({
  children,
  galleryControl,
  transitionType,
  ...props
}: GallerySliderInterface) => (
  <Slider
    {...props}
    {...((galleryControl?.transitionType === 'fadeIn' ||
      transitionType === 'fadeIn') && {
      classNameAnimation: '!transition-opacity !duration-1000',
    })}
  >
    {children}
  </Slider>
)

export default GallerySlider
