import { Slide, SlideProps } from 'pure-react-carousel'
import React from 'react'

import { GalleryControlInterface } from '@store/types'

export type GallerySlideInterface = SlideProps &
  (
    | {
        galleryControl?: GalleryControlInterface
        transitionType?: never
      }
    | {
        galleryControl?: never
        transitionType: string
      }
  )

const GallerySlide = ({
  children,
  galleryControl,
  transitionType,
  ...props
}: GallerySlideInterface) => (
  <Slide
    {...props}
    {...((galleryControl?.transitionType === 'fadeIn' ||
      transitionType === 'fadeIn') && {
      classNameHidden:
        '!opacity-0 !transition-opacity !duration-1000 !transform-none',
      classNameVisible:
        '!opacity-100 !transition-opacity !duration-1000 !transform-none',
    })}
  >
    {children}
  </Slide>
)

export default GallerySlide
