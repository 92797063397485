import { ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel'
import React from 'react'
import { useDispatch } from 'react-redux'

import { setBuildingPageConfigByKey } from '@store/actionSlices/buildingPageConfig'
import { GalleryControlInterface, SessionMap, UnitGallery } from '@store/types'

import {
  GallerySlide,
  GallerySlider,
  SlideTrigger,
} from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import Player from '@components/player'

import { Unit } from '@api/building'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import handleMouseWheel from '@utilities/gallery-image-control-util'

import UnitInfoBar from './unit-info-bar'

export interface UnitCarouselContentInterface {
  unit: Unit | undefined
  gallery: Array<UnitGallery>
  session: SessionMap | undefined
  isConnected: boolean
  unitGalleryControlStyle?: string
  galleryControl?: GalleryControlInterface
}

const UnitCarouselContent = ({
  unit,
  gallery,
  session,
  isConnected,
  unitGalleryControlStyle = 'thumbnail',
  galleryControl,
}: UnitCarouselContentInterface) => {
  const dispatch = useDispatch()
  const dotContainerRef = React.useRef<HTMLDivElement>(null)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)

  const carouselContext = React.useContext(CarouselContext)
  const [slideCount, setSlide] = React.useState(
    carouselContext.state.currentSlide
  )

  const getImageSource = (unitGallery: UnitGallery) =>
    unitGallery.type === 'image' ? unitGallery.src : unitGallery.thumbnail || ''

  const updateUnitPanelState = (arg: React.SetStateAction<boolean>) => {
    setIsVideoPlaying(arg)
    if (isConnected === false) {
      dispatch(
        setBuildingPageConfigByKey({
          key: 'unitPanelFolded',
          value: arg === true,
        })
      )
    }
  }

  React.useEffect(() => {
    const onChange = () => {
      setSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <div
      onWheel={(e) => {
        if (!isVideoPlaying) {
          handleMouseWheel(e, 'nextButton', 'prevButton')
        }
      }}
      className="relative h-full w-full"
    >
      {!isConnected && gallery.length > 0 && !isVideoPlaying && (
        <IdleTimeHandler>
          <div
            className="absolute right-0 z-10 flex h-full items-center pr-7"
            onWheel={(e) => {
              handleMouseWheel(e, 'nextButton', 'prevButton')
            }}
          >
            <div>
              <ButtonNext id="nextButton" className="hidden">
                Next button
              </ButtonNext>
              <ButtonBack id="prevButton" className="hidden">
                Prev button
              </ButtonBack>
              <SlideTrigger
                type={unitGalleryControlStyle}
                items={gallery.map((item) => ({
                  text: item.label || '',
                  src: getImageSource(item),
                  type: 'legacy',
                  noSpliceUrl: item.noSpliceUrl,
                  gradiant: 0,
                }))}
                setCurrentSlide={setSlide}
                currentSlide={slideCount}
                nextButtonId="nextButton"
                previousButtonId="prevButton"
                dotContainerRef={dotContainerRef}
                alignClass={
                  unitGalleryControlStyle === 'label' ||
                  unitGalleryControlStyle === 'thumbnail'
                    ? 'text-right'
                    : ''
                }
              />
            </div>
          </div>
        </IdleTimeHandler>
      )}
      <div
        className={`absolute top-0 z-10 mt-10 w-full p-2 lg:mt-9 2xl:mt-9 sm-h:mt-10 ${
          isVideoPlaying ? 'hidden' : ''
        }`}
      >
        <UnitInfoBar unit={unit} isConnected={isConnected} />
      </div>
      <GallerySlider galleryControl={galleryControl}>
        {gallery.map((item: UnitGallery, itemIndex) => (
          <GallerySlide
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.src} - ${itemIndex}`}
            className="h-full"
            index={itemIndex}
            style={{ paddingBottom: 0 }}
            galleryControl={galleryControl}
          >
            <div
              className="background-cover h-full w-full"
              style={{
                ...setBackground(
                  AssetHandler({
                    url: getImageSource(item),
                    type: 'legacy',
                    noSpliceUrl: item.noSpliceUrl,
                  }),
                  {
                    gradiant: item.gradiant || 0,
                  }
                ),
              }}
            >
              {item.type === 'video' && (
                <Player
                  galleryName="unitGallery"
                  getPlayerState={updateUnitPanelState}
                  videoUrl={AssetHandler({
                    url: item.src,
                    type: 'legacy',
                  })}
                  isActive={itemIndex === slideCount}
                  playerControls={session?.unitGallery?.playerControl}
                />
              )}
            </div>
          </GallerySlide>
        ))}
      </GallerySlider>
    </div>
  )
}

export default UnitCarouselContent
