const handleMouseWheel = (
  event: React.WheelEvent,
  nextButtonId: string,
  prevButtonId: string
) =>
  event.deltaY < 0
    ? document.getElementById(prevButtonId)?.click()
    : document.getElementById(nextButtonId)?.click()

export default handleMouseWheel
