import React from 'react'

import { SvgProps } from '@components/svg/types'

const ExplorerSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    top: '0deg',
    down: '180deg',
    right: '90deg',
    left: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '48'}
      height={height || '48'}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 512 512"
    >
      <path
        fillRule="evenodd"
        d="M512 256c0 141.2-114.7 256-256 256C114.8 512 0 397.3 0 256S114.7 0 256 0s256 114.7 256 256zm-32 0c0-123.2-100.3-224-224-224C132.5 32 32 132.5 32 256s100.5 224 224 224 224-100.5 224-224zM160.9 124.6l86.9 37.1-37.1 86.9-86.9-37.1 37.1-86.9zm110 169.1l46.6 94h-14.6l-50-100-48.9 100h-14l51.1-106.9-22.3-9.4 6-14 68.6 29.1-6 14.3-16.5-7.1zm-11.8-116.3l68.6 29.4-29.4 68.3L230 246l29.1-68.6zm80.3 42.9l54.6 23.1-23.4 54.3-54.3-23.1 23.1-54.3z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ExplorerSvg
