import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setBedConfig } from '@store/actionSlices/bedConfig'
import { BuildingInterface, setBuilding } from '@store/actionSlices/building'
import { RootStateType } from '@store/store'
import { SessionMap } from '@store/types'

import { CloseCircleSvg } from '@components/svg'

import {
  BedConfig,
  selectFromResult as selectFromBedConfigResult,
  useGetBedConfigQuery,
} from '@api/bed-config'
import { selectFromResult, useGetBuildingQuery } from '@api/building'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import { truncateTextWithEllipsis } from '@utilities/helper'

export interface ConfigPopupProps {
  building: BuildingInterface
  isOpen: boolean
  toggle: (arg: boolean) => void
  projectName: string
  session: SessionMap | undefined
  bedConfig: Array<BedConfig>
  longhand: boolean
}

const ConfigPopup = ({
  isOpen,
  toggle,
  building,
  projectName,
  session,
  bedConfig,
  longhand = false,
}: ConfigPopupProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isConnected, setIsConnected] = useState(false)

  const configPopupContainerRef = React.useRef<HTMLDivElement>(null)

  const buildingPayload = useGetBuildingQuery(
    { projectName },
    { selectFromResult }
  )

  const bedConfigPayload = useGetBedConfigQuery(
    { projectName },
    { selectFromResult: selectFromBedConfigResult }
  )

  React.useEffect(() => {
    const { building: buildingData, isLoaded, aspects } = buildingPayload
    if (building.levels.length === 0 && isLoaded && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          aspects,
        })
      )
    }
  }, [buildingPayload, building])

  React.useEffect(() => {
    const { bedConfig: bedConfigData, isLoaded } = bedConfigPayload
    if (bedConfig.length === 0 && isLoaded && bedConfigData.length > 0) {
      dispatch(setBedConfig(bedConfigData))
    }
  }, [bedConfigPayload, bedConfig])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  function getStatus(config: BedConfig) {
    let statusClass = 'bg-red-500'
    if (config.availableUnits > 0) {
      statusClass =
        (config.availableUnits * 100) / config.totalUnits > 50
          ? 'bg-green-500'
          : 'bg-yellow-500'
    }
    return statusClass
  }

  function showLevel(config: BedConfig) {
    if (config.availableUnits) {
      const { activeLevel, activeUnit, activeBlock } = config
      dispatch(
        setBuilding({
          ...building,
          activeLevel,
          activeUnit,
          activeBlock: activeBlock || '',
        })
      )
      history.push('building')
    }
  }

  const getConfigName = (config: BedConfig) => {
    let name = ''

    if (longhand === true) {
      name = truncateTextWithEllipsis(config.configuration, 20, 17)
    } else {
      name =
        config.shortConfiguration.length < 1
          ? truncateTextWithEllipsis(config.configuration, 20, 17)
          : config.shortConfiguration
    }

    return name
  }

  const compareBedConfig = (bedConfig1: BedConfig, bedConfig2: BedConfig) => {
    const sortValue1 = longhand
      ? bedConfig1.configuration
      : bedConfig1.shortConfiguration
    const sortValue2 = longhand
      ? bedConfig2.configuration
      : bedConfig2.shortConfiguration
    if (sortValue1 < sortValue2) return -1
    if (sortValue1 > sortValue2) return 1
    return 0
  }

  return (
    <div className="relative">
      <div
        className={`absolute right-0 z-30 h-screen w-5/12 transform border-l-2 border-solid border-white bg-black bg-opacity-75 duration-500 ease-in-out 2xl:w-1/5 tablet:w-4/12 ${
          isOpen ? 'translate-x-0' : 'translate-x-120%'
        }`}
      >
        {!isConnected && (
          <button
            onClick={() => toggle(!isOpen)}
            type="button"
            className="relative"
          >
            <CloseCircleSvg className="absolute -left-14 top-4 cursor-pointer" />
          </button>
        )}
        <div
          ref={configPopupContainerRef}
          className="no-scrollbar h-screen overflow-x-auto px-10 pb-14"
        >
          {bedConfig.length === 0 || building.levels.length === 0 ? (
            <div className="text-white">No config found.</div>
          ) : (
            [...bedConfig].sort(compareBedConfig).map(
              (res) =>
                res.src !== '' && (
                  <div
                    onClick={() => {
                      showLevel(res)
                    }}
                    role="none"
                    key={res.configuration}
                    className="cursor-pointer"
                  >
                    <div className="py-4 text-white">
                      <span
                        title={res.configuration}
                        className="whitespace-nowrap text-2xl"
                      >
                        <span
                          className={`relative inline-flex h-3 w-3 rounded-full ${getStatus(
                            res
                          )} mr-2`}
                        ></span>
                        {getConfigName(res)}
                      </span>
                      <span className="float-right leading-extra-3">
                        {res.availableUnits} left
                      </span>
                    </div>
                    <div
                      className="background-cover h-64 w-full rounded bg-gray-500 pb-5"
                      style={{
                        ...setBackground(
                          AssetHandler({
                            url: res.src,
                            type: 'legacy',
                            noSpliceUrl: true,
                          }),
                          {
                            gradiant: res.availableUnits ? 0 : 0.5,
                          }
                        ),
                      }}
                    ></div>
                  </div>
                )
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    building,
    bedConfig,
    projectConfig: { longhand },
  }: RootStateType) => ({
    projectName,
    building,
    bedConfig,
    longhand,
  })
)(ConfigPopup)
