import React from 'react'

import { SvgProps } from '@components/svg/types'

const MenuSvg = ({ width, height, className, size, styles }: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
      }}
      width={width || '48'}
      height={height || '48'}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path d="M93,52H7c-1.1,0-2-0.9-2-2s0.9-2,2-2h86c1.1,0,2,0.9,2,2S94.1,52,93,52z M95,26c0-1.1-0.9-2-2-2H7c-1.1,0-2,0.9-2,2  s0.9,2,2,2h86C94.1,28,95,27.1,95,26z M95,74c0-1.1-0.9-2-2-2H7c-1.1,0-2,0.9-2,2s0.9,2,2,2h86C94.1,76,95,75.1,95,74z"></path>
    </svg>
  )
}

export default MenuSvg
