import React from 'react'

import { Configurations as ConfigurationInterface } from '@store/types'

export interface BedConfigInterface {
  type: string
  label: string
}

export interface Props {
  configs: ConfigurationInterface
  onChange: (arg: ConfigurationInterface) => void
}

const BedConfig = ({ configs, onChange }: Props) => {
  const [configurations, setConfigurations] =
    React.useState<ConfigurationInterface>({
      ...configs,
    })

  const bedConfigs: Array<BedConfigInterface> = [
    { type: 'bedroom', label: 'Bedroom' },
    { type: 'study', label: 'Study' },
    { type: 'bathroom', label: 'Bathroom' },
    { type: 'powderRoom', label: 'Powder Room' },
    { type: 'carpark', label: 'Car Park' },
  ]

  const handleBedConfigSelect = (configKey: string, increment: number) => {
    const key = configKey as keyof typeof configurations
    if (
      !(increment > 0 && configurations[key] >= 5) &&
      !(increment < 0 && configurations[key] === 0)
    ) {
      configurations[key] += increment
      setConfigurations({ ...configurations })
      onChange({ ...configurations })
    }
  }

  React.useEffect(() => {
    if (configs) {
      setConfigurations({ ...configs })
    }
  }, [configs])

  return (
    <div className="h-full divide-y px-4">
      {bedConfigs.map((configOption, index) => (
        <div
          key={configOption.label}
          className="flex flex-row items-center justify-between py-2"
          tabIndex={index}
        >
          <div className="w-2/5">
            <span>{configOption.label}</span>
          </div>
          <div className="self-left text-blue-500">
            {index === 0 && <span>{configurations.bedroom || 'Any'}</span>}
            {index === 1 && <span>{configurations.study || 'Any'}</span>}
            {index === 2 && <span>{configurations.bathroom || 'Any'}</span>}
            {index === 3 && <span>{configurations.powderRoom || 'Any'}</span>}
            {index === 4 && <span>{configurations.carpark || 'Any'}</span>}
          </div>
          <div>
            <span className="flex flex-row items-center justify-between divide-x rounded-3xl border">
              <button
                type="button"
                onClick={() => handleBedConfigSelect(configOption.type, -1)}
              >
                <span className="px-4">-</span>
              </button>
              <button
                type="button"
                onClick={() => handleBedConfigSelect(configOption.type, 1)}
              >
                <span className="px-4">+</span>
              </button>
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default BedConfig
