import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

export interface TeamsInfo {
  id: string
  development: string
  name: string
  label: string
  imageSource: string
  imageName: string
  description: string
  type: string
}

export interface TeamsData {
  projectId: string
  teamsInfo: Array<TeamsInfo>
}

export interface TeamsPayload {
  data: Array<TeamsData>
}

type TeamsType = {
  projectName: string
}

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getTeams: builder.query<TeamsPayload, TeamsType>({
      query: (params: TeamsType) => ({
        url: `/v1/${params.projectName}/teams${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  teamsInfo: data?.data.teamsInfo || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetTeamsQuery } = teamsApi
