import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import type { RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { ChevronSvg, CloseCircleSvg } from '@components/svg'

import {
  Level,
  Unit,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  InteractivePlanData,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

export interface ThreeDViewerProps {
  threeDViewerUrl: string
  projectName: string
  interactivePlan: InteractivePlanData
  building: BuildingInterface
}

const ThreeDViewer = ({
  threeDViewerUrl,
  projectName,
  interactivePlan,
  building,
}: ThreeDViewerProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [isThreeDViewerSlidePanelOpen, setThreeDViewerSlidePanelStatus] =
    React.useState(false)

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName },
    { selectFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const getCorrectBlockValue = (activeBlock: string, activeLevel: string) => {
    const level = building.levels.find(
      (lvl: Level) => activeLevel === lvl.level
    )
    if (!level) {
      return ''
    }
    const unit = level.data.find(
      (unt: Unit) => (unt?.blockId || '').replace(/\s/g, '') === activeBlock
    )
    if (!unit) {
      return ''
    }
    return unit.blockId || ''
  }

  const handleSnapLoaderEvent = (e: MessageEvent) => {
    try {
      const { name } = JSON.parse(e.data)
      if (name) {
        const [activeBlock, activeLevel] = name.split('_')
        const foundActiveBlock = getCorrectBlockValue(activeBlock, activeLevel)

        if (foundActiveBlock) {
          dispatch(
            setByFlag({
              flag: 'activeBlock',
              value: foundActiveBlock,
            })
          )
        }

        if (activeLevel) {
          dispatch(
            setByFlag({
              flag: 'activeLevel',
              value: activeLevel,
            })
          )
          history.push('building')
          return
        }

        console.log('Incorrect building data.')
      }
    } catch (error) {
      console.log('Incorrect format was given in mapping.')
    }
  }

  const handleInspaceEvent = (e: MessageEvent) => {
    const [activeBlock, activeLevel] = e.data?.route.split('_') ?? []

    if (e.data?.hasBlock && activeBlock) {
      dispatch(
        setByFlag({
          flag: 'activeBlock',
          value: activeBlock,
        })
      )
    }

    if (activeLevel) {
      dispatch(
        setByFlag({
          flag: 'activeLevel',
          value: activeLevel,
        })
      )
      history.push('building')
      return
    }

    console.log('Incorrect building data.')
  }

  window.parent.addEventListener(
    'message',
    React.useCallback((e: MessageEvent) => {
      if (e.data.child) {
        handleInspaceEvent(e)
        return
      }
      if (e.data) {
        handleSnapLoaderEvent(e)
      }
    }, [])
  )

  React.useEffect(() => {
    const { maps } = interactivePayload

    if (!interactivePlan.areaView?.image && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload])

  React.useEffect(() => {
    if (!threeDViewerUrl) {
      history.push('area-view')
    }
  }, [threeDViewerUrl])

  React.useEffect(() => {
    const { building: buildingData, aspects } = buildingPayload
    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          aspects,
          activeLevel: buildingData[0]?.level,
        })
      )
    }
  }, [buildingPayload, building])

  return (
    <Container>
      <div className="relative z-10">
        <div className="absolute right-0">
          <div
            className="flex h-screen flex-row items-center justify-between opacity-0 transition-opacity duration-500 hover:opacity-100"
            onMouseLeave={() => setThreeDViewerSlidePanelStatus(false)}
          >
            <div
              className={`transition-all duration-500 ${
                isThreeDViewerSlidePanelOpen
                  ? 'w-72 opacity-100'
                  : 'w-0 opacity-0'
              }`}
            >
              <div className="no-scrollbar flex h-416px w-72 flex-col items-end justify-center overflow-y-auto pr-10 text-right">
                <div className="block h-20 drop-shadow">
                  <Link
                    to="vision"
                    className="flex items-center justify-start p-5 text-white drop-shadow-40"
                  >
                    <CloseCircleSvg
                      size="m"
                      fill="white"
                      className="drop-shadow-70"
                    />
                    <span className="pl-2 drop-shadow-70">Return</span>
                  </Link>
                </div>
              </div>
            </div>
            <button
              className={`slide-panel relative w-10 transform cursor-pointer text-right opacity-0 transition-all duration-500 hover:opacity-100 ${
                isThreeDViewerSlidePanelOpen ? '-rotate-90' : 'rotate-90'
              }`}
              type="button"
              onClick={() =>
                setThreeDViewerSlidePanelStatus(!isThreeDViewerSlidePanelOpen)
              }
            >
              <ChevronSvg className="animate-bounce cursor-pointer text-white drop-shadow-40" />
            </button>
          </div>
        </div>
      </div>
      <DataHandler
        payload={{
          ...interactivePayload,
          data: true,
        }}
      >
        <iframe
          title="threeDViewer"
          src={threeDViewerUrl}
          className="h-full w-full overflow-hidden border-0"
          height="100%"
          width="100%"
        ></iframe>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectConfig,
    projectIdentity: { projectName },
    interactivePlan,
    building,
  }: RootStateTypeExtra) => ({
    threeDViewerUrl: projectConfig.threeDViewerUrl,
    projectName,
    interactivePlan,
    building,
  })
)(ThreeDViewer)
