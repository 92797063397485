import React from 'react'

import { TokenPayloadInterface } from '@store/actionSlices/token'

import { hasToken } from './helper'
import { notifySuccess } from './notifier'
import ResetUtil from './reset-util'

interface LocalStorageCleanerUtilProps {
  token: TokenPayloadInterface
}

const LocalStorageCleanerUtil = ({ token }: LocalStorageCleanerUtilProps) => {
  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const cacheExpirationMinutes = Number(process.env.REACT_APP_CACHE_EXPIRATION)

  const resetUtil = ResetUtil()

  const resetInterval = (): void => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(undefined)
    }
  }

  const setStorageCleanInterval = () => {
    if (!hasToken(token) || cacheExpirationMinutes <= 0) {
      resetInterval()
      return
    }

    if (!intervalId) {
      setIntervalId(
        setInterval(() => {
          resetUtil.resetApiState()
          resetUtil.resetStore()
          notifySuccess('Showcase has refreshed.')
        }, 1000 * (cacheExpirationMinutes * 60))
      )
    }
  }

  React.useEffect(() => {
    setStorageCleanInterval()
  }, [token])
}

export default LocalStorageCleanerUtil
