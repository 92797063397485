import { fabric } from 'fabric'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { Polygon as InteractivePolygon, Point } from '@api/interactive-plan'

import AssetHandler from '@utilities/asset-handler'
import getWindowDimensions from '@utilities/window-dimensions'

import { getScaledAttribute } from './canvas-util'
import EventsUtil from './events'
import LabelUtil from './label'
import MarkerUtil from './marker'
import PolygonUtil from './polygon'
import RectUtil from './rect'

export interface Polygon extends InteractivePolygon {
  markerColour?: string
  activeByDefault?: boolean
  onClick?: () => void
  postFix?: string | number
  disabled?: boolean
}

export interface CustomObject extends fabric.Object {
  edit?: boolean
  points?: any
}

export interface CustomGroup extends fabric.Group {
  arrayIndex: number
  onGroupSelect?: () => void
  id: string
  label?: string
}

export interface CanvasData {
  image: string
  polygons: Array<Polygon>
}

export interface CanvasRefInterface {
  artificialTrigger(arg: string): void
  toggleLabels(): void
  setPolyActive(): void
  setCanvas(): void
}

export interface CanvasProps {
  id: string
  canvasData: CanvasData
  parentRef?: React.RefObject<HTMLDivElement>
  staticWidth?: number
  staticHeight?: number
  hasLabel?: boolean
  showLabels?: boolean
  labelPrefix?: string
  isRendering?: (arg: boolean) => void
  ratio?: string
}

declare global {
  interface Window {
    canvasData: CanvasData
    hasLabel?: boolean
  }
}

const CanvasInteractive = forwardRef<
  CanvasRefInterface | undefined,
  CanvasProps
>(
  (
    {
      id,
      canvasData,
      parentRef,
      staticWidth = 0,
      staticHeight = 0,
      hasLabel,
      labelPrefix,
      showLabels,
      isRendering,
      ratio = 'max',
    },
    ref
  ) => {
    window.canvasData = canvasData

    const fabricRef = React.useRef<fabric.Canvas | null>(null)
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null)

    const windowDimensions = getWindowDimensions()
    const marker = MarkerUtil()
    const polygonUtil = PolygonUtil()
    const labelUtil = LabelUtil()
    const rectUtil = RectUtil()
    const [bgImage, setBgImage] = useState<null | fabric.Image>(null)
    const eventsUtil = EventsUtil({ labelPrefix: labelPrefix || '' })

    const [canWindowRender, setCanWindowRender] = useState(false)

    const [rendering, setRendering] = useState(false)
    const [timeoutIdScreenChange, setTimeoutIdScreenChange] =
      useState<NodeJS.Timeout>()

    const GroupBase = {
      objectCaching: false,
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
    }

    const defaultFill = 'rgba(255, 255, 255, 0.1)'

    const eventUtilMouseMove = React.useCallback(
      (e) => eventsUtil.mouseMove(e, fabricRef?.current),
      []
    )
    const eventUtilMouseOver = React.useCallback(
      (e) => eventsUtil.mouseOver(e, fabricRef?.current),
      []
    )
    const eventUtilMouseOut = React.useCallback(
      (e) => eventsUtil.mouseOut(e, fabricRef?.current),
      []
    )
    const eventUtilMouseUp = React.useCallback(
      (e) => eventsUtil.mouseUp(e, fabricRef?.current),
      []
    )

    const clearEvents = () => {
      if (fabricRef?.current) {
        fabricRef?.current?.off('mouse:move', eventUtilMouseMove)
        fabricRef?.current?.off('mouse:over', eventUtilMouseOver)
        fabricRef?.current?.off('mouse:out', eventUtilMouseOut)
        fabricRef?.current?.off('mouse:up', eventUtilMouseUp)
      }
    }

    const setEvents = () => {
      clearEvents()
      if (fabricRef?.current) {
        fabricRef?.current?.on('mouse:move', eventUtilMouseMove)
        fabricRef?.current?.on('mouse:over', eventUtilMouseOver)
        fabricRef?.current?.on('mouse:out', eventUtilMouseOut)
        fabricRef?.current?.on('mouse:up', eventUtilMouseUp)
      }
    }

    const cleanObjects = () => {
      if (!canvasData?.polygons) {
        return
      }
      if (fabricRef.current) {
        fabricRef.current
          .getObjects()
          .forEach((obj) => fabricRef?.current?.remove(obj))
      }
    }

    const setObjectsInCanvas = (image: fabric.Image) => {
      canvasData.polygons.forEach((polygon, index) => {
        const {
          angle,
          points,
          coordinates,
          polyCoordinates,
          markerCoordinates,
          markerColour,
          markerSize,
          label,
          type,
          polySize,
          rectAngle,
          rectSize,
          rectCoordinates,
          size,
          groupId,
        } = polygon

        const bgSize = {
          width: image?.getScaledWidth() || 0,
          height: image?.getScaledHeight() || 0,
        }

        const polyAttrib = polygonUtil.getPolyAttributes(points)

        const groupWidth = getScaledAttribute(size.width, bgSize.width)
        const groupHeight = getScaledAttribute(size.height, bgSize.height)

        const markerGroup = marker.createMarker({
          size,
          type,
          bgSize,
          coordinates,
          markerColour: markerColour || '',
          markerCoordinates,
          widthRatio: groupWidth / (polyAttrib.width || 0),
          heightRatio: groupHeight / (polyAttrib.height || 0),
          markerSize,
          label: label || groupId,
        })

        const rect = rectUtil.createRect({
          size,
          bgSize,
          rectSize,
          rectAngle,
          coordinates,
          rectCoordinates,
        })

        const polyGroup = polygonUtil.createPolyGroup({
          size,
          bgSize,
          polySize,
          coordinates,
          points,
          polyCoordinates,
        })

        const obj = new fabric.Group([polyGroup, markerGroup, rect], {
          ...GroupBase,
          angle,
        }) as CustomGroup

        obj.arrayIndex = index

        if (!polygon?.disabled) {
          obj.onGroupSelect = polygon?.onClick
        }

        obj.id = groupId
        obj.label = polygon?.label || ''
        obj.add(markerGroup)

        if (fabricRef.current) {
          fabricRef.current.add(obj)
        }
      })
      setRendering(false)
    }

    const drawStaticLabels = (label: string, coordinates: Point) => {
      if (fabricRef.current) {
        const textAttrib = labelUtil.getTextAttribute(label)
        const labelGroup = labelUtil.createLabel({
          label,
          groupOverride: {
            left: coordinates.x - (textAttrib.width || 0) / 2,
            top: coordinates.y - (textAttrib.height || 0) * 2,
            selectable: false,
            type: 'staticLabelGroup',
            opacity: 0,
          } as fabric.Group,
        })
        return labelGroup
      }
      return null
    }

    const toggleLabels = () => {
      if (fabricRef.current) {
        if (showLabels) {
          fabricRef.current.getObjects().forEach((obj) => {
            if (obj.isType('group')) {
              const mainGroup = obj as CustomGroup
              const circle = mainGroup?._objects.find(
                (res) => res.name === 'MarkerGroup'
              ) as fabric.Circle
              if (circle) {
                const label = drawStaticLabels(
                  mainGroup?.label || `${labelPrefix} ${mainGroup.id}`,
                  {
                    x:
                      (mainGroup?.left || 0) +
                      (mainGroup?.width || 0) / 2 +
                      (circle?.left || 0),
                    y:
                      (mainGroup?.top || 0) +
                      (mainGroup?.height || 0) / 2 +
                      (circle?.top || 0),
                  }
                )
                if (label) {
                  label.animate(
                    {
                      opacity: 1,
                    },
                    {
                      onChange: fabricRef.current?.renderAll.bind(
                        fabricRef.current
                      ),
                      duration: 300,
                      easing: fabric.util.ease.easeInSine,
                    }
                  )
                  fabricRef.current?.add(label)
                }
              }
            }
          })
        } else {
          fabricRef.current.getObjects().forEach((obj) => {
            if (obj.type === 'staticLabelGroup') {
              obj.animate(
                {
                  opacity: 0,
                },
                {
                  onChange: () => {
                    fabricRef.current?.renderAll.bind(fabricRef.current)
                    setTimeout(() => {
                      fabricRef.current?.remove(obj)
                    }, 300)
                  },
                  duration: 300,
                  easing: fabric.util.ease.easeInSine,
                }
              )
            }
          })
        }
      }
    }

    const setPolyActive = () => {
      if (fabricRef.current) {
        canvasData.polygons.forEach((polygon) => {
          const { groupId, activeByDefault, color } = polygon
          fabricRef.current?.forEachObject((obj1) => {
            if (obj1.isType('group')) {
              const outerGroup = obj1 as CustomGroup
              outerGroup?.forEachObject((obj2) => {
                if (obj2.name === 'PolyGroup') {
                  const polyGroup = obj2 as CustomGroup
                  polyGroup.forEachObject((polObj) => {
                    if (outerGroup?.id === groupId) {
                      polObj.animate(
                        {
                          fill: activeByDefault ? color : defaultFill,
                          opacity: activeByDefault ? 0.7 : 0.1,
                        },
                        {
                          onChange: fabricRef.current?.renderAll.bind(
                            fabricRef.current
                          ),
                          duration: 300,
                          easing: fabric.util.ease.easeInSine,
                        }
                      )
                    }
                  })
                }
              })
            }
          })
        })
      }
    }

    const drawCanvas = () => {
      if (fabricRef.current) {
        setRendering(true)
        fabric.Image.fromURL(
          AssetHandler({
            url: canvasData.image,
            type: 'new',
            noSpliceUrl: true,
          }),
          (oImg) => {
            oImg.set('opacity', 0)
            const widthRatio =
              (fabricRef.current?.width || 0) / (oImg?.width || 0)
            const heightRatio =
              (fabricRef.current?.height || 0) / (oImg?.height || 0)
            const maxRatio = Math.max(widthRatio, heightRatio)
            const minRatio = Math.min(widthRatio, heightRatio)

            if (heightRatio > widthRatio) {
              oImg.scale(ratio === 'max' ? maxRatio : minRatio)
            } else {
              oImg.set({
                scaleX: widthRatio,
                scaleY: heightRatio,
                noScaleCache: false,
              })
            }
            oImg.set({ objectCaching: false })
            oImg.animate('opacity', 1, {
              duration: 300,
              onChange: () => {
                fabricRef.current?.setBackgroundImage(
                  oImg,
                  fabricRef.current?.renderAll.bind(fabricRef.current)
                )
                cleanObjects()
              },
              onComplete: () => {
                if (canvasData?.polygons) {
                  setObjectsInCanvas(oImg)
                  setBgImage(oImg)
                  setEvents()
                  setPolyActive()
                  toggleLabels()
                  fabricRef.current?.renderAll()
                }
              },
            })
          }
        )
      }
    }

    const setCanvasSize = () => {
      if (fabricRef.current && parentRef) {
        const canvasWrapper = parentRef?.current?.parentElement
        fabricRef.current?.setWidth(canvasWrapper?.clientWidth || staticWidth)
        fabricRef.current?.setHeight(
          canvasWrapper?.clientHeight || staticHeight
        )
        fabricRef.current?.renderAll()
      }
    }

    const artificialTrigger = (groupId: string) => {
      if (fabricRef.current) {
        fabricRef.current.getObjects().forEach((obj) => {
          if (obj.isType('group')) {
            const activeGroup = obj as CustomGroup
            if (activeGroup.id === groupId) {
              const mousePoint = obj.getCenterPoint() as fabric.Point
              const circle = activeGroup?._objects.find(
                (res) => res.name === 'MarkerGroup'
              ) as fabric.Circle
              const newzoom = 3
              fabric.util.animate({
                startValue: fabricRef.current?.getZoom(),
                endValue: newzoom,
                duration: 1000,
                onChange: (zoomvalue) => {
                  const canvasRect = new fabric.Rect({
                    width: fabricRef.current?.width,
                    height: fabricRef.current?.height,
                    fill: '#000',
                    opacity: 0,
                    objectCaching: false,
                  })
                  canvasRect.animate('opacity', 1, {
                    duration: 1000,
                    easing: fabric.util.ease.easeInExpo,
                  })
                  fabricRef.current?.add(canvasRect)
                  fabricRef.current?.zoomToPoint(
                    {
                      x: mousePoint.x + (circle?.left || 0),
                      y: mousePoint.y + (circle?.top || 0),
                    },
                    zoomvalue
                  )
                  fabricRef.current?.renderAll()
                },
                onComplete: () => {
                  if (activeGroup?.onGroupSelect) {
                    activeGroup.onGroupSelect()
                  }
                },
              })
            }
          }
        })
      }
    }

    const setCanvas = () => {
      if (fabricRef.current) {
        if (bgImage) {
          bgImage.animate('opacity', 0, {
            duration: 300,
            onChange: () => {
              cleanObjects()
              fabricRef.current?.setBackgroundImage(
                bgImage,
                fabricRef.current?.renderAll.bind(fabricRef.current)
              )
            },
            onComplete: () => {
              setCanvasSize()
              drawCanvas()
            },
          })
          return
        }
        cleanObjects()
        setCanvasSize()
        drawCanvas()
        setCanWindowRender(true)
      }
    }

    const initCanvas = () => {
      const canvasWrapper = parentRef?.current?.parentElement
      fabric.Object.prototype.objectCaching = false
      fabricRef.current = new fabric.Canvas(canvasRef?.current, {
        width: canvasWrapper?.clientWidth || staticWidth,
        height: canvasWrapper?.clientHeight || staticHeight,
        selection: false,
        preserveObjectStacking: true,
        perPixelTargetFind: true,
        hoverCursor: 'pointer',
      })
      setTimeout(() => {
        setCanvas()
      }, 100)
    }

    useImperativeHandle(ref, () => ({
      artificialTrigger,
      toggleLabels,
      setPolyActive,
      setCanvas,
    }))

    useEffect(() => {
      if (isRendering) {
        isRendering(rendering)
      }
    }, [rendering])

    useEffect(() => {
      if (!canWindowRender) {
        return
      }
      if (timeoutIdScreenChange) {
        clearTimeout(timeoutIdScreenChange)
      }
      const timeoutId = setTimeout(() => {
        setCanvas()
      }, 100)
      setTimeoutIdScreenChange(timeoutId)
    }, [windowDimensions])

    const removeCanvasChildren = () => {
      const children = canvasRef?.current?.parentElement?.children
      if (children) {
        Array.from(children).forEach((res) => {
          res?.remove()
        })
      }
    }

    React.useLayoutEffect(() => {
      initCanvas()
      return () => {
        clearEvents()
        fabricRef.current?.setWidth(0)
        fabricRef.current?.setHeight(0)
        fabricRef?.current?.clear()
        fabricRef?.current?.renderAll()
        fabricRef?.current?.remove()
        removeCanvasChildren()
      }
    }, [])

    React.useLayoutEffect(() => {
      window.hasLabel = hasLabel
    }, [hasLabel])

    return <canvas ref={canvasRef} key={id} />
  }
)

export default CanvasInteractive
