import { fabric } from 'fabric'

export interface Point {
  x: number
  y: number
}

export interface LabelParams {
  label: string
  rectOverride?: fabric.Rect
  groupOverride?: fabric.Group
}

const Label = (): {
  getTextAttribute: (label: string) => {
    width: number
    height: number
  }
  createLabel: (params: LabelParams) => fabric.Group
} => {
  const TextObjectBase = {
    fontSize: 18,
    originX: 'center',
    originY: 'center',
    fontFamily: 'GT Walsheim Pro Regular',
  }

  const TextContainerBase = {
    originX: 'center',
    originY: 'center',
    rx: 5,
    ry: 5,
    fill: '#fff',
  }

  const LabelGroupBase = {
    selectable: false,
    type: 'labelGroup',
  }

  const getTextAttribute = (label: string) => {
    const textObject = new fabric.Text(label, TextObjectBase)
    return {
      width: textObject.getScaledWidth() + 40,
      height: textObject.getScaledHeight() + 15,
    }
  }

  const createLabel = ({ label, rectOverride, groupOverride }: LabelParams) => {
    const textObject = new fabric.Text(label, TextObjectBase)
    const textContainer = new fabric.Rect({
      ...TextContainerBase,
      width: textObject.getScaledWidth() + 40,
      height: textObject.getScaledHeight() + 15,
      ...(rectOverride || {}),
    })
    return new fabric.Group([textContainer, textObject], {
      ...LabelGroupBase,
      width: textObject.getScaledWidth() + 40,
      height: textObject.getScaledHeight() + 15,
      ...(groupOverride || {}),
    })
  }

  return {
    createLabel,
    getTextAttribute,
  }
}

export default Label
