import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { RootStateFirebase } from '@store/types'

import Container from '@components/container'

import { hasToken } from '@utilities/helper'

interface PropsType {
  token: TokenPayloadInterface
}

const PageNotFound = ({ token }: PropsType) => {
  const history = useHistory()

  React.useEffect(() => {
    if (!hasToken(token)) {
      history.push('/')
    }
  }, [token])

  return (
    <Container
      className="flex items-center justify-center"
      background={{
        url: '/images/devsuite-bg.jpg',
        type: 'new',
        noSpliceUrl: true,
      }}
    >
      <div className="flex h-2/6 w-7/12 items-center justify-center rounded-xl bg-gradient-to-tr from-gray-100 to-gray-800">
        <h1 className="text-5xl sm:text-4xl">404 Page not found.</h1>
      </div>
    </Container>
  )
}

export default connect(({ token }: RootStateFirebase) => ({
  token,
}))(PageNotFound)
