import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import {
  setMarkerColour,
  setProjectConfig,
} from '@store/actionSlices/projectConfig'
import type { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { ArrowSquareSvg } from '@components/svg'

import {
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  selectFromResult as selectFromResultConfig,
  useGetConfigQuery,
} from '@api/config'
import {
  InteractivePlanData,
  MapContent,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import getSession from '@utilities/firebase-util'

export interface AreaProps {
  session: SessionMap | undefined
  projectName: string
  interactivePlan: InteractivePlanData
  building: BuildingInterface
  areaMarkerColour: string
}

const Area = ({
  session,
  projectName,
  interactivePlan,
  building,
  areaMarkerColour,
}: AreaProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [area, setArea] = React.useState<MapContent>({
    image: '',
    polygons: [],
  })
  const [isConnected, setIsConnected] = React.useState(false)

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName },
    { selectFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const { configInfo, markerColour, isLoaded } = useGetConfigQuery(
    { projectName },
    { selectFromResult: selectFromResultConfig }
  )

  React.useEffect(() => {
    if (configInfo && Object.keys(configInfo).length > 0) {
      dispatch(setProjectConfig({ ...configInfo }))
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (markerColour && Object.keys(markerColour).length > 0) {
      dispatch(setMarkerColour(markerColour))
    }
  }, [isLoaded])

  const setInteractiveAction = (areaViewMap: MapContent) => {
    const areaData = {
      ...areaViewMap,
      polygons: areaViewMap.polygons.map((poly) => ({
        ...poly,
        onClick: () => {
          dispatch(
            setByFlag({
              flag: 'activeBlock',
              value: poly.groupId,
            })
          )
          if (!isConnected) {
            history.push('level-view')
          }
        },
        markerColour: areaMarkerColour,
      })),
    }
    return areaData
  }

  React.useEffect(() => {
    setRenderCanvas(true)
  }, [])

  React.useEffect(() => {
    if (interactivePlan.areaView?.image) {
      setArea(setInteractiveAction(interactivePlan.areaView))
    }
  }, [interactivePlan, isConnected])

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (!interactivePlan.areaView?.image && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload, interactivePlan])

  React.useEffect(() => {
    const { building: buildingData, aspects } = buildingPayload
    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          aspects,
          activeLevel: buildingData[0]?.level,
        })
      )
    }
  }, [buildingPayload, building])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        areaView: { triggerBuilding },
        building: { activeBlock },
      } = session
      if (activeBlock) {
        dispatch(
          setByFlag({
            flag: 'activeBlock',
            value: activeBlock,
          })
        )
      }

      if (triggerBuilding && renderCanvas) {
        let polyToTrigger: string | undefined =
          area?.polygons[0]?.groupId || 'BuildingGroup'
        if (activeBlock) {
          const foundItem = area?.polygons.find(
            (res) => activeBlock === res.groupId
          )?.groupId
          if (foundItem) {
            polyToTrigger = foundItem
          }
        }
        canvasRef?.current?.artificialTrigger(polyToTrigger || 'BuildingGroup')
      }
      setIsConnected(connected)
    }
  }, [session])

  return (
    <>
      <div className={`relative z-50 ${isConnected ? 'invisible' : ''}`}>
        <IdleTimeHandler>
          <Link to="vision" className="fixed pl-5 pt-5">
            <ArrowSquareSvg size="m" className="drop-shadow-70" />
          </Link>
        </IdleTimeHandler>
      </div>
      <DataHandler
        payload={{
          ...buildingPayload,
          data: building.levels.length > 0 && interactivePlan.areaView.image,
        }}
      >
        <Container
          background={{
            url: area?.image || '',
            type: 'new',
            noSpliceUrl: true,
          }}
        >
          {area.polygons.length > 0 && (
            <div
              key="area-canvas"
              data-testid="area-canvas"
              ref={canvasContainerRef}
              className="relative h-full w-full overflow-hidden"
            >
              <CanvasInteractive
                ref={canvasRef}
                id="area-canvas"
                canvasData={area}
                parentRef={canvasContainerRef}
              />
            </div>
          )}
        </Container>
      </DataHandler>
    </>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    interactivePlan,
    building,
    projectConfig: {
      markerColour: { area: areaMarkerColour },
    },
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    interactivePlan,
    building,
    areaMarkerColour,
  })
)(Area)
