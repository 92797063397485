import React from 'react'

import { SvgProps } from '@components/svg/types'

const ArrowSquareSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
  fill,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    left: '0deg',
    right: '180deg',
    top: '90deg',
    down: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '48'}
      height={height || '48'}
    >
      <path
        d="M 36.06818 0 C 42.66 0 48 5.32309 48 11.91491 L 48 36.06818 C 48 42.66 42.66 48 36.06818 48 L 11.93182 48 C 5.34 48 0 42.66 0 36.06818 L 0 11.91491 C 0 5.32309 5.34 0 11.93182 0 Z M 36.06818 2.02855 L 11.93182 2.02855 C 9.30614 2.01659 6.78455 3.05434 4.9279 4.91099 C 3.07125 6.76764 2.03349 9.28923 2.04545 11.91491 L 2.04545 36.06818 C 2.03349 38.69386 3.07125 41.21545 4.9279 43.0721 C 6.78455 44.92875 9.30614 45.96651 11.93182 45.95455 L 36.06818 45.95455 C 38.69386 45.96651 41.21545 44.92875 43.0721 43.0721 C 44.92875 41.21545 45.96651 38.69386 45.95455 36.06818 L 45.95455 11.91491 C 45.96651 9.28923 44.92875 6.76764 43.0721 4.91099 C 41.21545 3.05434 38.69386 2.01659 36.06818 2.02855 Z M 20.21564 16.89218 C 20.66178 16.90705 21.05361 17.19343 21.20401 17.61456 C 21.35441 18.03569 21.23288 18.50615 20.89745 18.80127 L 16.44927 22.90909 L 34.31291 22.90909 C 34.34707 22.90748 34.38129 22.90748 34.41545 22.90909 C 35.01704 22.93745 35.48177 23.44886 35.45346 24.05135 C 35.42514 24.65384 34.9145 25.11927 34.31291 25.09091 L 16.44927 25.09091 L 20.89855 29.18182 C 21.34485 29.5875 21.37844 30.2789 20.97357 30.7261 C 20.56869 31.1733 19.87867 31.20696 19.43236 30.80127 L 12.90382 24.80127 C 12.67501 24.59452 12.5444 24.30027 12.5444 23.99155 C 12.5444 23.68282 12.67501 23.38857 12.90382 23.18182 L 19.43236 17.18182 C 19.64491 16.98449 19.92722 16.88024 20.21673 16.89218 Z"
        fill={fill || 'hsl(0, 0%, 100%)'}
      ></path>
    </svg>
  )
}

export default ArrowSquareSvg
