import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

export interface Configuration {
  bed: string | number
  study: string | number
  bath: string | number
  powderRoom: string | number
  car: string | number
}

export interface ViewLineImage {
  src: string
  label: string
}

export interface Metas {
  internalArea: number
  externalArea: number
  totalArea: number
  aspect: string | null
  aspectViewlines: string | null
  aspectShort: string
  viewImage: Array<string> | null
  viewLineImages: Array<ViewLineImage> | null
  floorImage: string
  floorLabel?: string
  floorPdf: string
  level: string
  classification: string
  notes: string | null
  projectUrl: string
  bedConfig: string
  price: number
  pricem2: number
  status: string
  class: string
  statusName: string
  productShort: Array<string> | string
  configurations: Configuration
}

export interface Unit {
  id: string
  order: string
  aspect: string
  agentDimmed: boolean
  area: number
  name: string
  width: number
  metas: Metas
  blockId: string | null
  extras?: any
}

export interface Level {
  level: string
  data: Array<Unit>
}

export interface BuildingPayload {
  data: Array<Level>
}

type BuildingType = {
  projectName: string
}

export const buildingApi = createApi({
  reducerPath: 'buildingApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBuilding: builder.query<BuildingPayload, BuildingType>({
      query: (params: BuildingType) => ({
        url: `/v1/${params.projectName}/building`,
        method: 'get',
      }),
    }),
  }),
})

const setAspects = (building: Array<Level>) => {
  let aspectArray: Array<string> = ['All']
  building.forEach(({ data }: Level) => {
    data.forEach(({ metas: { aspect } }: Unit) => {
      if (aspect) {
        const aspectTrimmed = aspect.trim()
        if (aspectTrimmed !== '' && !aspectArray.includes(aspectTrimmed)) {
          aspectArray = [...aspectArray, aspectTrimmed]
        }
      }
    })
  })
  return aspectArray
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  building: data?.data || [],
  aspects: setAspects(data?.data || []) || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetBuildingQuery } = buildingApi
