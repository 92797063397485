import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setByFlag } from '@store/actionSlices/building'
import type { RootStateFirebase, SessionMap } from '@store/types'
import { UnitFilterInterface } from '@store/types'

import { CanvasInteractive } from '@components/showcase-canvas'
import {
  CanvasRefInterface,
  Polygon,
} from '@components/showcase-canvas/canvas-interactive'

import { Level, Unit } from '@api/building'
import { MarkerColourInterface } from '@api/config'
import { MapContent, MappingCollection } from '@api/interactive-plan'

import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

export interface FloorplanCanvasProps {
  activeLevel: string
  activeBlock: string
  isFullWidth: boolean
  floorplan: MappingCollection
  session: SessionMap | undefined
  unitFilter: UnitFilterInterface
  units: Array<Unit>
  markerColour: MarkerColourInterface
  showPrice: boolean
  unitPrefix: string
  ratio?: string
}

const FloorplanCanvas = ({
  units,
  unitFilter,
  floorplan,
  session,
  activeLevel,
  activeBlock,
  isFullWidth: isFullWidthProp,
  markerColour,
  showPrice,
  unitPrefix,
  ratio = 'max',
}: FloorplanCanvasProps) => {
  const dispatch = useDispatch()

  const prevReference = React.useRef<{ activeLevel: string }>({
    activeLevel,
  })
  const prevActiveBlockReference = React.useRef<{ activeBlock: string }>({
    activeBlock,
  })
  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()

  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const [isFullWidth, setIsFullWidth] = React.useState(isFullWidthProp)

  const [floorplanMap, setFloorPlanMap] = React.useState<MapContent>()

  const [showLabels, setShowLabels] = React.useState(false)

  const STATUS_SOLD = 'sold'
  const STATUS_AVAILABLE = 'available'

  const [refreshTimeOutId, setRefreshTimeoutId] =
    React.useState<NodeJS.Timeout>()

  const getFloorplanLength = () => Object.keys(floorplan).length

  const checkUnit = (unit: Unit) => {
    const { apply } = unitFilter

    if (unit.metas.status === STATUS_SOLD) {
      return true
    }

    if (!apply) {
      return false
    }

    return filterUnitUtil(unit, unitFilter, showPrice)
  }

  const setUnitColor = (status: string, color: string) => {
    if (status.toLowerCase() === STATUS_AVAILABLE) {
      return color || '#0abec4'
    }
    return '#8F8E8E'
  }

  const handleUnitData = (poly: Polygon) => {
    const unit = units.find(
      (res) => res.name.replace(/\s/g, '') === poly?.groupId?.replace(/\s/g, '')
    )
    return {
      disabled: !unit?.metas,
      postFix: unit?.metas.status,
      color: setUnitColor(unit?.metas.status || '', poly?.color),
      markerColour:
        unit?.metas.status === STATUS_SOLD
          ? markerColour.sold
          : markerColour.available,
      activeByDefault: unit && checkUnit(unit),
    }
  }

  const mapFloorplanActions = () => {
    const mapName = activeBlock ? `${activeBlock}-${activeLevel}` : activeLevel
    const currentMap: MapContent = floorplan[mapName]
    if (!currentMap) {
      return
    }
    setFloorPlanMap({
      ...currentMap,
      image: currentMap.image,
      polygons: currentMap.polygons
        .map((poly) => ({
          ...poly,
          ...handleUnitData(poly),
          onClick: () => {
            if (poly.groupId) {
              dispatch(setByFlag({ flag: 'activeUnit', value: poly.groupId }))
            }
          },
        }))
        .filter((res) =>
          units.find(
            (resInner) =>
              resInner.name.replace(/\s/g, '') ===
              res?.groupId?.replace(/\s/g, '')
          )
        ),
    })
  }

  React.useEffect(() => {
    if (getFloorplanLength() && canvasContainerRef && units.length) {
      mapFloorplanActions()
      setRenderCanvas(true)
    }
  }, [activeLevel, unitFilter, activeBlock])

  const handleCanvasItems = () => {
    canvasRef?.current?.setPolyActive()
    if (showLabels) {
      canvasRef?.current?.toggleLabels()
    }
  }

  React.useEffect(() => {
    if (
      activeLevel !== prevReference.current.activeLevel ||
      activeBlock !== prevActiveBlockReference.current.activeBlock
    ) {
      canvasRef?.current?.setCanvas()
      prevReference.current = {
        ...prevReference.current,
        activeLevel,
      }
      prevActiveBlockReference.current = {
        ...prevActiveBlockReference.current,
        activeBlock,
      }
    }
    handleCanvasItems()
  }, [floorplanMap])

  React.useEffect(() => {
    canvasRef?.current?.toggleLabels()
  }, [showLabels])

  React.useEffect(() => {
    if (isFullWidth !== isFullWidthProp) {
      if (refreshTimeOutId) {
        clearTimeout(refreshTimeOutId)
      }
      const id = setTimeout(() => {
        canvasRef?.current?.setCanvas()
      }, 1000)
      setRefreshTimeoutId(id)
      setIsFullWidth(isFullWidthProp)
    }
  }, [isFullWidthProp])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: { unitsShowLabels, activeUnit },
      } = session
      setIsConnected(connected)
      canvasRef?.current?.artificialTrigger(activeUnit)
      setShowLabels(unitsShowLabels)
    }
  }, [session])

  return (
    <div
      className="relative flex h-full w-full justify-center bg-white"
      ref={canvasContainerRef}
    >
      <div className="absolute right-0 top-0">
        {floorplanMap && renderCanvas && (
          <CanvasInteractive
            ref={canvasRef}
            id="floorplan-canvas"
            canvasData={floorplanMap}
            parentRef={canvasContainerRef}
            hasLabel={!isConnected}
            labelPrefix={`${unitPrefix || 'Unit'}:`}
            showLabels={showLabels}
            ratio={ratio}
          />
        )}
      </div>
    </div>
  )
}

const findUnit = (
  level: Array<Level>,
  activeLevel: string,
  activeBlock: string
) =>
  level
    .find((lvl: Level) => lvl.level === activeLevel)
    ?.data?.filter((res) => {
      if (activeBlock) {
        return res.blockId === activeBlock
      }
      return true
    }) || []

export default connect(
  ({
    unitFilter,
    building: { activeLevel, activeBlock, levels },
    interactivePlan: { floorplan },
    projectConfig: { markerColour, showPrice, unitPrefix },
  }: RootStateFirebase) => ({
    unitFilter,
    activeLevel,
    activeBlock,
    floorplan,
    units: findUnit(levels, activeLevel, activeBlock),
    markerColour,
    showPrice,
    unitPrefix,
  })
)(FloorplanCanvas)
