import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setBuildingPageConfigByKey } from '@store/actionSlices/buildingPageConfig'
import { setPanoramicData } from '@store/actionSlices/panoramic'
import { setUnitGallery } from '@store/actionSlices/unitGallery'
import {
  RootStateFirebase,
  SessionMap,
  UnitGallery,
  UnitGalleryData,
} from '@store/types'

import DataHandler from '@components/data-handler'
import { GalleryProvider } from '@components/gallery-handler'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import { Unit, ViewLineImage } from '@api/building'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'
import {
  selectFromResult as selectFromUnitGalleryResult,
  useGetUnitGalleryQuery,
} from '@api/unit-gallery'

import getSession from '@utilities/firebase-util'

import UnitCarouselContent from './unit-carousel-content'

export interface UnitViewProps {
  unit: Unit
  projectName: string
  projectId: string
  session: SessionMap | undefined
  panoramic: Array<PannellumDataInterface>
  unitGalleryControlStyle: string
  onlyShowUnitGalleries: boolean
  unitGallery: Array<UnitGalleryData>
}

const UnitView = ({
  unit,
  projectName,
  projectId,
  session,
  panoramic,
  unitGalleryControlStyle,
  onlyShowUnitGalleries,
  unitGallery,
}: UnitViewProps) => {
  const dispatch = useDispatch()

  const [skipPanoramicApi, setSkipPanoramicApi] = React.useState(true)
  const [isConnected, setIsConnected] = React.useState(false)
  const [gallery, setGallery] = React.useState<Array<UnitGallery>>([])
  const [skipUnitGalleryApi, setSkipUnitGalleryApi] = React.useState(true)

  const panoramicPayload = useGetPanoramicQuery(
    { projectName },
    { selectFromResult: selectFromPanoramicResult, skip: skipPanoramicApi }
  )

  const unitGalleryPayload = useGetUnitGalleryQuery(
    { projectName, unitId: unit.id },
    { selectFromResult: selectFromUnitGalleryResult, skip: skipUnitGalleryApi }
  )

  const unitGalleryExistsInTheStore = (unitId: string): boolean =>
    unitGallery.find(
      (galleryItem: UnitGalleryData) => galleryItem.unit === unitId
    ) !== undefined

  React.useEffect(() => {
    if (panoramic.length === 0 && skipPanoramicApi) {
      setSkipPanoramicApi(false)
    }
  }, [panoramic])

  React.useEffect(() => {
    const { panoramic: panoramicData, isLoaded } = panoramicPayload
    if (panoramic.length === 0 && isLoaded && panoramicData.length > 0) {
      dispatch(setPanoramicData(panoramicData))
    }
  }, [panoramicPayload])

  React.useEffect(() => {
    if (!unitGalleryExistsInTheStore(unit.id) && skipUnitGalleryApi) {
      setSkipUnitGalleryApi(false)
    }
  }, [unitGallery])

  React.useEffect(() => {
    const { unitGalleryData, isLoaded, isError } = unitGalleryPayload
    const unitId = unit.id
    if (
      isLoaded &&
      unitGalleryData.length > 0 &&
      !unitGalleryExistsInTheStore(unitId) &&
      !isError
    ) {
      dispatch(setUnitGallery({ unit: unitId, items: unitGalleryData }))
    }
  }, [unitGalleryPayload])

  const buildUnitGallery = () => {
    const galleryData: Array<UnitGallery> = []
    if (unit !== undefined) {
      const {
        id: unitId,
        metas: { floorImage, floorLabel, viewLineImages },
      } = unit

      if (
        !onlyShowUnitGalleries &&
        viewLineImages &&
        viewLineImages.length > 0
      ) {
        viewLineImages.forEach((item: ViewLineImage) => {
          galleryData.push({
            ...item,
            src: `/${projectId}/views/${item.src}`,
            type: 'image',
            noSpliceUrl: true,
          })
        })
      }

      const foundUnitGallerys = unitGallery.filter(
        (galleryItem: UnitGalleryData) => galleryItem.unit === unitId
      )
      if (foundUnitGallerys.length > 0) {
        const [foundUnitGallery] = foundUnitGallerys
        foundUnitGallery.items.forEach((galleryItem: UnitGallery) => {
          const gradiant = galleryItem.type === 'video' ? 0.5 : 0
          galleryData.push({ ...galleryItem, gradiant, noSpliceUrl: false })
        })
      }

      if (!onlyShowUnitGalleries && floorImage) {
        galleryData.unshift({
          type: 'image',
          src: `/${projectId}/floorplans/preview/${floorImage}`,
          label: floorLabel || floorImage,
          noSpliceUrl: true,
        })
      }
    } else {
      dispatch(
        setBuildingPageConfigByKey({ key: 'unitInfoPopupOpen', value: false })
      )
    }
    setGallery(galleryData)
  }

  React.useEffect(() => {
    buildUnitGallery()
  }, [unit, unitGallery])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  return (
    <DataHandler
      payload={{
        ...unitGalleryPayload,
        data: gallery,
      }}
    >
      <div className="relative flex h-full w-full">
        <GalleryProvider
          galleryName="building"
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          dragEnabled={false}
          className="building w-full"
          originalSlides={gallery}
          galleryControl={session?.unitGallery?.galleryControl}
        >
          {(slideContent) => (
            <UnitCarouselContent
              unit={unit}
              gallery={slideContent}
              session={session}
              isConnected={isConnected}
              unitGalleryControlStyle={unitGalleryControlStyle}
              galleryControl={session?.unitGallery?.galleryControl}
            />
          )}
        </GalleryProvider>
      </div>
    </DataHandler>
  )
}

export default connect(
  ({
    projectIdentity: { projectName, projectId },
    firestore,
    panoramic,
    projectConfig: { unitGalleryControlStyle, onlyShowUnitGalleries },
    unitGallery,
  }: RootStateFirebase) => ({
    projectName,
    projectId,
    session: getSession(firestore),
    panoramic,
    unitGalleryControlStyle,
    onlyShowUnitGalleries,
    unitGallery,
  })
)(UnitView)
