import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { BuildingInterface, setByFlag } from '@store/actionSlices/building'
import { setBuildingPageConfigByKey } from '@store/actionSlices/buildingPageConfig'
import { clearShortlist, setShortlistAll } from '@store/actionSlices/shortlist'
import { RootStateFirebase, UnitFilterInterface } from '@store/types'

import DropDown from '@components/dropdown'
import ConfirmationModal from '@components/modals'
import {
  ArrowTurnLeftSvg,
  BuildingSvg,
  CloseCircleSvg,
  MenuToggleSvg,
} from '@components/svg'

import { Level, Unit } from '@api/building'
import {
  EnvisionVRConfigurationInterface,
  SnaploaderConfigurationInterface,
} from '@api/config'
import { MappingBlockCollection } from '@api/interactive-plan'
import { Shortlist } from '@api/shortlisted'

import { hasEnvisionVR, hasSnaploader } from '@utilities/helper'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import LevelDropdown from './level-dropdown'
import UnitCard from './unit-card'

export interface UnitsSidePanelProps {
  building: BuildingInterface
  togglePopup: (arg: boolean) => void
  isUnitPanelFolded: boolean
  isPopUpOpen: boolean
  shortlist: Array<Shortlist>
  unitFilter: UnitFilterInterface
  isConnected?: boolean
  hasThreeDViewer?: boolean
  snaploaderConfiguration: SnaploaderConfigurationInterface
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  showPrice: boolean
  sidePanelTitle: string
  hideFilter: boolean
  blocks: MappingBlockCollection
}

const UnitsSidePanel = ({
  shortlist,
  building,
  isPopUpOpen,
  isUnitPanelFolded,
  togglePopup,
  unitFilter,
  isConnected = false,
  hasThreeDViewer,
  snaploaderConfiguration,
  envisionVRConfiguration,
  showPrice,
  sidePanelTitle,
  hideFilter,
  blocks,
}: UnitsSidePanelProps) => {
  const AVAILABLE_STATUS = 'available'
  const MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST) || 4

  const history = useHistory()
  const dispatch = useDispatch()
  const blockList: Array<string> = Object.keys(blocks) || []
  const [activeLevel, setActiveLevel] = React.useState<Level>({
    level: '',
    data: [],
  })
  const [filteredUnits, setFilteredUnits] = React.useState<Array<Unit>>([])
  const [isClickedShortListAllUnit, setIsClickedShortListAllUnit] =
    React.useState<boolean>(false)
  const [showConfirmationModal, toggleConfirmationModal] = React.useState(false)
  const [confirmationModalMessage, setConfirmationModalMessage] =
    React.useState('')
  const [availableUnitsCount, setAvailableUnitsCount] = React.useState(0)

  const getActiveLevel = () => {
    const [foundLevel] = building.levels.filter(
      (res) => res.level === building.activeLevel
    )
    if (foundLevel) {
      const { level, data } = foundLevel
      setActiveLevel({
        level,
        data: building.activeBlock
          ? data.filter((res) => res.blockId === building.activeBlock)
          : data,
      })
    }
  }

  const checkIfShortListed = (unit: Unit) =>
    shortlist.filter(
      (res: Shortlist) =>
        unit.name === res.name && res.level === activeLevel?.level
    ).length

  const shortlistUnitAll = () => {
    let availableFilteredUnits = filteredUnits.filter(
      (unit: Unit) => unit.metas.status === AVAILABLE_STATUS
    )

    availableFilteredUnits = availableFilteredUnits.filter(
      (unit: Unit) => !checkIfShortListed(unit)
    )
    const shortlistedUnits = availableFilteredUnits
      .slice(0, MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST - shortlist.length)
      .map((unit) => {
        const shortList: Shortlist = { level: activeLevel.level, ...unit }
        return shortList
      })
    dispatch(setShortlistAll(shortlistedUnits))
  }

  const clearShortlistedUnits = () => {
    dispatch(clearShortlist(activeLevel))
  }

  const handleChangeOnShortListedUnit = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked

    if (!isChecked) {
      clearShortlistedUnits()
    } else {
      shortlistUnitAll()
    }
  }

  const handleBackNav = () => {
    if (building.activeUnit) {
      dispatch(setByFlag({ flag: 'activeUnit', value: '' }))
      return
    }
    if (building.activeLevel) {
      dispatch(
        setByFlag({ flag: 'activeLevel', value: building.levels[0].level })
      )
      if (hasSnaploader(snaploaderConfiguration)) {
        history.push('snaploader-view')
        return
      }
      if (hasEnvisionVR(envisionVRConfiguration)) {
        history.push('envision-vr')
        return
      }
      if (hasThreeDViewer) {
        history.push('areaview-3d')
        return
      }
      history.push('level-view')
    }
  }

  const filterUnit = () =>
    activeLevel?.data?.filter((unit: Unit) =>
      filterUnitUtil(unit, unitFilter, showPrice)
    )

  const getClassNameBasedOnTheConnectAndUnitPanelFoldedState = () => {
    let className =
      'flex flex-col items-start justify-between rounded-tr-5xl shadow-2xl transition-size-spacing ease-in-out h-full bg-neutral-grey absolute bottom-0 w-full '
    if (isConnected) {
      className += 'h-screen'
    } else if (!isUnitPanelFolded) {
      className += 'h-screen-92vh sm-h:h-screen-91vh px-4 md:px-9 '
    } else {
      className += 'h-screen px-0'
    }
    return className
  }

  const setShortlistErrorMessage = (message: string) => {
    setConfirmationModalMessage(message)
    toggleConfirmationModal(true)
  }

  const getShortlistedUnitsByLevel = (myLevel: string) =>
    shortlist.filter(
      (shortlistItem: Shortlist) => shortlistItem.level === myLevel
    )

  const getLevelListByBlockId = (blockId: string): Array<string> => {
    const levelList: Level[] = [...building.levels]
    if (unitFilter.showAvailable) {
      levelList.filter((lvl: Level) =>
        lvl.data.filter((unit: Unit) => unit.metas.status === AVAILABLE_STATUS)
      )
    }
    const levels: Array<string> = []
    levelList.forEach((res: Level) => {
      if (res.data.find((unit: Unit) => unit.blockId === blockId)) {
        levels.push(res.level)
      }
    })
    return levels
  }

  const handleBlockChange = (value: string) => {
    const [firstLevel] = getLevelListByBlockId(value)
    dispatch(setByFlag({ flag: 'activeUnit', value: '' }))
    dispatch(setByFlag({ flag: 'activeBlock', value }))
    dispatch(setByFlag({ flag: 'activeLevel', value: firstLevel }))
  }

  React.useEffect(() => {
    getActiveLevel()
  }, [building, unitFilter])

  React.useEffect(() => {
    setFilteredUnits(filterUnit())
  }, [activeLevel, unitFilter])

  React.useEffect(() => {
    const { level, data: units } = activeLevel

    const availableUnits = units.filter(
      (unit: Unit) => unit.metas.status === AVAILABLE_STATUS
    )

    const shortListUnits = getShortlistedUnitsByLevel(level)

    setAvailableUnitsCount(availableUnits.length)

    if (availableUnits.length > 0) {
      setIsClickedShortListAllUnit(
        shortListUnits.length ===
          (availableUnits.length > MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST
            ? MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST
            : availableUnits.length)
      )
    } else {
      setIsClickedShortListAllUnit(false)
    }
  }, [activeLevel])

  React.useEffect(() => {
    const { level } = activeLevel

    const shortListUnits = getShortlistedUnitsByLevel(level)

    if (availableUnitsCount > 0) {
      setIsClickedShortListAllUnit(
        shortListUnits.length ===
          (availableUnitsCount > MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST
            ? MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST
            : availableUnitsCount)
      )
    }
  }, [shortlist])

  return (
    <div
      className={`relative z-20 flex h-full max-w-md flex-col items-start transition-all duration-300 ${
        !isUnitPanelFolded ? 'w-2/3 sm:w-2/3 md:w-90 lg:w-90 xl:w-90' : 'w-0'
      }`}
    >
      <div className={getClassNameBasedOnTheConnectAndUnitPanelFoldedState()}>
        <div className="relative mt-8 flex w-full flex-row items-center">
          <span
            className={`w-full text-2xb ${isUnitPanelFolded ? 'hidden' : ''}`}
          >
            {sidePanelTitle || 'Unit'}
          </span>
          <span className="flex w-full items-center justify-end">
            {!isConnected && (
              <>
                <button
                  type="button"
                  className="h-9 text-current outline-none"
                  onClick={() => handleBackNav()}
                >
                  <ArrowTurnLeftSvg size="m" />
                </button>
              </>
            )}

            <button
              onClick={() =>
                dispatch(
                  setBuildingPageConfigByKey({
                    key: 'unitPanelFolded',
                    value: true,
                  })
                )
              }
              type="button"
              className="ml-1 flex items-center outline-none"
            >
              <CloseCircleSvg
                className="cursor-pointer"
                width="34"
                height="34"
                fill="hsl(1, 0%, 0%)"
              />
            </button>
          </span>
        </div>
        <div className="relative mt-4 h-full w-full bg-neutral-grey transition-all duration-700">
          <div className="flex h-5/6 flex-col items-start overflow-hidden">
            {building.activeBlock &&
              (blockList.length > 1 ? (
                <DropDown
                  id="blockDropdown"
                  onSelect={handleBlockChange}
                  items={blockList}
                  value={building.activeBlock}
                  icon={<BuildingSvg className="h-8 w-8" />}
                  className="bg-white"
                />
              ) : (
                <div className="flex w-full flex-col items-start">
                  <div className="justify-left flex items-center">
                    <div className="-ml-1 mr-2">
                      <BuildingSvg
                        className="h-10 w-10 text-black"
                        stroke={2}
                      />
                    </div>
                    <div className="text-2xl text-black">
                      {building.activeBlock ?? '--'}
                    </div>
                  </div>
                </div>
              ))}

            <div className="flex w-full flex-col">
              <div className="mt-6 flex flex-col items-center justify-between md:flex-row">
                <LevelDropdown sessionConnected={isConnected} />
                {!isConnected && !hideFilter && (
                  <button
                    onClick={() => togglePopup(!isPopUpOpen)}
                    type="button"
                    className="mt-4 h-9 flex-none pl-1 text-white outline-none md:mt-0"
                  >
                    <MenuToggleSvg fill="black" />
                  </button>
                )}
              </div>
            </div>
            <hr className="my-4 h-px w-full border-0 bg-gray-200 shadow-md dark:bg-gray-700"></hr>
            {!isConnected && (
              <div className="mb-4 flex w-full justify-end pr-1">
                <label
                  htmlFor="shortListedUnit"
                  className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  <span className="pr-4 text-black">
                    Select All (max. {MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST})
                  </span>
                  <input
                    id="shortListedUnit"
                    type="checkbox"
                    name="shortListedUnit"
                    checked={isClickedShortListAllUnit}
                    onChange={handleChangeOnShortListedUnit}
                    className={`h-4 w-4 rounded border-gray-300 dark:border-gray-600 ${
                      availableUnitsCount < 1 ? 'bg-gray-300' : ''
                    }`}
                    disabled={availableUnitsCount < 1}
                  />
                </label>
              </div>
            )}
            <div className="no-scrollbar flex h-full w-full flex-col items-start overflow-y-scroll">
              {filteredUnits.length > 0 ? (
                filteredUnits.map((unit: Unit) => (
                  <UnitCard
                    key={unit.id}
                    unit={unit}
                    activeLevel={activeLevel}
                    sessionConnected={isConnected}
                    setShortlistErrorMessage={setShortlistErrorMessage}
                  />
                ))
              ) : (
                <div className="flex h-18 w-full items-center rounded-lg bg-white p-2.5 shadow-lg">
                  No Units to Display
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isVisible={showConfirmationModal}
        message={confirmationModalMessage}
        toggleModal={toggleConfirmationModal}
        okButtonText="Ok"
        showCancelButton={false}
      />
    </div>
  )
}

export default connect(
  ({
    building,
    shortlist,
    unitFilter,
    projectConfig: {
      threeDViewerUrl,
      showPrice,
      sidePanelTitle,
      hideFilter,
      snaploaderConfiguration,
      envisionVRConfiguration,
    },
    interactivePlan: { blocks },
  }: RootStateFirebase) => ({
    building,
    shortlist,
    unitFilter,
    hasThreeDViewer: threeDViewerUrl !== '',
    snaploaderConfiguration,
    envisionVRConfiguration,
    showPrice,
    sidePanelTitle,
    hideFilter,
    blocks,
  })
)(UnitsSidePanel)
