import React, { RefObject } from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import ReactPlayer from 'react-player'

interface ScrubBarProps {
  playerReference: RefObject<ReactPlayer>
  videoLengthInMilliseconds: number
  isPlaying: boolean
  isBuffing: boolean
}

const ScrubBar = ({
  playerReference,
  videoLengthInMilliseconds,
  isPlaying,
  isBuffing,
}: ScrubBarProps) => {
  const [sliderRangeValue, setSliderRangeValue] = React.useState(0)
  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const toTimeString = (milliseconds: number): string =>
    new Date(milliseconds).toISOString().slice(11, 19)

  const setSliderRangeInterval = () => {
    if (intervalId) return

    setIntervalId(
      setInterval(() => {
        setSliderRangeValue((previousValue) => {
          const newValue = previousValue + 250
          return newValue > videoLengthInMilliseconds ? 0 : newValue
        })
      }, 250)
    )
  }

  const resetSliderRangeInterval = () => {
    if (intervalId) clearInterval(intervalId)
    setIntervalId(undefined)
  }

  const handleOnChangeComplete = (valueInMilliseconds: number) => {
    playerReference.current?.seekTo(valueInMilliseconds / 1000)
    if (isPlaying && !isBuffing) setSliderRangeInterval()
  }

  const handleOnChange = (valueInMilliseconds: number) => {
    resetSliderRangeInterval()
    setSliderRangeValue(valueInMilliseconds)
  }

  React.useEffect(() => {
    if (isPlaying && !isBuffing) {
      setSliderRangeInterval()
    } else {
      resetSliderRangeInterval()
    }
  }, [isPlaying, isBuffing])

  React.useEffect(
    () => () => {
      resetSliderRangeInterval()
    },
    []
  )

  return (
    <div className="flex items-center">
      <span
        className={`mr-4 text-sm drop-shadow-70 ${
          videoLengthInMilliseconds > 0 ? 'text-white' : 'text-gray-500'
        }`}
      >
        {videoLengthInMilliseconds > 0
          ? toTimeString(sliderRangeValue)
          : '00:00:00'}
      </span>
      <InputRange
        minValue={0}
        maxValue={videoLengthInMilliseconds}
        value={sliderRangeValue}
        onChange={(value) => handleOnChange(Number(value))}
        onChangeComplete={(value) => handleOnChangeComplete(Number(value))}
        formatLabel={() =>
          videoLengthInMilliseconds > 0
            ? toTimeString(sliderRangeValue)
            : '00:00:00'
        }
      />
      <span
        className={`ml-4 text-sm drop-shadow-70 ${
          videoLengthInMilliseconds > 0 ? 'text-white' : 'text-gray-500'
        }`}
      >
        {videoLengthInMilliseconds > 0
          ? toTimeString(videoLengthInMilliseconds)
          : '00:00:00'}
      </span>
    </div>
  )
}

export default ScrubBar
