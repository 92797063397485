import { useFirestore } from 'react-redux-firebase'

import type { Channel, ProjectIdentity, SessionMap } from '@store/types'

export interface FirebaseControlQueryProps {
  projectIdentity: ProjectIdentity
  map?: SessionMap | undefined
}

const FirebaseControlQuery = ({
  projectIdentity,
  map,
}: FirebaseControlQueryProps): any => {
  const firestore = useFirestore()

  const doesProjectExist = async (projectName: string) => {
    const { exists } = await firestore
      .collection('devsuite')
      .doc(projectName)
      .get()
    return exists
  }

  const doesSesionExist = async ({
    projectName,
    sessionId,
  }: {
    projectName: string
    sessionId: string
  }) => {
    const { exists } = await firestore
      .collection('devsuite')
      .doc(projectName)
      .collection('session')
      .doc(sessionId)
      .get()
    return exists
  }

  const createSession = async ({
    projectName,
    sessionId,
  }: {
    projectName: string
    sessionId: string
  }) => {
    const exist = await doesSesionExist({ projectName, sessionId })
    if (!exist) {
      await firestore
        .collection('devsuite')
        .doc(projectName)
        .collection('session')
        .doc(sessionId)
        .set(map || {})
      return true
    }
    return false
  }

  const updateConnection = async (connected: boolean, sessionId?: string) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(
        (sessionId || projectIdentity.sessionId)
          .toUpperCase()
          .replace(/\s/g, '')
      )
      .update({
        connected,
      })
    return response
  }

  const refreshSession = async () => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update({ ...(map || {}), connected: true })
    return response
  }

  const deleteSession = async () => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .delete()
    return response
  }

  const update = async (value: { [key: string]: any }) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update(value)
    return response
  }

  const updateLightChannel = async (value: { [key: string]: any }) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('light-map')
      .doc(projectIdentity.lightMap)
      .update(value)
    return response
  }

  const updateLightMap = async (channels: Array<Channel>) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('light-map')
      .doc(projectIdentity.lightMap)
      .update({ channels })
    return response
  }

  return {
    updateConnection,
    doesProjectExist,
    refreshSession,
    deleteSession,
    createSession,
    updateLightMap,
    update,
    updateLightChannel,
  }
}

export default FirebaseControlQuery
