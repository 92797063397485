import React from 'react'
import { useHistory } from 'react-router-dom'

import { ChevronSvg, CloseCircleSvg } from '@components/svg'

import { ExternalViewsData } from '@api/external-views'

export interface ExternalLinksInterface {
  externalViews: Array<ExternalViewsData>
  selectedView: number
  trigger: (index: number) => void
}

const ExternalLinks = ({
  externalViews,
  selectedView,
  trigger,
}: ExternalLinksInterface): React.ReactElement => {
  const history = useHistory()

  const [slidePanelOpen, setSlidePanelOpen] = React.useState(false)

  return (
    <div
      className="flex h-screen flex-row items-center justify-between opacity-0 transition-opacity duration-500 hover:opacity-100"
      onMouseLeave={() => setSlidePanelOpen(false)}
    >
      <div
        className={`transition-opacity duration-500 ${
          slidePanelOpen ? 'w-72 opacity-100' : 'w-0 opacity-0'
        }`}
      >
        <div
          className={`no-scrollbar mr-10 flex h-416px flex-col items-end justify-center overflow-y-auto text-right ${
            slidePanelOpen ? 'w-72' : 'w-0'
          }`}
        >
          {externalViews.map((item: ExternalViewsData, index: number) => (
            <div key={item.title} className="w-full">
              <button
                className={`my-2 pr-4 text-white ${
                  selectedView === index && 'opacity-50'
                }`}
                onClick={() => trigger(index)}
                type="button"
                tabIndex={index}
                disabled={!slidePanelOpen}
              >
                <div className="transform text-right transition duration-300 ease-in-out hover:scale-110">
                  <span
                    className={`drop-shadow-40 ${
                      index === selectedView ? 'text-3xl' : ' text-xl'
                    }`}
                  >
                    {item.title}
                  </span>
                </div>
              </button>
            </div>
          ))}
          <div className="w-full">
            <button
              onClick={() => history.push('vision')}
              type="button"
              className="p-5 text-white"
              disabled={!slidePanelOpen}
            >
              <CloseCircleSvg
                size="m"
                fill="white"
                className="drop-shadow-40"
              />
            </button>
          </div>
        </div>
      </div>
      <button
        className="slide-panel relative cursor-pointer py-10 pl-5 text-right opacity-0 transition-all duration-500 hover:opacity-100"
        type="button"
        onClick={() => setSlidePanelOpen(!slidePanelOpen)}
      >
        <div
          className={`transform transition-all duration-500 ${
            slidePanelOpen ? '-rotate-90' : 'rotate-90'
          }`}
        >
          <ChevronSvg className="animate-bounce cursor-pointer text-white drop-shadow-40" />
        </div>
      </button>
    </div>
  )
}

export default ExternalLinks
