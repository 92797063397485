import React from 'react'
import { useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/project'

import { CloseCircleSvg } from '@components/svg'

export interface ModalInterface {
  toggleModal: (arg: boolean) => void
  isVisible: boolean
  lightMap: string
  hasLightMap: boolean
}

const LightsModal = ({
  isVisible,
  toggleModal,
  lightMap,
  hasLightMap,
}: ModalInterface) => {
  const dispatch = useDispatch()

  const [lightModelCode, setLightModelCode] = React.useState('')
  const [lightModelCodeError, setLightModelCodeError] = React.useState('')

  const closeModal = () => {
    setLightModelCodeError('')
    toggleModal(false)
  }

  const connectLight = () => {
    setLightModelCodeError('')
    if (!lightModelCode) {
      setLightModelCodeError('Light model code field is required.')
      return
    }

    dispatch(setProjectIdentity({ lightMap: lightModelCode }))
  }

  const disconnectLight = () => {
    setLightModelCodeError('')
    dispatch(setProjectIdentity({ lightMap: '' }))
  }

  React.useEffect(() => {
    setLightModelCode(lightMap)
  }, [lightMap])

  return (
    <div
      id="light-setting-modal"
      aria-hidden="true"
      className={`h-modal fixed left-0 right-0 top-4 z-50 transform items-center justify-center overflow-y-auto overflow-x-hidden duration-500
      ease-in-out md:inset-0 md:h-full ${
        isVisible ? 'translate-y-0' : '-translate-y-full'
      }
      `}
    >
      <div className="m-auto mt-32 h-full w-full max-w-2xl px-4 md:h-auto">
        <div className="rounded-lg bg-white shadow dark:bg-gray-700">
          <div className="flex items-center justify-between rounded-t border-b p-5 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
              Lights Settings
            </h3>
            <button
              type="button"
              onClick={() => closeModal()}
              className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="light-setting-modal"
            >
              <CloseCircleSvg fill="#000000" />
            </button>
          </div>
          <div className="space-y-6 p-6">
            <div>
              <label htmlFor="room">Light Model Code</label>
              <div className="flex rounded-md">
                <input
                  type="text"
                  name="room"
                  value={lightModelCode}
                  className={`w-full rounded-l-md border-gray-200 focus:border-gray-200 focus:ring-0 ${
                    lightMap && 'bg-gray-200'
                  }`}
                  onChange={(event) => setLightModelCode(event.target.value)}
                  placeholder="Enter your light model code"
                  maxLength={20}
                  disabled={lightMap.length > 0}
                />
                <div className="flex items-center rounded-r-md border border-gray-200 px-4 py-2">
                  <span className="relative flex h-3 w-3">
                    <span
                      className={`absolute inline-flex h-full w-full rounded-full bg-lime-400 opacity-75 ${
                        lightMap && hasLightMap && 'animate-ping'
                      }`}
                    ></span>
                    <span
                      className={`relative inline-flex h-3 w-3 rounded-full ${
                        lightMap && hasLightMap ? 'bg-lime-500' : 'bg-gray-400'
                      }`}
                    ></span>
                  </span>
                </div>
              </div>
              <div>
                {lightModelCodeError && (
                  <small className="text-red-500">{lightModelCodeError}</small>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end border-t border-gray-200 p-6">
            {lightMap ? (
              <button
                type="button"
                onClick={() => disconnectLight()}
                className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-0 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Disonnect Light
              </button>
            ) : (
              <button
                type="button"
                onClick={() => connectLight()}
                className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-0 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Connect Light
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LightsModal
