import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ReleaseVersionInterface, RootStateTypeExtra } from '@store/types'

const defaultReleaseInfo = {
  version: '',
}

let initialState = defaultReleaseInfo as ReleaseVersionInterface

const releaseInfo = localStorage.getItem('releaseInfo')

if (releaseInfo) {
  initialState = JSON.parse(releaseInfo)
}

const releaseInfoSlice = createSlice({
  name: 'releaseInfo',
  initialState,
  reducers: {
    setReleaseVersion: (
      state: ReleaseVersionInterface,
      action: PayloadAction<string>
    ) => {
      localStorage.setItem(
        'releaseInfo',
        JSON.stringify({ ...state, version: action.payload })
      )
      return { ...state, version: action.payload }
    },
    removeReleaseInfo: () => {
      localStorage.removeItem('releaseInfo')
      return defaultReleaseInfo
    },
  },
})

export const { setReleaseVersion, removeReleaseInfo } = releaseInfoSlice.actions

export const selectBuilding = (state: RootStateTypeExtra) => state.releaseInfo

export default releaseInfoSlice.reducer
