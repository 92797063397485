import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface VideoGalleryData {
  title: string
  fileName: string
  src: string
  previewImageSrc: string
}

export interface VideoGalleryPayload extends PayloadInterface {
  data: Array<VideoGalleryData>
}

type VideoGalleryType = {
  projectName: string
}

export const videoGalleryApi = createApi({
  reducerPath: 'videoGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getVideoGallery: builder.query<VideoGalleryData, VideoGalleryType>({
      query: (params: VideoGalleryType) => ({
        url: `/v1/${params.projectName}/videos${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  videoGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetVideoGalleryQuery } = videoGalleryApi
