import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { InteractivePlanData } from '@api/interactive-plan'

import isOffline from '@utilities/offline-mode-util'

let initialState = {
  areaView: {
    image: '',
    polygons: [],
  },
  blocks: {},
  floorplan: {},
} as InteractivePlanData

if (isOffline()) {
  const interactivePlanData = localStorage.getItem('interactivePlanApiData')
  if (interactivePlanData) {
    initialState = JSON.parse(interactivePlanData)
  }
}

const interactivePlanSlice = createSlice({
  name: 'interactivePlan',
  initialState,
  reducers: {
    setInteractivePlan: (
      state: InteractivePlanData,
      action: PayloadAction<InteractivePlanData>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'interactivePlanApiData',
          JSON.stringify({
            ...state,
            ...action.payload,
          })
        )
      }
      return {
        ...state,
        ...action.payload,
      }
    },
    removeInteractivePlan: () => {
      localStorage.removeItem('interactivePlanApiData')
      return {
        areaView: {
          image: '',
          polygons: [],
        },
        blocks: {},
        floorplan: {},
      }
    },
  },
})

export const { setInteractivePlan, removeInteractivePlan } =
  interactivePlanSlice.actions

export const selectInteractivePlan = (state: RootStateTypeExtra) =>
  state.interactivePlan

export default interactivePlanSlice.reducer
