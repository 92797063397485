import React from 'react'

import { OfflineSvg } from '@components/svg'

const ConnectionRefused = () => (
  <div className="background-cover bg-mainColour flex items-center justify-center">
    <div className="inline-flex items-center">
      <OfflineSvg className="h-20 w-20" />
      <h1 className="text-5xl sm:text-4xl">Showcase refused to connect.</h1>
    </div>
  </div>
)

export default ConnectionRefused
