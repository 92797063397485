import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
} from 'pure-react-carousel'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import type { RootStateFirebase, SessionMap } from '@store/types'

import {
  GallerySlide,
  GallerySlider,
  SlideTrigger,
} from '@components/gallery-handler'
import Player from '@components/player'

import { GalleryData } from '@api/vision'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import getSession from '@utilities/firebase-util'
import handleMouseWheel from '@utilities/gallery-image-control-util'

export interface CarouselContentProps {
  session: SessionMap | undefined
  slideContent: Array<GalleryData>
  getCtrlVisibility: (arg: React.SetStateAction<boolean>) => void
  ctrlsHidden: boolean
  transitionType?: string
}

const CarouselContent = ({
  session,
  slideContent,
  ctrlsHidden,
  getCtrlVisibility,
  transitionType,
}: CarouselContentProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const [slideCount, setSlide] = useState(0)

  useEffect(() => {
    if (session) {
      const {
        vision: {
          galleryControl: { activeItemIndex: activeItemIndexFirebase },
        },
      } = session
      setSlide(activeItemIndexFirebase)
    }
  }, [session])

  const getGradiantValue = (galleryData: GalleryData) => {
    let gradientValue = 0

    const hasTitleCard = galleryData.config.isTitleCard ?? false

    if (!hasTitleCard) {
      gradientValue = galleryData.videoSource ? 0.5 : 0
    }

    return gradientValue
  }

  return (
    <div
      onWheel={(e) => handleMouseWheel(e, 'nextButton', 'prevButton')}
      className="relative"
    >
      <div
        className={`absolute left-7 z-10 flex h-screen items-center transition-opacity duration-300 ease-in-out ${
          ctrlsHidden ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <ButtonNext id="nextButton" className="hidden">
          Next button
        </ButtonNext>
        <ButtonBack id="prevButton" className="hidden">
          Prev button
        </ButtonBack>
        <ButtonFirst id="firstButton" className="hidden">
          First button
        </ButtonFirst>
        <ButtonLast id="lastButton" className="hidden">
          Last button
        </ButtonLast>
        {slideContent.length > 1 && (
          <SlideTrigger
            type="dot"
            items={slideContent.map((res) => ({ text: res.imageName }))}
            setCurrentSlide={setSlide}
            currentSlide={slideCount}
            dotContainerRef={dotContainerRef}
          />
        )}
      </div>
      <GallerySlider transitionType={String(transitionType)}>
        {slideContent.map((value, index) => (
          <GallerySlide
            index={index}
            key={value.imageName}
            transitionType={String(transitionType)}
          >
            <div
              className="background-cover"
              style={{
                ...setBackground(
                  AssetHandler({ url: value.imageSource, type: 'legacy' }),
                  { gradiant: getGradiantValue(value) }
                ),
              }}
            >
              {value?.videoSource && (
                <Player
                  galleryName="vision"
                  getPlayerState={getCtrlVisibility}
                  videoUrl={AssetHandler({
                    url: value.videoSource,
                    type: 'legacy',
                  })}
                  isActive={index === slideCount}
                  playerControls={session?.vision?.playerControl}
                />
              )}
            </div>
          </GallerySlide>
        ))}
      </GallerySlider>
    </div>
  )
}

export default connect(({ firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
}))(CarouselContent)
