import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { resetBuilding } from '@store/actionSlices/building'
import { setLoadingIndicatorVisibility } from '@store/actionSlices/loadingIndicator'
import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import { ClearCacheSvg } from '@components/svg'

import { buildingApi } from '@api/building'
import { useClearMatrixCacheMutation } from '@api/clear-cache'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'
import { notifyError, notifySuccess } from '@utilities/notifier'

export interface ComponentProps {
  toggleModal: (arg: boolean) => void
  modalAction: string
  getModalAction: (arg: string) => void
  className: string
  projectName: string
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const CacheClear = ({
  toggleModal,
  modalAction,
  getModalAction,
  className,
  projectName,
  projectIdentity,
  session,
}: ComponentProps) => {
  const dispatch = useDispatch()

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const [clearMatrixCache, matrixCacheClearResponse] =
    useClearMatrixCacheMutation()

  React.useEffect(() => {
    if (modalAction === 'confirmed') {
      clearMatrixCache({ projectName })
      getModalAction('')
    }
  }, [modalAction])

  React.useEffect(() => {
    if (!matrixCacheClearResponse.isUninitialized) {
      if (matrixCacheClearResponse.isLoading) {
        dispatch(setLoadingIndicatorVisibility(true))
      } else {
        dispatch(setLoadingIndicatorVisibility(false))

        if (matrixCacheClearResponse.isSuccess) {
          dispatch(buildingApi.util.resetApiState())
          dispatch(resetBuilding())
          notifySuccess('The matrix cache has been successfully cleared.')
        }

        if (matrixCacheClearResponse.isError) {
          notifyError('Something went wrong, please try again.')
        }
      }
    }
  }, [matrixCacheClearResponse])

  const resetClearCacheTriggerKeyFromFirebase = async () => {
    await firebaseControlQuery.update({
      [`clearCacheTriggerKey`]: '',
      [`clearCacheKeyState`]: 'cleared',
    })
    window.location.reload()
  }

  React.useEffect(() => {
    if (session) {
      const { clearCacheTriggerKey: firebaseClearCacheTriggerKey } = session

      if (firebaseClearCacheTriggerKey) {
        clearMatrixCache({ projectName })
        resetClearCacheTriggerKeyFromFirebase()
      }
    }
  }, [session])

  return (
    <button
      onClick={() => toggleModal(true)}
      type="button"
      className={`block w-10 transform border-none duration-300 ease-in-out ${className}`}
    >
      <ClearCacheSvg className="h-10 w-10 text-white drop-shadow-70" />
    </button>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  projectIdentity,
  session: getSession(firestore),
}))(CacheClear)
