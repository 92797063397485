import React from 'react'

export interface ToggleSwitchInterface {
  initialState: boolean
  onChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  onColor?: string
  offColor?: string
}

const ToggleSwitch = ({
  initialState,
  onChangeCallback,
  isDisabled = false,
  onColor = 'bg-lime-500',
  offColor = 'bg-gray-400',
}: ToggleSwitchInterface) => {
  const [backgroundColor, setBackgroundColor] = React.useState(offColor)
  const [sliderPosition, setSliderPosition] = React.useState('left-1')

  React.useEffect(() => {
    setSliderPosition(initialState ? 'left-7' : 'left-1')
    setBackgroundColor(initialState ? onColor : offColor)
  }, [initialState])

  return (
    <label
      className={`relative h-6 w-12 cursor-pointer rounded-full ${backgroundColor}`}
    >
      <input
        disabled={isDisabled}
        type="checkbox"
        checked={initialState}
        className="sr-only"
        onChange={onChangeCallback}
      />
      <span
        className={`absolute top-1 h-4 w-4 rounded-full bg-white transition-all duration-300 ${sliderPosition}`}
      ></span>
    </label>
  )
}

export default ToggleSwitch
