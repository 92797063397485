import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setByFlag } from '@store/actionSlices/building'
import { filterShortlist, setShortlist } from '@store/actionSlices/shortlist'
import { RootStateFirebase } from '@store/types'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  PowderSvg,
  ShortListSvg,
  StudySvg,
} from '@components/svg'

import { Level, Unit } from '@api/building'
import { Shortlist } from '@api/shortlisted'

export interface ComponentPropsInterface {
  unit: Unit
  activeLevel: Level
  sessionConnected: boolean
  setShortlistErrorMessage: (arg: string) => void
  shortlist: Array<Shortlist>
}
const UnitCard = ({
  unit,
  activeLevel,
  sessionConnected,
  setShortlistErrorMessage,
  shortlist,
}: ComponentPropsInterface) => {
  const AVAILABLE_STATUS = 'available'
  const MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST) || 4
  const dispatch = useDispatch()

  const checkIfAvailable = (myUnit: Unit) =>
    myUnit.metas.status === AVAILABLE_STATUS

  const checkIfShortListed = (myUnit: Unit) =>
    shortlist.filter(
      (res: Shortlist) =>
        myUnit.name === res.name && res.level === activeLevel?.level
    ).length

  const shortlistUnit = (myUnit: Unit) => {
    if (unit.metas.status !== AVAILABLE_STATUS) {
      setShortlistErrorMessage(
        'This unit is not available, you can only shortlist available unit.'
      )
      return
    }

    if (checkIfShortListed(myUnit)) {
      dispatch(
        filterShortlist({
          ...myUnit,
          level: activeLevel.level,
        })
      )
      return
    }

    if (shortlist.length < MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST) {
      dispatch(
        setShortlist({
          ...myUnit,
          level: activeLevel.level,
        })
      )
    } else {
      setShortlistErrorMessage(
        `You can shortlist a maximum of ${MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST} units.`
      )
    }
  }

  const handleUnitClick = (myUnit: Unit) => {
    dispatch(setByFlag({ flag: 'activeUnit', value: myUnit.name }))
  }

  return (
    <div
      role="none"
      className={`mb-2.5 flex w-full cursor-pointer rounded-lg bg-white shadow-lg ${
        checkIfAvailable(unit) ? 'available' : 'sold'
      }`}
      onClick={() => handleUnitClick(unit)}
    >
      <div className="flex h-18 w-5/6 flex-col justify-between p-2.5">
        <div className="flex capitalize">
          <span className="truncate text-lg" title={unit.name}>
            {unit.name}
          </span>
          <span className="ml-4 text-lg">{unit.metas.status}</span>
        </div>
        <div className="flex">
          {Number(unit.metas?.configurations?.bed) > 0 && (
            <div className="mr-3 inline-flex items-center">
              <BedSvg className="mr-2 h-5 w-5" />
              {unit.metas?.configurations?.bed}
            </div>
          )}
          {Number(unit.metas?.configurations?.study) > 0 && (
            <div className="mr-3 inline-flex items-center">
              <StudySvg className="mr-2 h-5 w-5" />
              {unit.metas?.configurations?.study}
            </div>
          )}
          {Number(unit.metas?.configurations?.bath) > 0 && (
            <div className="mr-3 inline-flex items-center">
              <BathSvg className="mr-2 h-5 w-5" />
              {unit.metas?.configurations?.bath}
            </div>
          )}
          {Number(unit.metas?.configurations?.powderRoom) > 0 && (
            <div className="mr-3 inline-flex items-center">
              <PowderSvg className="mr-2 h-5 w-5" />
              {unit.metas?.configurations?.powderRoom}
            </div>
          )}
          {Number(unit.metas?.configurations?.car) > 0 && (
            <div className="mr-3 inline-flex items-center">
              <CarSvg className="mr-2 h-5 w-5" />
              {unit.metas?.configurations?.car}
            </div>
          )}
        </div>
      </div>
      <div className="relative w-1/6">
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation()
            if (!sessionConnected) shortlistUnit(unit)
          }}
          className="absolute -top-1 right-3"
        >
          <ShortListSvg
            fill={checkIfShortListed(unit) ? '#1293FB' : '#515050'}
            width="26"
            height="48"
          />
        </button>
      </div>
    </div>
  )
}
export default connect(({ shortlist }: RootStateFirebase) => ({
  shortlist,
}))(UnitCard)
