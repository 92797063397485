import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { UnitGallery } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface UnitGalleryPayload extends PayloadInterface {
  data: Array<UnitGallery>
}
type UnitGalleryType = {
  projectName: string
  unitId: string
}
export const unitGalleryApi = createApi({
  reducerPath: 'unitGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getUnitGallery: builder.query<UnitGalleryPayload, UnitGalleryType>({
      query: (params: UnitGalleryType) => ({
        url: `/v1/${params.projectName}/unit-galleries/${
          params.unitId
        }${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})
export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  unitGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})
export const { useGetUnitGalleryQuery } = unitGalleryApi
