import React from 'react'

export interface SidePanelProps {
  children: React.ReactNode
}

const LeftSidePanel = ({ children }: SidePanelProps) => (
  <div className="bg-mainColour inline-block w-1/4 xl:w-1/5 2xl:w-1/5">
    {children}
  </div>
)

export default LeftSidePanel
