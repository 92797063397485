import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  BuildingPageConfigInterface,
  setBuildingPageConfigByKey,
} from '@store/actionSlices/buildingPageConfig'
import { RootStateFirebase } from '@store/types'

import { PannellumDataInterface } from '@components/showcase-pannellum/types'
import { InfoCircleSvg, VirtualGogglesSvg } from '@components/svg'

import { Unit } from '@api/building'

export interface UnitInfoBarProps {
  unit: Unit | undefined
  isConnected: boolean
  showPrice: boolean
  buildingPageConfig: BuildingPageConfigInterface
  panoramic: Array<PannellumDataInterface>
  hideSoldPrice: boolean
}

const UnitInfoBar = ({
  unit,
  isConnected,
  showPrice,
  hideSoldPrice,
  buildingPageConfig,
  panoramic,
}: UnitInfoBarProps) => {
  const STATUS_SOLD = 'sold'
  const dispatch = useDispatch()

  const numberWithThousandSeparator = (number: string) => {
    const floatNumber = parseFloat(number).toFixed(0)
    return floatNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const hasPanoramicPayload = () => {
    if (unit !== undefined) {
      const foundData = panoramic.find(
        (data) =>
          data.type === 'unit' && data.name.toString() === unit.name.toString()
      )
      return foundData !== undefined
    }
    return false
  }

  const canWeShowPrice =
    showPrice && !(unit?.metas.status === STATUS_SOLD && hideSoldPrice)

  return (
    <div className="grid grid-cols-2 gap-1 2xl:grid-cols-3">
      <div
        className={`flex ${
          isConnected ? 'pl-5' : 'pl-18'
        } h-8 flex-row items-center gap-x-3`}
      >
        <div className="flex-initial flex-initial rounded-2xl bg-black p-1 px-3 text-white opacity-60">
          <span>{unit?.name}</span>
        </div>
        <div className="flex-initial flex-initial rounded-2xl bg-black p-1 px-3 text-white opacity-60">
          <span>
            {canWeShowPrice
              ? `$${numberWithThousandSeparator(
                  String(unit?.metas?.price) || '0'
                )}`
              : unit?.metas?.statusName}
          </span>
        </div>
      </div>
      <div className="flex h-8 flex-row-reverse items-center gap-x-0 pr-5 2xl:col-span-2">
        <div className="flex-initial">
          <button
            type="button"
            onClick={(e) => {
              if (isConnected) {
                e.preventDefault()
              } else {
                dispatch(
                  setBuildingPageConfigByKey({
                    key: 'unitInfoPopupOpen',
                    value: !buildingPageConfig.unitInfoPopupOpen,
                  })
                )
              }
            }}
            className="align-middle"
          >
            <InfoCircleSvg className="drop-shadow-70" />
          </button>
        </div>
        {hasPanoramicPayload() && (
          <div className={`flex-initial ${isConnected ? 'hidden' : ''}`}>
            <Link to="panoramic" className="align-middle">
              <VirtualGogglesSvg className="drop-shadow-70" />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default connect(
  ({
    buildingPageConfig,
    panoramic,
    projectConfig: { showPrice, hideSoldPrice },
  }: RootStateFirebase) => ({
    buildingPageConfig,
    panoramic,
    showPrice,
    hideSoldPrice,
  })
)(UnitInfoBar)
