import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { Stack } from '@api/interactive-map'

import isOffline from '@utilities/offline-mode-util'

let initialState = {
  src: '',
  layers: [],
} as Stack

if (isOffline()) {
  const interactiveMapData = localStorage.getItem('interactiveMapApiData')
  if (interactiveMapData) {
    initialState = JSON.parse(interactiveMapData)
  }
}

const interactiveMapSlice = createSlice({
  name: 'interactiveMap',
  initialState,
  reducers: {
    setInteractiveMap: (state: Stack, action: PayloadAction<Stack>) => {
      if (isOffline()) {
        localStorage.setItem(
          'interactiveMapApiData',
          JSON.stringify({
            ...state,
            ...action.payload,
          })
        )
      }
      return {
        ...state,
        ...action.payload,
      }
    },
    removeInteractiveMap: () => {
      localStorage.removeItem('interactiveMapApiData')
      return {
        src: '',
        layers: [],
      }
    },
  },
})

export const { setInteractiveMap, removeInteractiveMap } =
  interactiveMapSlice.actions

export const selectInteractiveMap = (state: RootStateTypeExtra) =>
  state.interactiveMap

export default interactiveMapSlice.reducer
