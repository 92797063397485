import React from 'react'
import { useSelector } from 'react-redux'

import { RootStateFirebase } from '@store/types'

const events = ['mousemove', 'click', 'keyup', 'touchstart', 'touchmove']

export const useIdleTimeHandler = () => {
  const { controls } = useSelector(
    (state: RootStateFirebase) => state.projectConfig
  )

  const timerId = React.useRef<NodeJS.Timeout | null>(null)
  const [isAppIdle, setIsAppIdle] = React.useState<boolean>(false)

  const handleSetAppIdle = () => {
    if (timerId.current) clearTimeout(timerId.current)
    timerId.current = null

    setIsAppIdle(true)
  }

  const onUserInteraction = () => {
    setIsAppIdle(false)

    if (timerId.current) {
      clearTimeout(timerId.current)
    }

    if (controls?.duration) {
      timerId.current = setTimeout(handleSetAppIdle, controls?.duration * 1000)
    }
  }

  React.useEffect(() => {
    if (controls?.duration) {
      timerId.current = setTimeout(handleSetAppIdle, controls.duration * 1000)

      events.forEach((event: string) =>
        document.addEventListener(event, onUserInteraction, true)
      )
    }

    return () => {
      if (timerId.current) clearTimeout(timerId.current)
      events.forEach((event: string) =>
        document.removeEventListener(event, onUserInteraction, true)
      )
    }
  }, [controls?.duration])

  return controls?.active && isAppIdle
}

export default {}
