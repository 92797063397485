import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  ConfigInterface,
  setEnvisionVRConfiguration,
  setMarkerColour,
  setPrices,
  setProjectConfig,
  setSnaploaderConfiguration,
  setThreeDViewerUrl,
} from '@store/actionSlices/projectConfig'
import { RangeInterface, setFilter } from '@store/actionSlices/unitFilter'
import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import ConfigPopup from '@components/config-popup'
import IdleTimeHandler from '@components/idle-time-handler'
import { HomeSvg, MenuSvg } from '@components/svg'

import {
  PriceRange,
  selectFromResult as selectFromResultConfig,
  useGetConfigQuery,
} from '@api/config'

import getSession from '@utilities/firebase-util'
import { hasEnvisionVR, hasSnaploader } from '@utilities/helper'

import DropdownMenu from './drop-down-menu'
import SideOptions from './side-options'

export interface navigationProps {
  sideOption?: React.ReactNode
  linkClasses?: {
    buildClass?: string
    homeClass?: string
    toggleClass?: string
  }
  hasOptions?: boolean
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  map: SessionMap | undefined
  includePopup?: boolean
  isVisible?: boolean
  classes?: string
  projectConfig: ConfigInterface
  range: RangeInterface
  hasLightMap: boolean
}

const Navigation = ({
  sideOption,
  linkClasses,
  hasOptions,
  projectIdentity,
  session,
  includePopup = true,
  isVisible = true,
  classes = 'p-8',
  map,
  projectConfig,
  range,
  hasLightMap,
}: navigationProps) => {
  const dispatch = useDispatch()

  const [isPopUpOpen, togglePopup] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const [isDropdownMenuOpen, toggleDropdownMenu] = React.useState(false)

  const {
    isLoaded,
    prices,
    configInfo,
    threeDViewer,
    markerColour,
    snaploaderConfiguration,
    envisionVRConfiguration,
  } = useGetConfigQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromResultConfig }
  )

  const setFilterPriceRange = ({ maxPrice, minPrice }: PriceRange) => {
    if (range.min === 0 && range.max === 0) {
      dispatch(
        setFilter({
          range: {
            max: maxPrice,
            min: minPrice,
          },
        })
      )
    }
  }

  const getBuildingLink = (): string => {
    const { threeDViewerUrl } = projectConfig

    if (hasSnaploader(projectConfig.snaploaderConfiguration)) {
      return 'snaploader-view'
    }

    if (hasEnvisionVR(projectConfig.envisionVRConfiguration)) {
      return 'envision-vr'
    }

    if (threeDViewerUrl !== '') {
      return 'areaview-3d'
    }

    return 'area-view'
  }

  React.useEffect(() => {
    const { minPrice, maxPrice } = prices
    if (minPrice >= 0 && maxPrice >= 0) {
      dispatch(setPrices(prices))
      setFilterPriceRange(prices)
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (markerColour && Object.values(markerColour).length > 0) {
      dispatch(setMarkerColour(markerColour))
    }
  }, [isLoaded])

  React.useEffect(() => {
    const { threeDViewerUrl } = projectConfig
    if (threeDViewer?.active && !threeDViewerUrl) {
      dispatch(setThreeDViewerUrl(threeDViewer.url))
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (Object.keys(configInfo).length > 0) {
      dispatch(setProjectConfig({ ...configInfo }))
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (
      snaploaderConfiguration &&
      Object.values(snaploaderConfiguration).length > 0
    ) {
      dispatch(setSnaploaderConfiguration(snaploaderConfiguration))
    }
    if (
      envisionVRConfiguration &&
      Object.values(envisionVRConfiguration).length > 0
    ) {
      dispatch(setEnvisionVRConfiguration(envisionVRConfiguration))
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  return (
    <>
      {!isConnected && (
        <IdleTimeHandler>
          <ul
            className={`z-20 flex h-24 justify-between transition-opacity duration-300 ${classes} fixed left-0 top-0 w-screen ${
              isVisible ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <li>
              <Link
                className={`text-2xl text-white ${linkClasses?.buildClass} drop-shadow-70`}
                to={getBuildingLink()}
              >
                Building
              </Link>
            </li>
            <li>
              <Link
                className={`${linkClasses?.homeClass} drop-shadow-70`}
                to="vision"
              >
                <HomeSvg className="h-9 text-white" />
              </Link>
            </li>
            <li>
              {sideOption || (
                <>
                  <button
                    onClick={() => toggleDropdownMenu(!isDropdownMenuOpen)}
                    type="button"
                  >
                    <MenuSvg className="h-10 w-10 text-white drop-shadow-70" />
                  </button>
                </>
              )}
            </li>
          </ul>
        </IdleTimeHandler>
      )}
      {hasOptions && (
        <div className="relative z-10">
          <SideOptions
            session={session}
            map={map}
            hasLightMap={hasLightMap}
            projectIdentity={projectIdentity}
            className={`absolute right-8 inline-block border-none transition-opacity ${
              isVisible ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
      )}
      {includePopup && (
        <ConfigPopup
          isOpen={isPopUpOpen}
          toggle={(isOpen) => togglePopup(isOpen)}
          session={session}
        />
      )}
      {!isConnected && (
        <IdleTimeHandler>
          <DropdownMenu
            isDropdownMenuOpen={isDropdownMenuOpen}
            isConfigPopupOpen={isPopUpOpen}
            toggleDropdownMenu={toggleDropdownMenu}
            toggleConfigPopup={togglePopup}
          />
        </IdleTimeHandler>
      )}
    </>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore,
    projectConfig,
    unitFilter: { range },
  }: RootStateFirebase) => ({
    projectIdentity,
    session: getSession(firestore),
    hasLightMap: firestore?.data?.lightMap?.channels !== undefined,
    map: firestore.data.map,
    projectConfig,
    range,
  })
)(Navigation)
