import React from 'react'

import type { Channel, ProjectIdentity } from '@store/types'

import { Shortlist } from '@api/shortlisted'

import FirebaseControlQuery from '@utilities/firebase-control-query'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  channels: Array<Channel>
  activeLevel: string
  activeUnit: string
  shortlist: Array<Shortlist>
}

const LightMapHandler = ({
  projectIdentity,
  channels,
  activeLevel,
  activeUnit,
  shortlist,
}: ComponentProps): void => {
  const CHANNEL_TYPE_SHORTLIST = 'shortlist'
  const CHANNEL_TYPE_UNIT = 'unit'
  const CHANNEL_TYPE_LEVEL = 'level'

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const handleActiveLevelMap = () => {
    if (channels.length === 0) {
      return
    }

    channels.forEach((channel: Channel, index: number) => {
      if (channel.type !== CHANNEL_TYPE_LEVEL) {
        return
      }

      firebaseControlQuery.updateLightChannel({
        [`channels.${index}.status`]: channel.name === activeLevel,
      })
    })
  }

  const handleActiveUnitlMap = () => {
    if (channels.length === 0) {
      return
    }

    channels.forEach((channel: Channel, index: number) => {
      if (channel.type !== CHANNEL_TYPE_UNIT) {
        return
      }

      firebaseControlQuery.updateLightChannel({
        [`channels.${index}.status`]: channel.name === activeUnit,
      })
    })
  }

  const handleShortlistedUnitMap = () => {
    if (channels.length === 0) {
      return
    }

    if (shortlist.length === 0) {
      channels.forEach((channel: Channel, index: number) => {
        if (channel.type !== CHANNEL_TYPE_SHORTLIST) {
          return
        }

        firebaseControlQuery.updateLightChannel({
          [`channels.${index}.status`]: false,
        })
      })
      return
    }

    channels.forEach((channel: Channel, index: number) => {
      if (channel.type !== CHANNEL_TYPE_SHORTLIST) {
        return
      }

      const foundItem = shortlist.filter(
        (shortlistedUnit: Shortlist) => shortlistedUnit.name === channel.name
      )

      firebaseControlQuery.updateLightChannel({
        [`channels.${index}.status`]: foundItem.length > 0,
      })
    })
  }

  const resetChannels = () => {
    channels.forEach((channel: Channel, index: number) => {
      if (channel.type === CHANNEL_TYPE_SHORTLIST) {
        return
      }

      firebaseControlQuery.updateLightChannel({
        [`channels.${index}.status`]: false,
      })
    })
  }

  React.useEffect(() => {
    handleActiveUnitlMap()
  }, [activeUnit])

  React.useEffect(() => {
    handleActiveLevelMap()
  }, [activeLevel])

  React.useEffect(() => {
    handleShortlistedUnitMap()
  }, [shortlist])

  React.useEffect(
    () => () => {
      if (channels.length > 0) {
        resetChannels()
      }
    },
    []
  )
}

export default LightMapHandler
