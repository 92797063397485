import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { setAssetPort, setRoom } from '@store/actionSlices/appConfig'
import { RootStateType } from '@store/store'

import { CloseCircleSvg } from '@components/svg'

import { getQueryStringParams } from '@utilities/helper'

export interface ModalInterface {
  toggleModal: (arg: boolean) => void
  isVisible: boolean
  assetPort: string
  room: string
}

const AssetModal = ({
  isVisible,
  toggleModal,
  assetPort,
  room,
}: ModalInterface) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const VISION_ROUTE = 'vision'

  const [port, setPort] = React.useState('')
  const [portInputError, setPortInputError] = React.useState('')
  const [roomValue, setRoomValue] = React.useState('')
  const [roomInputError, setRoomInputError] = React.useState('')

  const handleModalClose = () => {
    setPortInputError('')
    setPort(assetPort)
    setRoomInputError('')
    setRoomValue(room)
    toggleModal(false)
  }

  const isValidPort = (value: number) => {
    const MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT = 65535
    return value > 0 && value <= MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT
  }

  const handleSavePort = () => {
    setPortInputError('')
    if (!port) {
      setPortInputError('Port field is required.')
      return
    }

    if (!isValidPort(Number(port))) {
      setPortInputError('The given port is not valid.')
      return
    }

    dispatch(setAssetPort(port))
  }

  const handleRemovePort = () => dispatch(setAssetPort(''))

  const handleSaveRoom = () => {
    setRoomInputError('')
    if (roomValue) {
      dispatch(setRoom(roomValue))
      history.push(VISION_ROUTE)
      window.location.reload()
    } else {
      setRoomInputError('Room field is required.')
    }
  }

  const handleRemoveRoom = () => {
    dispatch(setRoom(''))
    history.push(VISION_ROUTE)
    window.location.reload()
  }

  React.useEffect(() => {
    setPort(assetPort)
  }, [assetPort])

  React.useEffect(() => {
    setRoomValue(room)
  }, [room])

  React.useEffect(() => {
    const { room: roomParam } = getQueryStringParams(location.search)
    if (roomParam !== undefined) {
      dispatch(setRoom(roomParam))
    }
  }, [])

  return (
    <div
      id="default-modal"
      aria-hidden="true"
      className={`h-modal fixed left-0 right-0 top-4 z-50 transform items-center justify-center overflow-y-auto overflow-x-hidden duration-500
      ease-in-out md:inset-0 md:h-full ${
        isVisible ? 'translate-y-0' : '-translate-y-full'
      }
      `}
    >
      <div className="m-auto mt-32 h-full w-full max-w-2xl px-4 md:h-auto">
        <div className="rounded-lg bg-white shadow dark:bg-gray-700">
          <div className="flex items-center justify-between rounded-t border-b p-5 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
              Asset Settings
            </h3>
            <button
              type="button"
              onClick={() => handleModalClose()}
              className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="default-modal"
            >
              <CloseCircleSvg fill="#000000" />
            </button>
          </div>
          <div className="space-y-6 p-6">
            <p className="text-center leading-relaxed text-gray-500  dark:text-gray-400">
              For improved performance you can choose to use an asset pack
              hosted on your local machine.
            </p>
            <div>
              <div className="flex">
                <span className="flex items-center rounded-l border border-solid border-neutral-300 px-3 text-center text-base font-normal text-neutral-700">
                  Port
                </span>
                <input
                  type="number"
                  name="port"
                  value={port}
                  className={`w-full border-gray-200 focus:border-gray-200 focus:ring-0 ${
                    assetPort ? 'bg-gray-200' : ''
                  }`}
                  onChange={(event) => setPort(event.target.value)}
                  placeholder="8080"
                  min={0}
                  disabled={assetPort !== ''}
                />
                <div className="border-r-1 flex items-center rounded-r border border-solid border-neutral-300 px-3 text-center text-base font-normal text-neutral-700">
                  {assetPort ? (
                    <button type="button" onClick={handleRemovePort}>
                      Remove
                    </button>
                  ) : (
                    <button type="button" onClick={handleSavePort}>
                      Save
                    </button>
                  )}
                </div>
              </div>
              <div>
                {portInputError && (
                  <small className="text-red-500">{portInputError}</small>
                )}
              </div>
            </div>
            <div>
              <div className="flex">
                <span className="flex items-center rounded-l border border-solid border-neutral-300 px-3 text-center text-base font-normal text-neutral-700">
                  Room
                </span>
                <input
                  type="text"
                  name="room"
                  value={roomValue}
                  className={`w-full border-gray-200 focus:border-gray-200 focus:ring-0 ${
                    room ? 'bg-gray-200' : ''
                  }`}
                  onChange={(event) => setRoomValue(event.target.value)}
                  placeholder="Enter room"
                  min={20}
                  disabled={room !== ''}
                />
                <div className="border-r-1 flex items-center rounded-r border border-solid border-neutral-300 px-3 text-center text-base font-normal text-neutral-700">
                  {room ? (
                    <button type="button" onClick={handleRemoveRoom}>
                      Remove
                    </button>
                  ) : (
                    <button type="button" onClick={handleSaveRoom}>
                      Save
                    </button>
                  )}
                </div>
              </div>
              <div>
                {roomInputError && (
                  <small className="text-red-500">{roomInputError}</small>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end border-t border-gray-200 px-6 py-3">
            <button
              type="button"
              onClick={() => handleModalClose()}
              className="rounded-md border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-0 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(({ appConfig: { assetPort, room } }: RootStateType) => ({
  assetPort,
  room,
}))(AssetModal)
