import { CarouselProvider } from 'pure-react-carousel'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { setBrochure } from '@store/actionSlices/brochure'
import type { RootStateFirebase, SessionMap } from '@store/types'

import DataHandler from '@components/data-handler'
import Footer from '@components/footer'
import GalleryHandler from '@components/gallery-handler'
import SlideTrigger from '@components/gallery-handler/slide-trigger'
import IdleTimeHandler from '@components/idle-time-handler'
import Navigation from '@components/navigation'

import {
  BrochureData,
  GalleryData,
  selectFromResult,
  useGetBrochureQuery,
} from '@api/brochure'

import getSession from '@utilities/firebase-util'

export interface BrochureProps {
  session: SessionMap | undefined
  projectName: string
  brochure: Array<BrochureData>
}

const Brochure = ({ session, projectName, brochure }: BrochureProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const [activeLabel, setActiveLabel] = React.useState(0)
  const [isSessionConnected, setIsConnected] = React.useState(false)
  const [transitionTime, setTransitionTime] = React.useState(1000)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const brochurePayload = useGetBrochureQuery(
    { projectName },
    { selectFromResult }
  )

  React.useEffect(() => {
    const { brochureData } = brochurePayload
    if (brochure.length === 0 && brochureData.length > 0) {
      dispatch(setBrochure(brochureData))
    }
  }, [brochurePayload, brochure])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        brochure: {
          galleryControl: {
            activeTabIndex: activeTabIndexFirebase,
            transitionTime: transitionTimeFirebase,
            isPlaying: isPlayingFirebase,
          },
        },
      } = session

      setIsConnected(connected)
      setActiveLabel(activeTabIndexFirebase)
      setTransitionTime(transitionTimeFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  const carouselData = React.useMemo(
    () =>
      brochure.length > 0
        ? {
            slides: brochure[activeLabel].Images.map(
              (brochureContent) => brochureContent.imageSource
            ),
            labels: brochure[activeLabel].Images.map(
              (res: GalleryData, index) => ({
                label: `label-${index}`,
                sublabel: `sublabel-${index}`,
              })
            ),
          }
        : { slides: [], labels: [] },
    [brochure, activeLabel]
  )

  return (
    <div>
      <Navigation />
      <DataHandler
        payload={{
          ...brochurePayload,
          data: brochure,
        }}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={brochure.length}
          dragEnabled={false}
          orientation="vertical"
          className="ipad-p:w-1100px"
          interval={transitionTime}
          isPlaying={isSlideShowPlaying}
        >
          {!isSessionConnected && brochure.length > 0 && (
            <IdleTimeHandler>
              <div className="relative z-10">
                <div className="absolute flex h-screen items-center pl-8">
                  <SlideTrigger
                    type="label"
                    items={brochure.map((res: BrochureData) => ({
                      text: res.label,
                    }))}
                    setCurrentSlide={setActiveLabel}
                    currentSlide={activeLabel}
                    dotContainerRef={dotContainerRef}
                  />
                </div>
              </div>
            </IdleTimeHandler>
          )}
          <TransitionGroup>
            <CSSTransition
              key={activeLabel}
              classNames="transition-toTop"
              timeout={500}
            >
              <GalleryHandler
                galleryName="brochure"
                galleryControl={session?.brochure.galleryControl}
                slides={carouselData.slides}
                isConnected={isSessionConnected}
                labels={carouselData.labels}
              />
            </CSSTransition>
          </TransitionGroup>
        </CarouselProvider>
      </DataHandler>
      <Footer />
    </div>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    brochure,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    brochure,
  })
)(Brochure)
