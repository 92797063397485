import React from 'react'

import Container from '@components/container'

import { DataNotFound, SomethingWentWrong } from './errors'

export interface Payload {
  isLoaded: boolean
  isError: boolean
  status: string
  data: any
}

export interface DataHandlerProps {
  children: React.ReactNode
  payload: Payload
  message?: string
}

const DataHandler = ({
  children,
  payload,
  message = 'This page needs to be setup',
}: DataHandlerProps) => {
  const UNINITIALIZED_STATUS = 'uninitialized'
  const PENDING_STATUS = 'pending'
  const FULFILLED_STATUS = 'fulfilled'
  const REJECTED_STATUS = 'rejected'

  const [isLoaded, setIsLoaded] = React.useState(false)
  const [hasData, sethasData] = React.useState(true)

  const checkData = (data: any) => {
    if (!data) {
      sethasData(false)
      return false
    }
    if (Array.isArray(data)) {
      sethasData(data.length > 0)
      return data.length > 0
    }
    sethasData(true)
    return true
  }

  const handleInitialLoad = () => {
    const { status, data } = payload
    if (status === FULFILLED_STATUS || status === REJECTED_STATUS) {
      setIsLoaded(true)
      checkData(data)
    }
  }

  const handleRouteSwitch = () => {
    const { status, data } = payload
    if (status === UNINITIALIZED_STATUS && checkData(data)) {
      setIsLoaded(true)
    }
  }

  React.useEffect(() => {
    handleInitialLoad()
    handleRouteSwitch()
  }, [payload])

  if (!isLoaded && !payload.isError) {
    return (
      <Container
        className="relative flex items-center justify-center"
        background={{
          url: '/images/devsuite-bg.jpg',
          type: 'new',
          noSpliceUrl: true,
        }}
      >
        <div
          style={{ borderTopColor: 'transparent' }}
          className="border-white-400 absolute left-1/2 top-1/2 h-16 w-16 animate-spin rounded-full border-4 border-solid"
        />
      </Container>
    )
  }

  if (isLoaded && payload.isError && !hasData) {
    return <SomethingWentWrong />
  }

  if (isLoaded && !payload.isError && !hasData) {
    return <DataNotFound message={message} />
  }

  return <>{children}</>
}

export default DataHandler
