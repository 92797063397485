import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface GalleryData {
  label: string
  distance: string
  imageSource: string
  imageName: string
}

export interface LocationData {
  label: string
  Images: Array<GalleryData>
}

export interface LocationPayload extends PayloadInterface {
  data: Array<LocationData>
}

type LocationType = {
  projectName: string
}

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getLocation: builder.query<LocationPayload, LocationType>({
      query: (params: LocationType) => ({
        url: `/v1/${params.projectName}/location${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  locations: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetLocationQuery } = locationApi
