import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import {
  BuildingPageConfigInterface,
  setBuildingPageConfigByKey,
} from '@store/actionSlices/buildingPageConfig'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import { setFilter } from '@store/actionSlices/unitFilter'
import {
  Channel,
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FilterPopup from '@components/filter-popup'
import IdleTimeHandler from '@components/idle-time-handler'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import Navigation from '@components/navigation'
import { BuildingSvg, ShortListSvg } from '@components/svg'

import {
  Unit,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  MappingCollection,
  selectFromResult as selectFromResultInteractive,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import { Shortlist } from '@api/shortlisted'

import getSession from '@utilities/firebase-util'
import LightMapHandler from '@utilities/lightmap-handler'

import FloorplateView from './floorplate-view'
import UnitInfoPopup from './unit-info-popup'
import UnitView from './unit-view'
import UnitsSidePanel from './units-side-panel'

export interface BuildingProps {
  building: BuildingInterface
  floorplan: MappingCollection
  shortlist: Array<Shortlist>
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  channels: Array<Channel>
  buildingPageConfig: BuildingPageConfigInterface
}

const Building = ({
  shortlist,
  floorplan,
  session,
  building,
  projectIdentity,
  buildingPageConfig,
  channels,
}: BuildingProps) => {
  const dispatch = useDispatch()
  const isAppIdle = useIdleTimeHandler()

  const [unit, setUnit] = React.useState<Unit>()
  const [isConnected, setIsConnected] = React.useState(false)

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromResultInteractive }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const floorplanLength = Object.keys(floorplan).length

  LightMapHandler({
    projectIdentity,
    channels: Object.values(channels),
    activeLevel: building.activeLevel,
    activeUnit: building.activeUnit,
    shortlist,
  })

  const sideOption = (
    <Link
      className="fixed right-0 inline-flex items-center text-2xl text-white"
      to="shortlisted"
    >
      Shortlisted ({shortlist.length})
      <ShortListSvg className="h-10" />
    </Link>
  )

  const linkClasses = { buildClass: 'anchor-underlined' }

  const [isFilterOpen, toggleFilter] = React.useState(false)

  const handleActiveBlockControl = (blockCntrl: string) => {
    if (blockCntrl !== building.activeBlock) {
      dispatch(setByFlag({ flag: 'activeBlock', value: blockCntrl }))
    }
  }

  const handleActiveLevelControl = (activeLevel: string) => {
    if (activeLevel !== building.activeLevel) {
      dispatch(setByFlag({ flag: 'activeLevel', value: activeLevel }))
    }
  }

  const handleActiveUnitControl = (activeUnit: string) => {
    setTimeout(
      () => {
        dispatch(setByFlag({ flag: 'activeUnit', value: activeUnit }))
      },
      activeUnit ? 1000 : 0
    )
  }

  const findUnit = () => {
    building.levels.forEach((lvl) => {
      const foundUnit = lvl.data.find(
        (unt) =>
          unt.name.replace(/\s/g, '') === building.activeUnit.replace(/\s/g, '')
      )
      if (foundUnit) {
        setUnit(foundUnit)
      }
    })
  }

  const resetUnit = () => {
    setUnit(undefined)
  }

  React.useEffect(() => {
    if (building.levels.length && building.activeUnit) {
      findUnit()
    }
    if (!building.activeUnit) {
      resetUnit()
    }
  }, [building])

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (!floorplanLength && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload, floorplan])

  React.useEffect(() => {
    const { building: buildingData, aspects } = buildingPayload

    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          activeLevel: buildingData[0]?.level,
          activeBlock: buildingData[0]?.data[0].blockId || '',
          aspects,
        })
      )
    }
  }, [buildingPayload, building])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: {
          activeBlock,
          activeLevel,
          activeUnit,
          filterPopup,
          unitInfoPopup,
          sidePanelFolded,
          unitFilter: unitFilterFirestore,
        },
      } = session
      if (connected) {
        dispatch(setFilter(unitFilterFirestore))
        toggleFilter(filterPopup)
        dispatch(
          setBuildingPageConfigByKey({
            key: 'unitInfoPopupOpen',
            value: unitInfoPopup,
          })
        )
        dispatch(
          setBuildingPageConfigByKey({
            key: 'unitPanelFolded',
            value: sidePanelFolded,
          })
        )
        handleActiveBlockControl(activeBlock)
        handleActiveUnitControl(activeUnit)
        handleActiveLevelControl(activeLevel)
        setIsConnected(connected)
      }
    }
  }, [session])

  return (
    <div>
      {!buildingPageConfig.unitPanelFolded && !isAppIdle && (
        <Navigation
          {...{
            sideOption,
            linkClasses,
            includePopup: false,
            classes: 'pt-3 pb-5 pr-3 pl-7',
          }}
        />
      )}
      <DataHandler
        payload={{
          ...buildingPayload,
          data: building.levels,
        }}
      >
        <Container className="bg-secondaryColour relative z-10 flex items-end">
          {!isConnected && (
            <IdleTimeHandler>
              <div className="absolute left-5 top-10 z-20">
                <button
                  type="button"
                  className={`cursor-pointer rounded-full bg-gray-200 p-1 drop-shadow-40 hover:bg-blue-500 hover:text-white ${
                    buildingPageConfig.unitPanelFolded || isAppIdle
                      ? 'visible delay-200 duration-300'
                      : 'invisible'
                  }`}
                  onClick={() =>
                    dispatch(
                      setBuildingPageConfigByKey({
                        key: 'unitPanelFolded',
                        value: false,
                      })
                    )
                  }
                >
                  <BuildingSvg className="h-8 w-8" />
                </button>
              </div>
            </IdleTimeHandler>
          )}
          <UnitsSidePanel
            isPopUpOpen={isFilterOpen}
            togglePopup={(arg: boolean) => toggleFilter(arg)}
            isUnitPanelFolded={buildingPageConfig.unitPanelFolded || isAppIdle}
            isConnected={isConnected}
          />
          <div
            className={`flex-1 bg-white transition-size-spacing ease-in-out ${
              !buildingPageConfig.unitPanelFolded && !isAppIdle
                ? '-ml-20 h-screen-92vh pl-20 sm-h:h-screen-91vh'
                : 'h-screen'
            }`}
          >
            {building.activeUnit ? (
              unit && <UnitView key={unit.id} unit={unit} />
            ) : (
              <DataHandler
                payload={{
                  ...interactivePayload,
                  data: floorplanLength,
                }}
              >
                <FloorplateView />
              </DataHandler>
            )}
          </div>
        </Container>
        <FilterPopup
          isOpen={isFilterOpen}
          toggle={toggleFilter}
          className={`bottom-0 ${
            isConnected ? 'h-screen' : 'h-screen-92vh sm-h:h-screen-91vh'
          }`}
        />
        {unit && (
          <UnitInfoPopup
            activeLevel={building.activeLevel}
            activeUnit={unit}
            isUnitPanelFolded={buildingPageConfig.unitPanelFolded || isAppIdle}
            isUnitInfoPopupOpen={buildingPageConfig.unitInfoPopupOpen}
            isConnected={isConnected}
          />
        )}
      </DataHandler>
    </div>
  )
}

export default connect(
  ({
    interactivePlan: { floorplan },
    projectIdentity,
    shortlist,
    firestore,
    building,
    buildingPageConfig,
  }: RootStateFirebase) => ({
    floorplan,
    projectIdentity,
    building,
    shortlist,
    session: getSession(firestore),
    channels: firestore?.data?.lightMap?.channels || [],
    buildingPageConfig,
  })
)(Building)
