import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setInteractiveMap } from '@store/actionSlices/interactiveMap'
import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { CanvasStack } from '@components/showcase-canvas'
import {
  CanvasRefInterface,
  Layer,
} from '@components/showcase-canvas/canvas-stack'

import {
  Stack,
  selectFromResult,
  useGetInteractiveMapQuery,
} from '@api/interactive-map'

import getSession from '@utilities/firebase-util'

interface LayerExtended extends Layer {
  category: string
  label: string
  controlSrc: string
}

export interface InteractiveMapProps {
  session: SessionMap | undefined
  projectName: string
  interactiveMap: Stack
}

const InteractiveMap = ({
  session,
  projectName,
  interactiveMap,
}: InteractiveMapProps) => {
  const canvasRef = React.useRef<CanvasRefInterface>()
  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const interactiveMapPayload = useGetInteractiveMapQuery(
    { projectName },
    { selectFromResult }
  )

  const handleAnimationLayer = (layer: LayerExtended) => ({
    ...layer,
    src: `${layer.src.split('?')[0]}?${Math.random()}`,
    animation: layer?.animation
      ? `${layer.animation.split('?')[0]}?${Math.random()}`
      : '',
    visible: true,
  })

  const handleLayerTrigger = (trigger: Array<string>) => {
    if (interactiveMap?.layers) {
      dispatch(
        setInteractiveMap({
          ...interactiveMap,
          layers: interactiveMap?.layers.map((res) => {
            if (!res.controls) {
              return res
            }
            if (!trigger.find((trg) => trg === res.groupId)) {
              return {
                ...res,
                visible: false,
              }
            }
            if (res.visible) {
              return res
            }
            if (res.type.toLowerCase() === 'animation') {
              return handleAnimationLayer(res)
            }
            return {
              ...res,
              visible: true,
            }
          }),
        })
      )
    }
  }

  React.useEffect(() => {
    const { stack } = interactiveMapPayload
    if (interactiveMap.layers.length === 0 && stack.layers.length > 0) {
      dispatch(setInteractiveMap(stack))
    }
  }, [interactiveMapPayload, interactiveMap])

  React.useEffect(() => {
    if (session) {
      const { interactiveMap: interactiveMapSession } = session
      handleLayerTrigger(interactiveMapSession)
    }
  }, [session])

  return (
    <div>
      <Container
        background={{
          url: interactiveMap?.src || '',
          type: 'new',
          noSpliceUrl: true,
        }}
      >
        <DataHandler
          payload={{
            ...interactiveMapPayload,
            data: interactiveMap.layers,
          }}
        >
          <div ref={canvasContainerRef} className="h-screen w-screen">
            {interactiveMap?.layers && canvasContainerRef && (
              <CanvasStack
                id="interactive-map"
                parentRef={canvasContainerRef}
                ref={canvasRef}
                background={interactiveMap.src}
                layers={interactiveMap.layers}
              />
            )}
          </div>
        </DataHandler>
      </Container>
    </div>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    interactiveMap,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    interactiveMap,
  })
)(InteractiveMap)
