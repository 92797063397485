import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { setByFlag } from '@store/actionSlices/building'
import { setFilter } from '@store/actionSlices/unitFilter'
import type {
  RootStateFirebase,
  SessionMap,
  UnitFilterInterface,
} from '@store/types'

import Container from '@components/container'
import DropDown from '@components/dropdown'
import FilterPopup from '@components/filter-popup'
import IdleTimeHandler from '@components/idle-time-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import {
  CanvasRefInterface,
  Polygon,
} from '@components/showcase-canvas/canvas-interactive'
import {
  ArrowHorizontalSvg,
  ArrowSquareSvg,
  BuildingSvg,
  MenuToggleSvg,
} from '@components/svg'

import { Level } from '@api/building'
import { MapContent, MappingBlockCollection } from '@api/interactive-plan'

import getSession from '@utilities/firebase-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

export interface LevelProps {
  session: SessionMap | undefined
  blocks: MappingBlockCollection
  levels: Array<Level>
  unitFilter: UnitFilterInterface
  storeActiveBlock: string
  showPrice: boolean
  levelMarkerColour: string
  hideFilter: boolean
}

const LevelView = ({
  session,
  blocks: storeBlocks,
  storeActiveBlock,
  levels,
  unitFilter,
  showPrice,
  levelMarkerColour,
  hideFilter,
}: LevelProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const blockKeys: Array<string> = Object.keys(storeBlocks) || []

  const prevReference = React.useRef<{ blockFocus: string }>({
    blockFocus:
      blockKeys.find((res) => storeActiveBlock === res) || blockKeys[0],
  })
  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [rendering, setRendering] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [activeFacade, setFacade] = React.useState(0)
  const [blockFocus, setBlockFocus] = React.useState(
    blockKeys.find((res) => storeActiveBlock === res) || blockKeys[0]
  )
  const [block, setBlock] = React.useState<Array<MapContent> | []>([])
  const [isConnected, setIsConnected] = React.useState(false)
  const [showLabels, setShowLabels] = React.useState(false)

  const [facadeControl, setFacadeControl] = React.useState('')

  const setLevelState = (poly: Polygon) => {
    const foundLevel = levels.find((res) => poly.groupId === res.level)

    if (foundLevel) {
      const { data: units } = foundLevel
      return `- Available Units (${
        units.filter((res) => res.metas.status === 'available').length
      })`
    }
    return ''
  }

  const handleUnitStatus = (poly: Polygon) => {
    const { apply } = unitFilter

    let levelActive = false

    const splicdeGroupId = poly.groupId.split('-')

    const foundLevels = levels.find((res) => {
      if (splicdeGroupId.length > 1) {
        return splicdeGroupId[1] === res.level
      }
      return poly.groupId === res.level
    })

    if (!apply || !foundLevels) {
      return false
    }
    foundLevels.data.forEach((unit) => {
      if (splicdeGroupId.length > 1) {
        if (unit.blockId !== splicdeGroupId[0]) {
          return
        }
      }
      if (!levelActive) {
        levelActive = filterUnitUtil(unit, unitFilter, showPrice)
      }
    })

    return levelActive
  }

  const checkGroupId = (groupId: string) => {
    const splitGroupString = groupId.split('-')
    if (splitGroupString.length > 1) {
      const [activeBlock, activeLevel] = splitGroupString
      return {
        activeBlock,
        activeLevel,
      }
    }
    return {
      activeLevel: groupId,
      activeBlock: '',
    }
  }

  const setInteractiveAction = (areaViewMap: MapContent) => ({
    ...areaViewMap,
    polygons: areaViewMap.polygons.map((poly) => ({
      ...poly,
      ...{
        activeByDefault: handleUnitStatus(poly),
      },
      postFix: setLevelState(poly),
      onClick: () => {
        const { activeLevel, activeBlock } = checkGroupId(poly.groupId || '')
        dispatch(setByFlag({ flag: 'activeLevel', value: activeLevel }))
        dispatch(
          setByFlag({
            flag: 'activeBlock',
            value: activeBlock || (blockKeys.length > 1 ? blockFocus : ''),
          })
        )
        dispatch(setByFlag({ flag: 'activeUnit', value: '' }))
        if (!isConnected) {
          history.push('building')
        }
      },
      markerColour: levelMarkerColour,
    })),
  })

  const handleClick = () => {
    const facadeLength = block.length - 1
    if (activeFacade < facadeLength) {
      setFacade(activeFacade + 1)
      canvasRef?.current?.setCanvas()
    } else {
      setFacade(0)
      canvasRef?.current?.setCanvas()
    }
  }

  const handleFaceController = (facadeHash: string) => {
    if (facadeControl !== facadeHash) {
      handleClick()
      setFacadeControl(facadeHash)
    }
  }

  const handleLevelControl = (level: string, activeBlock: string) => {
    if (activeBlock) {
      if (
        block[activeFacade]?.polygons?.find(
          (res) => res.groupId === `${activeBlock}-${level}`
        )
      ) {
        canvasRef?.current?.artificialTrigger(`${activeBlock}-${level}`)
        return
      }
    }
    canvasRef?.current?.artificialTrigger(level)
  }

  const handleActiveBlockControl = (activeBlock: string) => {
    if (blockKeys.find((res) => res === activeBlock)) {
      setBlockFocus(activeBlock)
    }
  }

  React.useEffect(() => {
    if (!rendering) {
      canvasRef?.current?.setPolyActive()
      if (showLabels) {
        canvasRef?.current?.toggleLabels()
      }
    }
  }, [block, rendering])

  React.useEffect(() => {
    if (blockFocus !== prevReference.current?.blockFocus) {
      prevReference.current = {
        ...prevReference.current,
        blockFocus,
      }
      canvasRef?.current?.setCanvas()
    }
  }, [block])

  React.useEffect(() => {
    canvasRef?.current?.toggleLabels()
  }, [showLabels, canvasRef])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: {
          activeFacade: fireStoreActiveFacade,
          activeLevel: level,
          activeBlock,
          filterPopup,
          unitFilter: unitFilterFirestore,
          levelsShowLabels,
        },
      } = session
      if (connected) {
        toggleFilter(filterPopup)
        if (fireStoreActiveFacade && renderCanvas) {
          handleFaceController(fireStoreActiveFacade)
        }
        if (level && renderCanvas) {
          handleLevelControl(level, activeBlock)
        }
        setShowLabels(levelsShowLabels)
        handleActiveBlockControl(activeBlock)
        setIsConnected(connected)
        dispatch(setFilter(unitFilterFirestore))
      }
    }
  }, [session])

  React.useEffect(() => {
    if (block.length && blockFocus && blockKeys.length && levels.length) {
      setBlock(storeBlocks[blockFocus].map((res) => setInteractiveAction(res)))
    }
  }, [unitFilter, blockFocus])

  React.useEffect(() => {
    if (blockKeys.length && levels.length) {
      setBlock(storeBlocks[blockFocus].map((res) => setInteractiveAction(res)))
      return
    }
    history.push('area-view')
  }, [])

  React.useEffect(() => {
    if (blockFocus) {
      dispatch(setByFlag({ flag: 'activeBlock', value: blockFocus }))
    }
  }, [blockFocus])

  React.useEffect(() => {
    setRenderCanvas(true)
  }, [])

  return (
    <>
      <div
        className={`absolute left-5 top-5 z-20 ${
          isConnected ? 'invisible' : ''
        }`}
      >
        <IdleTimeHandler>
          <div className="flex items-center gap-5">
            <Link to="area-view">
              <ArrowSquareSvg size="m" className="drop-shadow-70" />
            </Link>
            <button
              data-testid="toggle-filter"
              onClick={() => toggleFilter(!isFilterOpen)}
              type="button"
            >
              <MenuToggleSvg size="m" className="drop-shadow-70" />
            </button>
            {blockKeys.length > 1 && (
              <DropDown
                id="blockDropdown"
                onSelect={(item: string) => setBlockFocus(item)}
                items={blockKeys}
                value={blockFocus}
                icon={<BuildingSvg className="h-8 w-8" />}
              />
            )}
          </div>
        </IdleTimeHandler>
      </div>
      <FilterPopup isOpen={isFilterOpen} toggle={toggleFilter} />
      <Container
        background={{
          url: block[activeFacade]?.image,
          type: 'new',
          noSpliceUrl: true,
        }}
      >
        <div ref={canvasContainerRef} className="relative m-auto h-full w-full">
          {canvasContainerRef && renderCanvas && block[activeFacade] && (
            <CanvasInteractive
              id="level-canvas"
              ref={canvasRef}
              canvasData={block[activeFacade]}
              parentRef={canvasContainerRef}
              hasLabel={!isConnected}
              labelPrefix="Level:"
              showLabels={showLabels}
              isRendering={setRendering}
            />
          )}
        </div>
      </Container>
      {block.length > 1 && (
        <div className="bottom-4% left-2% fixed z-50">
          <button
            type="button"
            className="flex h-16 w-16 items-center justify-center rounded-full border-2 border-white text-white drop-shadow-70"
            onClick={() => handleClick()}
          >
            <ArrowHorizontalSvg size="m" />
          </button>
        </div>
      )}
    </>
  )
}

export default connect(
  ({
    unitFilter,
    firestore,
    interactivePlan: { blocks },
    building: { levels, activeBlock, aspects },
    projectConfig: {
      showPrice,
      markerColour: { levels: levelMarkerColour },
      hideFilter,
    },
  }: RootStateFirebase) => ({
    unitFilter,
    session: getSession(firestore),
    blocks,
    storeActiveBlock: activeBlock,
    levels,
    aspects,
    showPrice,
    levelMarkerColour,
    hideFilter,
  })
)(LevelView)
