import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

interface RangeSliderProps {
  min: number
  max: number
  minValue: number
  maxValue: number
  onChangeSliderRange: (arg: { min: number; max: number }) => void
}

const Range = ({
  min,
  max,
  minValue,
  maxValue,
  onChangeSliderRange,
}: RangeSliderProps) => {
  const [minVal, setMinVal] = useState(minValue)
  const [maxVal, setMaxVal] = useState(maxValue)
  const minValRef = useRef<HTMLInputElement>(null)
  const maxValRef = useRef<HTMLInputElement>(null)
  const range = useRef<HTMLDivElement>(null)

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  const handleMillionLabel = (value: number) => {
    const suffixes = ['', 'K', 'M', 'B', 'T']
    const suffixNum = Math.floor(`${value}`.length / 3)
    let shortValue: string | number = parseFloat(
      (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(2)
    )
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1)
    }
    return shortValue + suffixes[suffixNum]
  }

  const setLabel = (value: number) => {
    if (value > 999999) {
      return handleMillionLabel(value)
    }

    return `${Math.ceil(value / 1000)}K`
  }

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal)
      const maxPercent = getPercent(+maxValRef.current.value)
      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minVal, getPercent])

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxVal)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxVal, getPercent])

  useEffect(() => {
    if (minVal !== minValue) {
      setMinVal(minValue)
    }
  }, [minValue])

  useEffect(() => {
    if (maxVal !== maxValue) {
      setMaxVal(maxValue)
    }
  }, [maxValue])

  useEffect(() => {
    if (minVal && maxVal) {
      onChangeSliderRange({ min: minVal, max: maxVal })
    }
  }, [minVal, maxVal])

  return (
    <div className="relative h-full px-4">
      <div className="slider h-full">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          ref={minValRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.min(+event.target.value, maxVal - 1)
            setMinVal(value)
          }}
          className={`thumb thumb--zindex-3 ${
            minVal > max - 100 ? 'thumb--zindex-5' : ''
          }`}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          ref={maxValRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.max(+event.target.value, minVal + 1)
            setMaxVal(value)
          }}
          className="thumb thumb--zindex-4"
        />
        <div className="slider__left-text">Min</div>
        <div className="slider__right-text">Max</div>
        <div className="slider__left-value">{setLabel(minVal)}</div>
        <div className="slider__right-value">{setLabel(maxVal)}</div>
        <div className="slider__track"></div>
        <div ref={range} className="slider__range bg-slider-range"></div>
      </div>
    </div>
  )
}

export default Range
