import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import {
  EnvisionVRConfigurationInterface,
  MarkerColourInterface,
  PriceRange,
  SnaploaderConfigurationInterface,
} from '@api/config'

export interface ConfigInterface {
  prices: PriceRange
  markerColour: MarkerColourInterface
  threeDViewerUrl: string
  longhand: boolean
  showPrice: boolean
  showCarouselIndicator: boolean
  carouselIndicatorLocation: string
  hideSoldPrice: boolean
  showcaseRefreshTriggerKey: string
  hideFilter: boolean
  sidePanelTitle: string
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
  unitPrefix: string
  unitGalleryControlStyle: string
  onlyShowUnitGalleries: boolean
  controls: {
    active: boolean
    duration: number
  }
  snaploaderConfiguration: SnaploaderConfigurationInterface
  envisionVRConfiguration: EnvisionVRConfigurationInterface
}

export interface ConfigInterfaceOptional {
  prices?: PriceRange
  markerColour?: MarkerColourInterface
  threeDViewerUrl?: string
  longhand?: boolean
  showPrice?: boolean
  showCarouselIndicator?: boolean
  carouselIndicatorLocation?: string
  hideSoldPrice?: boolean
  showcaseRefreshTriggerKey?: string
  hideFilter?: boolean
  sidePanelTitle?: string
  hideUnitInfoName?: boolean
  hideInfoPanelAspect?: boolean
  unitPrefix?: string
  unitGalleryControlStyle?: string
  onlyShowUnitGalleries?: boolean
  controls?: {
    active: boolean
    duration: number
  }
  snaploaderConfiguration?: SnaploaderConfigurationInterface
  envisionVRConfiguration?: EnvisionVRConfigurationInterface
}

const configDefault = {
  hideSoldPrice: false,
  longhand: false,
  showPrice: false,
  showCarouselIndicator: false,
  carouselIndicatorLocation: 'right',
  prices: {
    maxPrice: 0,
    minPrice: 0,
  },
  markerColour: {
    available: '#FFFFFF',
    sold: '#D14417',
    area: '#FFFFFF',
    levels: '#FFFFFF',
  },
  threeDViewerUrl: '',
  showcaseRefreshTriggerKey: '',
  hideFilter: false,
  sidePanelTitle: 'Unit',
  hideUnitInfoName: false,
  hideInfoPanelAspect: false,
  unitPrefix: 'Unit',
  unitGalleryControlStyle: 'thumbnail',
  onlyShowUnitGalleries: false,
  controls: {
    active: false,
    duration: 0,
  },
  snaploaderConfiguration: {
    active: false,
    sceneSnapId: '',
    sceneModelId: '',
  },
  envisionVRConfiguration: {
    active: false,
    url: '',
  },
}

let initialState = configDefault as ConfigInterface

const projectConfig = localStorage.getItem('projectConfig')

if (projectConfig) {
  initialState = JSON.parse(projectConfig)
}

const projectConfigSlice = createSlice({
  name: 'projectConfig',
  initialState,
  reducers: {
    setProjectConfig: (
      state: ConfigInterface,
      action: PayloadAction<ConfigInterfaceOptional>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, ...action.payload })
      )
      return { ...state, ...action.payload }
    },
    setPrices: (state: ConfigInterface, action: PayloadAction<PriceRange>) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, prices: action.payload })
      )
      return { ...state, prices: action.payload }
    },
    setMarkerColour: (
      state: ConfigInterface,
      action: PayloadAction<MarkerColourInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, markerColour: action.payload })
      )
      return { ...state, markerColour: action.payload }
    },
    setThreeDViewerUrl: (
      state: ConfigInterface,
      action: PayloadAction<string>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, threeDViewerUrl: action.payload })
      )
      return { ...state, threeDViewerUrl: action.payload }
    },
    setShowcaseRefreshTriggerKey: (
      state: ConfigInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        showcaseRefreshTriggerKey: action.payload,
      }
      localStorage.setItem('projectConfig', JSON.stringify(newState))
      return newState
    },
    setSnaploaderConfiguration: (
      state: ConfigInterface,
      action: PayloadAction<SnaploaderConfigurationInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, snaploaderConfiguration: action.payload })
      )
      return { ...state, snaploaderConfiguration: action.payload }
    },
    setEnvisionVRConfiguration: (
      state: ConfigInterface,
      action: PayloadAction<EnvisionVRConfigurationInterface>
    ) => {
      localStorage.setItem(
        'projectConfig',
        JSON.stringify({ ...state, envisionVRConfiguration: action.payload })
      )
      return { ...state, envisionVRConfiguration: action.payload }
    },
    removeProjectConfig: () => {
      localStorage.removeItem('projectConfig')
      return configDefault
    },
  },
})

export const {
  setProjectConfig,
  removeProjectConfig,
  setThreeDViewerUrl,
  setMarkerColour,
  setPrices,
  setShowcaseRefreshTriggerKey,
  setSnaploaderConfiguration,
  setEnvisionVRConfiguration,
} = projectConfigSlice.actions

export const selectProjectConfig = (state: RootStateTypeExtra) =>
  state.projectConfig

export default projectConfigSlice.reducer
