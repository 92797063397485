import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

export interface MarkerColourInterface {
  available: string
  sold: string
  area: string
  levels: string
}

export interface SnaploaderConfigurationInterface {
  active: boolean
  sceneSnapId: string
  sceneModelId: string
}

export interface EnvisionVRConfigurationInterface {
  active: boolean
  url: string
}

export interface ConfigData {
  longhand: boolean
  showPrice: boolean
  show_carousel_indicator: boolean
  carousel_indicator_location: string
  hide_sold_price: boolean
  hideFilter: boolean
  sidePanelTitle: string
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
  unitPrefix: string
  unitGalleryControlStyle: string
  onlyShowUnitGalleries: boolean
  unitGalleyTab: {
    active: boolean
    duration: number
  }
  snaploader: SnaploaderConfigurationInterface
}

export interface PriceRange {
  maxPrice: number
  minPrice: number
}

export interface ConfigPayload {
  data: {
    config: {
      project: ConfigData
    }
    prices: PriceRange
    markerColour: MarkerColourInterface
    inspace?: {
      url: string
      active: boolean
    }
    threeDViewer?: {
      url: string
      active: boolean
    }
    envisionVr?: EnvisionVRConfigurationInterface
  }
}

type ConfigType = {
  projectName: string
}

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigPayload, ConfigType>({
      query: (params: ConfigType) => ({
        url: `/v1/${params.projectName}/config`,
        method: 'get',
      }),
    }),
  }),
})

const mapConfigInfo = ({
  longhand,
  showPrice,
  show_carousel_indicator,
  carousel_indicator_location,
  hide_sold_price,
  hideFilter,
  sidePanelTitle,
  hideUnitInfoName,
  hideInfoPanelAspect,
  unitPrefix,
  unitGalleryControlStyle,
  onlyShowUnitGalleries,
  unitGalleyTab,
}: ConfigData) => ({
  longhand,
  showPrice,
  showCarouselIndicator: show_carousel_indicator,
  carouselIndicatorLocation: carousel_indicator_location,
  hideSoldPrice: hide_sold_price,
  hideFilter,
  sidePanelTitle,
  hideUnitInfoName,
  hideInfoPanelAspect,
  unitPrefix,
  unitGalleryControlStyle,
  onlyShowUnitGalleries,
  controls: unitGalleyTab,
})

const mapThreeDViewerConfig = (myData: any) => {
  if ('threeDViewer' in myData) {
    return myData.threeDViewer
  }
  return myData.inspace || {}
}

export const selectFromResult = ({
  data,
  isLoading,
  error,
}: UseQueryStateResult<any, any>) => ({
  configInfo: data?.data.config.project
    ? mapConfigInfo(data?.data.config.project)
    : {},
  prices: data?.data.prices || {},
  markerColour: data?.data.markerColour || {},
  threeDViewer: mapThreeDViewerConfig(data?.data || {}),
  snaploaderConfiguration: data?.data.config.project.snaploader || {},
  envisionVRConfiguration:
    data?.data.envisionVr || ({} as EnvisionVRConfigurationInterface),
  isLoaded: !isLoading,
  hasError: error !== undefined,
})

export const { useGetConfigQuery } = configApi
