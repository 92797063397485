import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { setExternalViews } from '@store/actionSlices/externalViews'
import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'

import {
  ExternalViewsData,
  selectFromResult,
  useGetExternalViewsQuery,
} from '@api/external-views'

import getSession from '@utilities/firebase-util'

import ExternalLinks from './external-links'

export interface ExternalViewsProps {
  projectName: string
  externalViews: Array<ExternalViewsData>
  session: SessionMap | undefined
}

const ExternalViews = ({
  projectName,
  externalViews,
  session,
}: ExternalViewsProps) => {
  const dispatch = useDispatch()

  const [selectedView, setSelectedView] = useState(0)

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName },
    { selectFromResult }
  )

  React.useEffect(() => {
    const { externalViewsData } = externalViewsPayload
    if (externalViews.length === 0 && externalViewsData.length > 0) {
      dispatch(setExternalViews(externalViewsData))
    }
  }, [externalViewsPayload, externalViews])

  React.useEffect(() => {
    if (session) {
      setSelectedView(session.externalView)
    }
  }, [session])

  return (
    <Container>
      <DataHandler
        payload={{
          ...externalViewsPayload,
          data: externalViews.length > 0,
        }}
      >
        <div className="absolute right-0 z-10">
          <ExternalLinks
            externalViews={externalViews}
            selectedView={selectedView}
            trigger={setSelectedView}
          />
        </div>
        <iframe
          className="z-0 h-full w-full overflow-hidden border-0"
          title={externalViews[selectedView]?.title}
          src={externalViews[selectedView]?.url}
          height="100%"
          width="100%"
        />
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    externalViews,
    firestore,
  }: RootStateFirebase) => ({
    projectName,
    externalViews,
    session: getSession(firestore),
  })
)(ExternalViews)
