import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getQueryStringParams } from '@utilities/helper'

export interface ThemeProps {
  font: string
  mainColour: string
  subColour: string
}

export default function styleUtil() {
  const location = useLocation()

  const storageKey = 'theme'

  const theme = localStorage.getItem(storageKey)

  const { themeParam, resetTheme } = getQueryStringParams(location.search)

  const { font, mainColour, secondaryColour } = themeParam
    ? JSON.parse(themeParam)
    : ''

  const handleColour = (colour: string) => {
    if (colour && colour.match(/[0-9A-Fa-f]{6}/g)) {
      return `#${colour}`
    }
    return colour
  }

  useEffect(() => {
    const style = document.getElementById('dynamic-theme')

    const dynamicStyle =
      (!resetTheme ? theme : undefined) ??
      `
      * {
        font-family: ${font ?? 'GT Walsheim Pro Regular'}, sans-serif;
      } 
      .bg-mainColour {
        background-color: ${handleColour(mainColour) ?? '#6D6D6D'};
      }
      .bg-secondaryColour {
        background-color: ${handleColour(secondaryColour) ?? '#515050'};
      }
    `

    if (font || mainColour || secondaryColour) {
      localStorage.setItem(storageKey, dynamicStyle)
    }

    if (style) {
      style.innerHTML = dynamicStyle
    }
  }, [])

  useEffect(() => {
    if (resetTheme) {
      localStorage.removeItem(storageKey)
    }
  }, [])
}
