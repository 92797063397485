import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { Level } from '@api/building'

import isOffline from '@utilities/offline-mode-util'

export interface BuildingInterface {
  activeUnit: string
  activeLevel: string
  activeBlock: string
  levels: Array<Level>
  aspects: Array<string>
}

let initialState = {
  activeUnit: '',
  activeLevel: '',
  activeBlock: '',
  levels: [],
  aspects: [],
} as BuildingInterface

const buildingData = localStorage.getItem('buildingApiData')

if (isOffline()) {
  if (buildingData) {
    initialState = JSON.parse(buildingData)
  }
}

const buildingSlice = createSlice({
  name: 'building',
  initialState,
  reducers: {
    setBuilding: (
      state: BuildingInterface,
      action: PayloadAction<BuildingInterface>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'buildingApiData',
          JSON.stringify({
            ...state,
            ...action.payload,
          })
        )
      }
      return {
        ...state,
        ...action.payload,
      }
    },
    setByFlag: (
      state: BuildingInterface,
      action: PayloadAction<{
        flag:
          | 'activeBlock'
          | 'activeLevel'
          | 'activeUnit'
          | 'levels'
          | 'aspects'
        value: string | Array<Level | string>
      }>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'buildingApiData',
          JSON.stringify({
            ...state,
            [action.payload.flag]: action.payload.value,
          })
        )
      }
      return {
        ...state,
        [action.payload.flag]: action.payload.value,
      }
    },
    resetBuilding: () => {
      localStorage.removeItem('buildingApiData')
      return {
        activeUnit: '',
        activeLevel: '',
        activeBlock: '',
        levels: [],
        aspects: [],
      }
    },
  },
})

export const { setBuilding, setByFlag, resetBuilding } = buildingSlice.actions

export const selectBuilding = (state: RootStateTypeExtra) => state.building

export default buildingSlice.reducer
