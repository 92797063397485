import { CarouselProvider } from 'pure-react-carousel'
import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setTeams } from '@store/actionSlices/teams'
import { RootStateFirebase, SessionMap } from '@store/types'

import DataHandler from '@components/data-handler'
import Footer from '@components/footer'
import Navigation from '@components/navigation'

import { TeamsInfo, selectFromResult, useGetTeamsQuery } from '@api/teams'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import getSession from '@utilities/firebase-util'

import SlideWrapper from './slide-wrapper'

export interface TeamsProps {
  session: SessionMap | undefined
  projectName: string
  teams: Array<TeamsInfo>
}

const Teams = ({ session, projectName, teams }: TeamsProps) => {
  const dispatch = useDispatch()
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [isConnected, setIsConnected] = React.useState(false)
  const [transitionTime, setTransitionTime] = React.useState(1000)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const teamsPayload = useGetTeamsQuery({ projectName }, { selectFromResult })

  React.useEffect(() => {
    const { teamsInfo } = teamsPayload
    if (teams.length === 0 && teamsInfo.length > 0) {
      dispatch(setTeams(teamsInfo))
    }
  }, [teamsPayload, teams])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        team: {
          galleryControl: {
            activeItemIndex: firebaseActiveItemIndex,
            transitionTime: transitionTimeFirebase,
            isPlaying: isPlayingFirebase,
          },
        },
      } = session
      setIsConnected(connected)
      setCurrentSlide(firebaseActiveItemIndex)
      setTransitionTime(transitionTimeFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  return (
    <div className="bg-mainColour overflow-hidden">
      <Navigation />
      <DataHandler
        payload={{
          ...teamsPayload,
          data: teams,
        }}
      >
        {teams.length > 0 && (
          <div className="grid grid-cols-3">
            <div
              className="background-cover col-span-2"
              style={{
                ...setBackground(
                  AssetHandler({
                    url: teams.filter((res) => res.type === 'background')[
                      currentSlide
                    ]?.imageSource,
                    type: 'legacy',
                  }),
                  { gradiant: 0 }
                ),
              }}
            ></div>
            <div className="col-span-1">
              <div className="mt-24">
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={125}
                  visibleSlides={1}
                  totalSlides={
                    teams.filter((res) => res.type === 'logo').length
                  }
                  currentSlide={currentSlide}
                  interval={transitionTime}
                  isPlaying={isSlideShowPlaying}
                >
                  <SlideWrapper
                    activeSlide={currentSlide}
                    isConnected={isConnected}
                    slideData={teams.filter((res) => res.type === 'logo')}
                    getActiveSlide={(arg: number) => {
                      setCurrentSlide(arg)
                    }}
                  />
                </CarouselProvider>
              </div>
              <div className="mx-12 mt-52 whitespace-pre-line text-white">
                {
                  teams.filter((res) => res.type === 'logo')[currentSlide]
                    ?.description
                }
              </div>
            </div>
          </div>
        )}
      </DataHandler>
      <Footer linkClasses={{ teamClass: 'underline' }} />
    </div>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    teams,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    teams,
  })
)(Teams)
