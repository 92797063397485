import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { Level } from '@api/building'
import { Shortlist } from '@api/shortlisted'

const shortlist = localStorage.getItem('shortlist')

let initialState = [] as Array<Shortlist>

if (shortlist) {
  initialState = JSON.parse(shortlist)
}

const shortlistSlice = createSlice({
  name: 'shortlist',
  initialState,
  reducers: {
    setShortlist: (
      state: Array<Shortlist>,
      action: PayloadAction<Shortlist>
    ) => {
      localStorage.setItem(
        'shortlist',
        JSON.stringify([...state, ...[action.payload]])
      )
      return [...state, ...[action.payload]]
    },
    setShortlistAll: (
      state: Array<Shortlist>,
      action: PayloadAction<Array<Shortlist>>
    ) => {
      localStorage.setItem(
        'shortlist',
        JSON.stringify([...state, ...[...action.payload]])
      )
      return [...state, ...[...action.payload]]
    },
    filterShortlist: (
      state: Array<Shortlist>,
      action: PayloadAction<Shortlist>
    ) => {
      const filteredShortlist: Array<Shortlist> = state.filter(
        (res: Shortlist) => action.payload.name !== res.name
      )
      localStorage.setItem('shortlist', JSON.stringify(filteredShortlist))
      return filteredShortlist
    },
    clearShortlist: (state: Array<Shortlist>, action: PayloadAction<Level>) => {
      const filteredShortlist: Array<Shortlist> = state.filter(
        (res: Shortlist) => action.payload.level !== res.level
      )
      localStorage.setItem('shortlist', JSON.stringify(filteredShortlist))
      return filteredShortlist
    },
    removeShortlist: () => {
      localStorage.removeItem('shortlist')
      return []
    },
  },
})

export const {
  setShortlist,
  setShortlistAll,
  filterShortlist,
  clearShortlist,
  removeShortlist,
} = shortlistSlice.actions

export const selectShortlist = (state: RootStateTypeExtra) => state.shortlist

export default shortlistSlice.reducer
