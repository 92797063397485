import React from 'react'

import { SvgProps } from '@components/svg/types'

const BookOpenSvg = ({ width, height, className, size, styles }: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 100 100"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="3"
    >
      <g transform="translate(0,-952.36218)">
        <path d="m 81.90625,962.38234 c -7.815863,0.65084 -14.874411,2.16153 -20.59375,5.49862 -5.112933,2.98326 -9.114106,7.47433 -11.5,13.8715 -1.240295,-2.36398 -2.999414,-4.11155 -5.125,-5.27992 -2.776183,-1.52598 -6.120044,-2.09322 -9.6875,-2.09322 -8.117886,0 -16.746194,1.9995 -25,1.9995 a 1.0001,0.99984797 0 0 0 -1,0.99974 l 0,60.98464 a 1.0001,0.99984797 0 0 0 1,0.9997 c 8.514959,0 17.92964,-3.999 27,-3.999 4.599836,0 9.417538,1.9212 12.125,6.4984 a 1.0001,0.99984797 0 0 0 1.75,0 c 2.707462,-4.5772 7.525164,-6.4984 12.125,-6.4984 9.07036,0 18.485041,3.999 27,3.999 a 1.0001,0.99984797 0 0 0 1,-0.9997 l 0,-60.98464 a 1.0001,0.99984797 0 0 0 -1,-0.99974 c -2.307942,0 -4.651072,-0.18173 -7,-0.40615 l 0,-12.59058 a 1.0001,0.99984797 0 0 0 -1.09375,-0.99975 z M 81,964.53805 81,1024.398 c -5.693788,0.029 -11.406008,0.2958 -16.75,2.2494 -4.87234,1.7813 -9.370292,5.1016 -13.25,10.5286 l 0,-52.61175 c 2.114806,-7.18799 6.040401,-11.83936 11.34375,-14.93373 5.056521,-2.95035 11.478444,-4.40157 18.65625,-5.09247 z m -46,11.84077 c 3.347611,0 6.350046,0.57251 8.71875,1.87452 2.368704,1.30202 4.156758,3.30258 5.1875,6.46712 A 1.0030471,1.0027943 0 0 0 49,984.90792 l 0,53.67398 c -3.198409,-3.5642 -7.703894,-5.2174 -12,-5.2174 -9.25121,0 -18.329643,3.6079 -26,3.9052 l 0,-58.95387 c 8.242038,-0.14357 16.519972,-1.93701 24,-1.93701 z m 48,1.62459 c 1.993806,0.17742 3.997309,0.27688 6,0.31242 l 0,58.95387 c -7.670357,-0.2973 -16.74879,-3.9052 -26,-3.9052 -2.827961,0 -5.73387,0.7154 -8.3125,2.2181 3.120599,-3.5316 6.553186,-5.7091 10.25,-7.0607 5.269748,-1.9265 11.089844,-2.1557 17.0625,-2.1557 a 1.0001,0.99984797 0 0 0 1,-0.9997 l 0,-47.36309 z"></path>
      </g>
    </svg>
  )
}

export default BookOpenSvg
