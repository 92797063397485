import React from 'react'

import { SvgProps } from '@components/svg/types'

const FloorplanSvg = ({
  height,
  width,
  className,
  size,
  styles,
  fill,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth >= 1024 ? 'xs' : 's'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '48'}
      height={height || '48'}
      fill="currentColor"
      viewBox="0 0 64 64"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path d="M 63.875 27.007 L 64 0 L 0 0 L 0 64 L 64 64 L 64 44.4 L 60 44.4 L 60 60 L 32.4 60 L 32.4 52.6 L 28.4 52.6 L 28.4 60 L 4 60 L 4 31.4 L 28.4 31.4 L 28.4 39.8 L 32.4 39.8 L 32.4 27.4 L 4 27.4 L 4 4 L 60 4 L 60 14.4 L 48 14.4 L 48 18.4 L 60 18.4 L 60 27 Z"></path>
    </svg>
  )
}

export default FloorplanSvg
