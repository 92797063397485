import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

export interface Layer {
  groupId: string
  label: string
  controlSrc: string
  src: string
  animation?: string
  visible: boolean
  controls: boolean
  type: string
  category: string
}

export interface Stack {
  src: string
  layers: Array<Layer>
}

export interface InteractiveMapPayload {
  data: Array<Stack>
}

type InteractiveMapType = {
  projectName: string
}

export const interactiveMapApi = createApi({
  reducerPath: 'interactiveMapApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getInteractiveMap: builder.query<InteractiveMapPayload, InteractiveMapType>(
      {
        query: (params: InteractiveMapType) => ({
          url: `/v1/${params.projectName}/interactive-map${roomQueryString()}`,
          method: 'get',
        }),
      }
    ),
  }),
})

const defaultStack = {
  src: '',
  layers: [],
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  stack: Object.keys(data?.data || {}).length > 0 ? data?.data : defaultStack,
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetInteractiveMapQuery } = interactiveMapApi
