import React from 'react'

import { SvgProps } from '@components/svg/types'

const HomeSvg = ({ className, size, styles, fill }: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{ ...styles, ...{ transform: `scale(${scale[sizeKey]})` } }}
      width="48px"
    >
      <path
        d="M 22 0 L 3 16.941 L 3 48 L 14.903 48 L 14.903 36.706 C 14.903 32.795 18.068 29.647 22 29.647 C 25.932 29.647 29.097 32.795 29.097 36.706 L 29.097 48 L 40 48 L 40 16.941 Z"
        fill={fill || 'hsl(0, 0%, 100%)'}
      ></path>
    </svg>
  )
}

export default HomeSvg
