import { Dot } from 'pure-react-carousel'
import React from 'react'

import { TextInterface } from '@store/types'

export interface TextDotInterface {
  item: TextInterface
  index: number
  currentSlide: number
  setCurrentSlide: (arg: number) => void
}

const TextDot = ({
  item,
  index,
  currentSlide,
  setCurrentSlide,
}: TextDotInterface) => (
  <Dot
    className={`my-2 w-auto text-white ${
      currentSlide === index ? '' : ' opacity-50'
    }`}
    slide={index}
    onClick={() => setCurrentSlide(index)}
  >
    <div className="flex transform items-center transition duration-300 ease-in-out hover:scale-110">
      {item.subText !== '' && (
        <span
          className={`mr-4 ${
            currentSlide === index ? 'text-base' : ' text-sm'
          }`}
        >
          {item.subText}
        </span>
      )}
      <span className={currentSlide === index ? 'text-3xl' : ' text-xl'}>
        {item.text}
      </span>
    </div>
  </Dot>
)

export default TextDot
