import { fabric } from 'fabric'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import AssetHandler from '@utilities/asset-handler'
import getWindowDimensions from '@utilities/window-dimensions'

export interface Layer {
  src: string
  visible: boolean
  controls: boolean
  groupId: string
  type: string
  animation?: string
}

export interface Stack {
  src: string
  layers: Array<Layer>
}

export interface CustomObject extends fabric.Object {
  groupId?: string
}

export interface CustomImage extends fabric.Image {
  groupId?: string
}

export interface CanvasProps {
  id: string
  parentRef?: React.RefObject<HTMLDivElement>
  staticWidth?: number
  staticHeight?: number
  background: string
  layers: Array<Layer>
  ratio?: string
}

export interface CanvasRefInterface {
  setCanvas(): void
}

export const TYPE_ANIMATION = 'animation'
export const TYPE_STATIC = 'static'
export const TYPE_MARKER = 'marker'

const CanvasStack = forwardRef<CanvasRefInterface | undefined, CanvasProps>(
  (
    {
      id,
      parentRef,
      staticWidth = 0,
      staticHeight = 0,
      ratio = 'max',
      background,
      layers,
    },
    ref
  ) => {
    const fabricRef = React.useRef<fabric.Canvas | null>(null)
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null)
    const [bgWidth, setBgWidth] = React.useState(0)

    const windowDimensions = getWindowDimensions()
    const [timeoutIdScreenChange, setTimeoutIdScreenChange] =
      useState<NodeJS.Timeout>()

    const initCanvas = () => {
      const canvasWrapper = parentRef?.current?.parentElement
      fabric.Object.prototype.objectCaching = false
      fabricRef.current = new fabric.Canvas(canvasRef?.current, {
        width: canvasWrapper?.clientWidth || staticWidth,
        height: canvasWrapper?.clientHeight || staticHeight,
        selection: false,
        preserveObjectStacking: true,
        perPixelTargetFind: true,
        hoverCursor: 'pointer',
      })
    }

    const drawBackground = (src: string) => {
      fabric.Image.fromURL(
        AssetHandler({
          url: src,
          type: 'new',
          noSpliceUrl: true,
        }),
        (oImg) => {
          const widthRatio =
            (fabricRef.current?.width || 0) / (oImg?.width || 0)
          const heightRatio =
            (fabricRef.current?.height || 0) / (oImg?.height || 0)
          const maxRatio = Math.max(widthRatio, heightRatio)
          const minRatio = Math.min(widthRatio, heightRatio)

          if (heightRatio > widthRatio) {
            oImg.scale(ratio === 'max' ? maxRatio : minRatio)
          } else {
            oImg.set({
              scaleX: widthRatio,
              scaleY: heightRatio,
              noScaleCache: false,
            })
          }
          oImg.set({
            hasBorders: false,
            hasControls: false,
            selectable: false,
            objectCaching: false,
          })
          setBgWidth(Math.round(oImg.getScaledWidth() || 0))
          fabricRef.current?.setBackgroundImage(
            oImg,
            fabricRef.current?.renderAll.bind(fabricRef.current)
          )
        },
        { crossOrigin: 'Anonymous' }
      )
    }

    const drawImages = (layer: Layer) => {
      fabric.Image.fromURL(
        AssetHandler({
          url: layer.src,
          type: 'new',
          noSpliceUrl: true,
        }),
        (oImg: CustomImage) => {
          const widthRatio =
            (fabricRef.current?.width || 0) / (oImg?.width || 0)
          const heightRatio =
            (fabricRef.current?.height || 0) / (oImg?.height || 0)
          const maxRatio = Math.max(widthRatio, heightRatio)
          const minRatio = Math.min(widthRatio, heightRatio)

          if (heightRatio > widthRatio) {
            oImg.scale(ratio === 'max' ? maxRatio : minRatio)
          } else {
            oImg.set({
              scaleX: widthRatio,
              scaleY: heightRatio,
              noScaleCache: false,
            })
          }
          oImg.set({
            hasBorders: false,
            hasControls: false,
            selectable: false,
            objectCaching: false,
            opacity: layer.visible ? 1 : 0,
            groupId: layer.groupId,
          })
          fabricRef.current?.add(oImg)
          fabricRef?.current?.renderAll()
        },
        { crossOrigin: 'Anonymous' }
      )
    }

    const setCanvasSize = () => {
      if (fabricRef.current && parentRef) {
        const canvasWrapper = parentRef?.current?.parentElement
        fabricRef.current?.setWidth(canvasWrapper?.clientWidth || staticWidth)
        fabricRef.current?.setHeight(
          canvasWrapper?.clientHeight || staticHeight
        )
        fabricRef.current?.renderAll()
      }
    }

    const cleanObjects = () => {
      if (fabricRef.current) {
        fabricRef.current
          .getObjects()
          .forEach((obj) => fabricRef?.current?.remove(obj))
      }
    }

    const setCanvas = () => {
      cleanObjects()
      setCanvasSize()
      drawBackground(background)
      layers
        .filter((res) => res.type.toLowerCase() === TYPE_STATIC)
        .forEach((lyr: Layer) => drawImages(lyr))
    }

    const updateLayers = (layersToUpdate: Array<Layer>) => {
      layersToUpdate.forEach((lyr) => {
        const obj = fabricRef?.current
          ?.getObjects()
          .find((img: CustomObject) => {
            if (img.type === 'image' && img.groupId === lyr.groupId) {
              return img
            }
            return null
          })
        if (obj) {
          obj.animate(
            {
              opacity: lyr.visible ? 1 : 0,
            },
            {
              onChange: () => {
                fabricRef?.current?.renderAll()
              },
              duration: 500,
              easing: fabric.util.ease.easeInSine,
            }
          )
        }
      })
    }

    useImperativeHandle(ref, () => ({
      setCanvas,
    }))

    useEffect(() => {
      if (timeoutIdScreenChange) {
        clearTimeout(timeoutIdScreenChange)
      }
      const timeoutId = setTimeout(() => {
        setCanvas()
      }, 100)
      setTimeoutIdScreenChange(timeoutId)
    }, [windowDimensions])

    React.useLayoutEffect(() => {
      updateLayers(layers)
    }, [layers])

    React.useLayoutEffect(() => {
      initCanvas()
      setCanvas()
    }, [])

    return (
      <div>
        <canvas ref={canvasRef} key={id} />
        {layers
          .filter((an) => an.visible && an.type === TYPE_ANIMATION)
          .map((anim) => (
            <div key={anim.groupId}>
              {anim.src && (
                <img
                  alt={anim.src}
                  className="absolute left-0 top-0 h-screen z-5"
                  style={{
                    minWidth: `${bgWidth}px`,
                  }}
                  src={AssetHandler({
                    url: anim.src || '',
                    type: 'new',
                    noSpliceUrl: true,
                  })}
                />
              )}
              {anim.animation && (
                <img
                  alt={anim.animation}
                  className="absolute left-0 top-0 h-screen z-5"
                  style={{
                    minWidth: `${bgWidth}px`,
                  }}
                  src={AssetHandler({
                    url: anim.animation || '',
                    type: 'new',
                    noSpliceUrl: true,
                  })}
                />
              )}
            </div>
          ))}
        {layers
          .filter((mark) => mark.visible && mark.type === TYPE_MARKER)
          .map((marker) => (
            <img
              key={marker.groupId}
              alt={marker.src}
              className="absolute left-0 top-0 h-screen z-5"
              style={{
                minWidth: `${bgWidth}px`,
              }}
              src={AssetHandler({
                url: marker.src || '',
                type: 'new',
                noSpliceUrl: true,
              })}
            />
          ))}
      </div>
    )
  }
)

export default CanvasStack
