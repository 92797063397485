import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

type ParamType = {
  projectName: string
}

export const clearCacheApi = createApi({
  reducerPath: 'clearCacheApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    clearMatrixCache: builder.mutation<{ data: string }, ParamType>({
      query: (params: ParamType) => ({
        url: `/v1/${params.projectName}/clear-cache-matrix`,
        method: 'PATCH',
        body: {
          ...params,
        },
      }),
    }),
  }),
})

export const { useClearMatrixCacheMutation } = clearCacheApi
