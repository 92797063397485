import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

export interface Point {
  x: number
  y: number
}

export interface Size {
  width: number
  height: number
}

export interface Polygon {
  label?: string
  groupId: string
  color: string
  points: Array<Point>
  coordinates: Point
  markerCoordinates: Point
  size: Size
  angle?: number
  rectAngle?: number
  rectCoordinates?: Point
  polyCoordinates?: Point
  markerSize?: Size
  polySize?: Size
  rectSize?: Size
  type?: 'numbered' | 'badge'
  noPolyHover?: boolean
}

export interface MapContent {
  id?: string
  image: string
  showcaseRoom?: string
  polygons: Array<Polygon>
}

export interface MappingCollection {
  [index: string]: MapContent
}

export interface MappingBlockCollection {
  [index: string]: Array<MapContent>
}

export interface InteractivePlanData {
  projectId?: string
  areaView: MapContent
  blocks: MappingBlockCollection
  floorplan: MappingCollection
}

export interface InteractivePlanPayload {
  data: InteractivePlanData
}

type InteractivePlanType = {
  projectName: string
}

export const interactivePlanApi = createApi({
  reducerPath: 'interactivePlanApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getInteractivePlan: builder.query<
      InteractivePlanPayload,
      InteractivePlanType
    >({
      query: (params: InteractivePlanType) => ({
        url: `/v1/${params.projectName}/interactive-plan${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  maps: data?.data || {},
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetInteractivePlanQuery } = interactivePlanApi
