import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { Token, User } from '@store/types'

export interface Project {
  id: string
  name: string
  canonical_name: string
}

export interface AuthPayload {
  data: {
    user: User | TokenPayloadInterface
    project: Project
  }
}

export interface RefreshTokenPayloadInterface {
  data: {
    user: User | TokenPayloadInterface
  }
}

type AuthType = {
  project_canonical_name: string
  password: string
  email: string
}

type AuthToken = {
  token: string
  projectId: string
}

type RefreshTokenType = {
  refresh_token: string
}

const baseUrl = process.env.REACT_APP_API_URL
const client_id = process.env.REACT_APP_CLIENT_ID
const client_secret = process.env.REACT_APP_CLIENT_SECRET

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    authUser: builder.mutation<AuthPayload, AuthType>({
      query: (params: AuthType) => ({
        url: '/v1/user/login',
        method: 'post',
        body: {
          ...{
            client_id,
            client_secret,
          },
          ...params,
        },
      }),
    }),
    authViaToken: builder.mutation<AuthPayload, AuthToken>({
      query: ({ token, projectId }: AuthToken) => ({
        url: '/v1/user/token/auth',
        method: 'post',
        body: {
          ...{
            client_id,
            client_secret,
          },
          ...{ auth_token: token, project_canonical_name: projectId },
        },
      }),
    }),
    renewUserToken: builder.mutation<
      RefreshTokenPayloadInterface,
      RefreshTokenType
    >({
      query: (params: RefreshTokenType) => ({
        url: '/v1/user/refresh-token',
        method: 'post',
        body: {
          ...{
            client_id,
            client_secret,
          },
          ...params,
        },
      }),
    }),
  }),
})

export const {
  useAuthUserMutation,
  useAuthViaTokenMutation,
  useRenewUserTokenMutation,
} = userApi
