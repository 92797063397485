import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setShowcaseRefreshTriggerKey } from '@store/actionSlices/projectConfig'
import { setReleaseVersion } from '@store/actionSlices/releaseInfo'
import {
  ReleaseVersionInterface,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import ConfirmationModal from '@components/modals'

import getSession from '@utilities/firebase-util'

export interface ComponentProps {
  showcaseReleaseInfoFirebase: ReleaseVersionInterface
  session: SessionMap | undefined
  currentShowcaseVersion: string
  showcaseRefreshTriggerKey: string
}

const ReleaseHandler = ({
  showcaseReleaseInfoFirebase,
  session,
  currentShowcaseVersion,
  showcaseRefreshTriggerKey,
}: ComponentProps) => {
  const dispatch = useDispatch()

  const [connected, setConnected] = React.useState(false)
  const [
    initialVersionUpdatedInLocalState,
    setInitialVersionUpdatedInLocalState,
  ] = React.useState(false)

  const [showVersionReleaseModal, toggleVersionReleaseModal] =
    React.useState(false)
  const [versionReleaseMessage, setVersionReleaseMessage] = React.useState('')

  const refresh = () => window.location.reload()

  React.useEffect(() => {
    dispatch(setReleaseVersion(showcaseReleaseInfoFirebase.version))
    setInitialVersionUpdatedInLocalState(true)
  }, [])

  React.useEffect(() => {
    if (!connected && initialVersionUpdatedInLocalState) {
      const { version: currentShowcaseVersionFirebase } =
        showcaseReleaseInfoFirebase

      if (
        currentShowcaseVersion &&
        currentShowcaseVersionFirebase !== currentShowcaseVersion
      ) {
        setVersionReleaseMessage(
          `A new version (${currentShowcaseVersionFirebase}) of showcase has been released.`
        )
        toggleVersionReleaseModal(true)
      }
    }
  }, [showcaseReleaseInfoFirebase])

  const refreshAppOnRefreshTriggerKeyChange = (
    showcaseRefreshTriggerKeyFirebase: string
  ) => {
    if (
      showcaseRefreshTriggerKeyFirebase &&
      showcaseRefreshTriggerKeyFirebase !== showcaseRefreshTriggerKey
    ) {
      dispatch(setShowcaseRefreshTriggerKey(showcaseRefreshTriggerKeyFirebase))
      refresh()
    }
  }

  React.useEffect(() => {
    if (session) {
      const {
        connected: connectedFirebase,
        showcaseRefreshTriggerKey: showcaseRefreshTriggerKeyFirebase,
      } = session

      setConnected(connectedFirebase)
      refreshAppOnRefreshTriggerKeyChange(showcaseRefreshTriggerKeyFirebase)
    }
  }, [session])

  return (
    <ConfirmationModal
      isVisible={showVersionReleaseModal}
      toggleModal={(toggle) => toggleVersionReleaseModal(toggle)}
      getModalAction={refresh}
      title="Update Available"
      message={versionReleaseMessage}
      showCancelButton={false}
      showCloseOption={false}
      okButtonText="Refresh Showcase"
    />
  )
}

export default connect(
  ({
    firestore,
    releaseInfo: { version: currentShowcaseVersion },
    projectConfig: { showcaseRefreshTriggerKey },
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    currentShowcaseVersion,
    showcaseRefreshTriggerKey,
  })
)(ReleaseHandler)
