import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface GalleryData {
  imageSource: string
  imageName: string
}

export type GalleryCollection = {
  (key: 'string'): Array<GalleryData>
}

export interface DesignPayload extends PayloadInterface {
  data: GalleryCollection
}

type DesignType = {
  projectName: string
}

export const designApi = createApi({
  reducerPath: 'designApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getDesign: builder.query<DesignPayload, DesignType>({
      query: (params: DesignType) => ({
        url: `/v1/${params.projectName}/design${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processGalleryData = (data: Array<GalleryData>) => {
  const gallery: Array<any> = []
  Object.keys(data).forEach((value: any) => {
    gallery.push({
      items: data[value] ?? [],
      label: value.charAt(0).toUpperCase() + value.slice(1),
    })
  })
  return gallery
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  gallery: processGalleryData(data?.data || []).filter(
    (res: any) => res.items.length > 0
  ),
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetDesignQuery } = designApi
