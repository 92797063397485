import { Dot } from 'pure-react-carousel'
import React from 'react'

import { ThumbnailInterface } from '@store/types'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import { generateId } from '@utilities/generate-id-util'

export interface ThumbnailDotInterface {
  item: ThumbnailInterface
  index: number
  currentSlide: number
  setCurrentSlide: (arg: number) => void
}

const ThumbnailDot = ({
  item,
  index,
  currentSlide,
  setCurrentSlide,
}: ThumbnailDotInterface) => (
  <div className="grid justify-items-center">
    <Dot
      className={`m-2 h-14 w-14 rounded-lg bg-transparent transition-size drop-shadow-40 ${
        currentSlide === index ? 'h-18 w-18' : ''
      }`}
      slide={index}
      key={generateId(10)}
      onClick={() => setCurrentSlide(index)}
    >
      <div
        className="relative h-full w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
        style={{
          ...setBackground(
            AssetHandler({
              url: item.src,
              type: item.type,
              noSpliceUrl: item.noSpliceUrl,
            }),
            {
              gradiant: item.gradiant ?? 0,
            }
          ),
        }}
      >
        <div
          className={`absolute left-0 top-0 h-full w-full rounded-lg bg-black transition-opacity ease-in-out ${
            currentSlide !== index ? 'opacity-50' : 'opacity-0'
          }`}
        ></div>
      </div>
    </Dot>
  </div>
)

export default ThumbnailDot
