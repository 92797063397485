import React from 'react'
import ScriptTag from 'react-script-tag'

import AssetHandler from '@utilities/asset-handler'

import './pannellum.css'
import {
  PannellumDataInterface,
  PannellumHotspotInterface,
  PannellumSceneInterface,
  PannellumViewRefInterface,
} from './types'

declare global {
  interface Window {
    pannellum: any
  }
}

interface PannellumViewPropInterface {
  payload: Array<PannellumDataInterface>
  defaultScene?: string
  sceneFadeDuration?: number
  autoLoad?: boolean
  showControls?: boolean
  className?: string
}

const PannellumView = React.forwardRef<
  PannellumViewRefInterface | undefined,
  PannellumViewPropInterface
>(
  (
    {
      payload,
      defaultScene = '',
      sceneFadeDuration = 1000,
      autoLoad = true,
      showControls = false,
      className = 'h-screen w-screen',
    },
    ref
  ) => {
    const viewerRef = React.useRef<any>()
    const [pannellumSDKLoadError, setPannellumSDKLoadError] =
      React.useState(false)

    const handlePannellumSDKOnError = () => {
      setPannellumSDKLoadError(true)
      console.error('Pannellum SDK failed to load!')
    }

    const buildAssetUrl = (url = '') =>
      url !== '' ? AssetHandler({ url, type: 'new' }) : ''

    const handlePannellumSDKOnLoad = () => {
      const scenes: { [key: string]: PannellumSceneInterface } = {}
      payload.forEach((data: PannellumDataInterface) => {
        const { name, label, pitch, yaw, image, hotspots } = data

        scenes[name] = {
          title: label || name,
          pitch,
          yaw,
          type: 'equirectangular',
          panorama: buildAssetUrl(image),
          hotSpots: hotspots.map((hotspot: PannellumHotspotInterface) => ({
            ...hotspot,
          })),
        }
        return data
      })
      const firstScene = defaultScene || payload[0].name || ''
      viewerRef.current = window.pannellum.viewer('panorama-view', {
        sceneFadeDuration,
        autoLoad,
        showControls,
        default: {
          firstScene,
          sceneFadeDuration,
        },
        scenes,
      })
    }

    const setPitch = (value: number) => {
      if (viewerRef?.current) {
        viewerRef?.current?.setPitch(value)
      }
    }

    const setYaw = (value: number) => {
      if (viewerRef?.current) {
        viewerRef?.current?.setYaw(value)
      }
    }

    const startAutoRotate = (value: number) => {
      if (viewerRef?.current) {
        viewerRef?.current?.startAutoRotate(value)
      }
    }

    const stopAutoRotate = () => {
      if (viewerRef?.current) {
        viewerRef?.current?.stopAutoRotate()
      }
    }

    const loadScene = (sceneId: string) => {
      if (viewerRef?.current) {
        viewerRef?.current?.loadScene(sceneId)
      }
    }

    React.useImperativeHandle(ref, () => ({
      setPitch,
      setYaw,
      startAutoRotate,
      stopAutoRotate,
      loadScene,
    }))

    return (
      <>
        <ScriptTag
          type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js"
          onLoad={handlePannellumSDKOnLoad}
          onError={handlePannellumSDKOnError}
        />
        {pannellumSDKLoadError ? (
          <p>Failed to load Pannellum SDK</p>
        ) : (
          <div className={className} id="panorama-view"></div>
        )}
      </>
    )
  }
)

export default PannellumView
