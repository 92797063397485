import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setVision } from '@store/actionSlices/vision'
import { RootStateFirebase, SessionMap } from '@store/types'

import DataHandler from '@components/data-handler'
import Footer from '@components/footer'
import { GalleryProvider } from '@components/gallery-handler'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import Navigation from '@components/navigation'

import { VisionData, selectFromResult, useGetVisionQuery } from '@api/vision'

import getSession from '@utilities/firebase-util'

import CarouselContent from './carousel-content'

export interface VisionProps {
  projectName: string
  vision: VisionData
  session: SessionMap | undefined
}

const Vision = ({ projectName, vision, session }: VisionProps) => {
  const dispatch = useDispatch()

  const isAppIdle = useIdleTimeHandler()

  const [ctrlsHidden, setCtrlsVisibility] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  const visionPayload = useGetVisionQuery({ projectName }, { selectFromResult })

  React.useEffect(() => {
    const { gallery, title } = visionPayload
    if (vision.gallery.length === 0 && gallery.length > 0) {
      dispatch(
        setVision({
          gallery,
          title,
        })
      )
    }
  }, [visionPayload])

  return (
    <div className="bg-mainColour overflow-hidden">
      <Navigation hasOptions isVisible={!ctrlsHidden} />
      <DataHandler
        payload={{
          ...visionPayload,
          data: vision.gallery,
        }}
      >
        <GalleryProvider
          galleryName="vision"
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          dragEnabled={false}
          disableKeyboard
          className="ipad-p:w-screen-lg"
          originalSlides={vision.gallery}
          galleryControl={session?.vision?.galleryControl}
        >
          {(slideContent) => (
            <CarouselContent
              slideContent={slideContent}
              ctrlsHidden={isConnected || isAppIdle ? true : ctrlsHidden}
              getCtrlVisibility={setCtrlsVisibility}
              transitionType={session?.vision?.galleryControl?.transitionType}
            />
          )}
        </GalleryProvider>
      </DataHandler>
      <Footer isVisible={!ctrlsHidden} />
    </div>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    vision,
    firestore,
  }: RootStateFirebase) => ({
    projectName,
    vision,
    session: getSession(firestore),
  })
)(Vision)
