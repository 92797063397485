import { FireStoreMap, ProjectIdentity, SessionMap } from '@store/types'

const getSession = (firestore: FireStoreMap) => {
  const { status } = firestore
  const { devsuite } = firestore?.data ?? {}
  if (devsuite && status.requested.devsuite) {
    return devsuite
  }
  return undefined
}

export default getSession
