import React from 'react'

import { SvgProps } from '@components/svg/types'

const PowderSvg = ({
  width,
  height,
  className,
  styles,
  fill,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{
      ...styles,
    }}
    width={width || '48'}
    height={height || '48'}
    fill={fill || 'currentColor'}
    stroke={strokeColor || 'currentColor'}
    viewBox="0 0 512 512"
    strokeWidth={stroke || 1}
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <g>
      <path d="M 511.5,249.5 C 511.5,322.167 511.5,394.833 511.5,467.5C 510.028,469.134 508.362,470.634 506.5,472C 339.167,472.667 171.833,472.667 4.5,472C 2.63834,470.634 0.971672,469.134 -0.5,467.5C -0.5,394.833 -0.5,322.167 -0.5,249.5C 0.971672,247.866 2.63834,246.366 4.5,245C 52.8333,244.667 101.167,244.333 149.5,244C 126.813,224.445 116.313,199.611 118,169.5C 119.167,167.667 120.667,166.167 122.5,165C 163.499,164.5 204.499,164.333 245.5,164.5C 245.667,138.498 245.5,112.498 245,86.5C 242.128,66.4768 230.628,57.6435 210.5,60C 200.667,63.1667 194.167,69.6667 191,79.5C 190.184,88.568 189.184,97.568 188,106.5C 183.8,110.714 178.966,111.548 173.5,109C 171.951,107.786 170.785,106.286 170,104.5C 164.126,76.7187 173.292,55.8853 197.5,42C 225.829,32.6598 247.329,40.8264 262,66.5C 263.202,69.7735 264.202,73.1069 265,76.5C 265.5,105.831 265.667,135.165 265.5,164.5C 306.501,164.333 347.501,164.5 388.5,165C 390.333,166.167 391.833,167.667 393,169.5C 394.685,199.616 384.185,224.449 361.5,244C 409.833,244.333 458.167,244.667 506.5,245C 508.362,246.366 510.028,247.866 511.5,249.5 Z M 138.5,185.5 C 216.332,184.334 294.332,184.168 372.5,185C 367.475,210.364 353.141,228.364 329.5,239C 324.625,240.802 319.625,242.135 314.5,243C 294.857,244.099 275.191,244.599 255.5,244.5C 235.809,244.599 216.143,244.099 196.5,243C 172.234,238.282 154.734,224.782 144,202.5C 141.682,196.863 139.849,191.197 138.5,185.5 Z M 19.5,264.5 C 94.8333,264.5 170.167,264.5 245.5,264.5C 245.5,327.167 245.5,389.833 245.5,452.5C 170.167,452.5 94.8333,452.5 19.5,452.5C 19.5,389.833 19.5,327.167 19.5,264.5 Z M 265.5,264.5 C 340.833,264.5 416.167,264.5 491.5,264.5C 491.5,327.167 491.5,389.833 491.5,452.5C 416.167,452.5 340.833,452.5 265.5,452.5C 265.5,389.833 265.5,327.167 265.5,264.5 Z" />
    </g>
    <g>
      <path d="M 193.5,330.5 C 202.437,328.555 208.937,331.555 213,339.5C 213.667,352.167 213.667,364.833 213,377.5C 208.212,386.413 201.045,388.913 191.5,385C 187.675,382.512 185.508,379.012 185,374.5C 184.333,363.833 184.333,353.167 185,342.5C 186.107,337.234 188.94,333.234 193.5,330.5 Z" />
    </g>
    <g>
      <path d="M 306.5,330.5 C 316.948,328.3 323.448,332.3 326,342.5C 326.667,353.167 326.667,363.833 326,374.5C 322.03,386.741 314.197,389.908 302.5,384C 300.383,382.269 298.883,380.102 298,377.5C 297.333,364.833 297.333,352.167 298,339.5C 299.688,335.315 302.521,332.315 306.5,330.5 Z" />
    </g>
  </svg>
)

export default PowderSvg
