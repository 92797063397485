const getScaledAttribute = (attrib: number, scaledSize: number) =>
  scaledSize * (attrib / 100)

const getRelativePostition = (
  mainCoordinate: number,
  relativeCoordinate: number,
  size: number,
  scaledSize: number
) =>
  getScaledAttribute(mainCoordinate, scaledSize) +
  (getScaledAttribute(relativeCoordinate, scaledSize) +
    getScaledAttribute(size, scaledSize) / 2)

export { getScaledAttribute, getRelativePostition }
