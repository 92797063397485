import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import { setFilter } from '@store/actionSlices/unitFilter'
import {
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
  UnitFilterInterface,
} from '@store/types'

import DataHandler from '@components/data-handler'
import { DataNotFound } from '@components/data-handler/errors'
import FilterPopup from '@components/filter-popup/filter-popup'
import IdleTimeHandler from '@components/idle-time-handler'
import { ArrowSquareSvg, MenuToggleSvg } from '@components/svg'

import {
  Level,
  Unit,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import { EnvisionVRConfigurationInterface } from '@api/config'
import {
  InteractivePlanData,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'
import { hasEnvisionVR } from '@utilities/helper'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

interface ComponentProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  hideFilter: boolean
  showPrice: boolean
  interactivePlan: InteractivePlanData
  building: BuildingInterface
  unitFilter: UnitFilterInterface
}
const EnvisionVR = ({
  session,
  projectIdentity,
  envisionVRConfiguration,
  hideFilter,
  showPrice,
  interactivePlan,
  building,
  unitFilter,
}: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const history = useHistory()
  const dispatch = useDispatch()

  const INITIAL_ZOOM_VALUE = 10

  const iframeRef = React.useRef() as React.MutableRefObject<HTMLIFrameElement>

  const [isRemoteAppConnected, setRemoteAppConnectionState] =
    React.useState(false)
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [isModelLoaded, setModelLoadState] = React.useState(false)
  const [levelList, setLevelList] = React.useState<Array<string>>([])
  const [activeBuilding, setActiveBuilding] = React.useState('')
  const [isLevelHighlighted, setLevelHighlightState] = React.useState(false)
  const [rotateCameraByTriggerKey, setRotateCameraByTrigger] =
    React.useState('')
  const [zoomCameraByTriggerKey, setZoomCameraByTrigger] = React.useState('')
  const [currentZoomValue, setCurrentZoomValue] =
    React.useState(INITIAL_ZOOM_VALUE)

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult }
  )
  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const callUnityFunction = (functionName: string, parameters: any) => {
    if (!iframeRef.current?.contentWindow) return
    const eventData = {
      eventType: 'call',
      payload: {
        functionName,
        parameters,
      },
    }
    iframeRef.current.contentWindow.postMessage(eventData, '*')
  }

  const handleGetBuildingIDsCallback = (values: string) => {
    try {
      firebaseControlQuery.update({
        [`envisionVR.isLoaded`]: true,
        [`envisionVR.buildings.isLoaded`]: true,
        [`envisionVR.buildings.data`]: JSON.parse(values).Value || [],
        [`envisionVR.activeBuilding`]: '',
        [`envisionVR.levels.isLoaded`]: false,
        [`envisionVR.levels.data`]: [],
      })
    } catch (errro) {
      console.error('Building data load error')
    }
  }

  const handleGetLevelsCallback = (values: any) => {
    try {
      if (session) {
        const myLevels = JSON.parse(values).Value || []
        setLevelList(myLevels)
        firebaseControlQuery.update({
          [`envisionVR.levels.isLoaded`]: true,
          [`envisionVR.levels.data`]: myLevels,
        })
      }
    } catch (errro) {
      console.error('Level data load error')
    }
  }

  const getCorrectBlockValue = (argBlock: string, argLevel: string): string => {
    const blockList = Object.keys(interactivePlan.blocks || {})

    const isMultiBlockProject = blockList.length > 1

    if (!isMultiBlockProject || argBlock === '') return ''

    const level = building.levels.find((lvl: Level) => argLevel === lvl.level)

    if (!level) return ''

    const unit = level.data.find((unt: Unit) => unt.blockId === argBlock)

    if (!unit) return ''

    return unit.blockId || ''
  }

  const handleLevelClick = (data: any) => {
    const obj = JSON.parse(data)
    if (!obj.Level) return

    const thisLevel = obj.Level
    const thisBlock = obj.BuildingID || ''

    dispatch(
      setByFlag({
        flag: 'activeBlock',
        value: getCorrectBlockValue(thisBlock, thisLevel),
      })
    )

    dispatch(
      setByFlag({
        flag: 'activeLevel',
        value: thisLevel,
      })
    )
    history.push('building')
  }

  const handleOnModelLoadedCallback = () => {
    setModelLoadState(true)
    callUnityFunction('SetHighlightColor', { Value: '#0000FF' })
    callUnityFunction('ZoomCamera', { Value: 1 })
    if (session) {
      callUnityFunction('GetBuildingIDs', null)
    }
  }

  const postMessageEventListener = React.useCallback((event: MessageEvent) => {
    try {
      const {
        data: {
          eventType,
          payload: { eventName, eventData },
        },
      } = event

      if (eventType !== 'onUnityMessage') return
      if (eventName === 'OnLevelSelectedCallback') handleLevelClick(eventData)
      if (eventName === 'OnModelLoadedCallback') handleOnModelLoadedCallback()
      if (eventName === 'GetBuildingIDsCallback')
        handleGetBuildingIDsCallback(eventData)
      if (eventName === 'GetLevelsCallback') handleGetLevelsCallback(eventData)
    } catch (error) {
      console.error('Incorrect format was given in mapping.')
    }
  }, [])

  const handleRotateCamera = (arg: boolean) => {
    if (!isModelLoaded) return
    if (arg) {
      callUnityFunction('AutoRotateNow', null)
      callUnityFunction('EnableAutoRotation', null)
    } else {
      callUnityFunction('DisableAutoRotation', null)
      callUnityFunction('ZoomCamera', { Value: INITIAL_ZOOM_VALUE / 10 })
    }
  }

  const handleRotateCameraBy = (settings: {
    direction: string
    triggerKey: string
  }) => {
    const { direction, triggerKey } = settings
    if (!isModelLoaded) return
    if (direction === '' || triggerKey === '') return
    if (triggerKey === rotateCameraByTriggerKey) return

    setRotateCameraByTrigger(triggerKey)
    const value = direction === 'left' ? -30 : 30
    callUnityFunction('RotateCameraBy', { Value: value })
  }

  const handleZoomCamera = (settings: {
    action: string
    triggerKey: string
  }) => {
    const { action, triggerKey } = settings
    if (!isModelLoaded) return
    if (action === '' || triggerKey === '') return
    if (triggerKey === zoomCameraByTriggerKey) return

    setZoomCameraByTrigger(triggerKey)
    const value = action === 'in' ? currentZoomValue - 2 : currentZoomValue + 2

    if (value > 10 || value < 0) return

    setCurrentZoomValue(value)
    callUnityFunction('ZoomCamera', { Value: value / 10 })
  }

  const getFilteredData = (): Array<string> => {
    const filteredLevelList: Array<string> = []

    const { levels } = building

    levels.forEach((level) => {
      level.data.forEach((unit: Unit) => {
        if (!filteredLevelList.includes(level.level) && unitFilter) {
          const response = filterUnitUtil(unit, unitFilter, showPrice)
          if (response) {
            filteredLevelList.push(level.level)
          }
        }
      })
    })

    return filteredLevelList
  }

  const resetHighlightedLevels = (): void =>
    callUnityFunction('ClearFilters', null)

  const highlightLevels = (filteredItems: Array<string>) => {
    if (filteredItems.length < 1) return

    if (activeBuilding === '') return

    const matchedItems: Array<{ building: string; level: any }> = []

    filteredItems.forEach((item: string) => {
      if (levelList.find((lvl: string) => lvl === item)) {
        matchedItems.push({
          building: activeBuilding,
          level: item,
        })
      }
    })

    if (matchedItems.length < 1) return

    setLevelHighlightState(true)

    callUnityFunction('HighlightLevels', {
      Value: matchedItems,
      Rotation: 15,
      Zoom: 1,
    })
  }

  const applyFilter = () => {
    const { levels } = building
    if (levels.length < 1) return

    const { apply } = unitFilter

    if (iframeRef.current?.contentWindow && isLevelHighlighted && !apply) {
      setLevelHighlightState(false)
      resetHighlightedLevels()
    }

    if (!apply) return

    resetHighlightedLevels()
    const filteredLevelList = getFilteredData()
    highlightLevels(filteredLevelList)
  }

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (!interactivePlan.areaView?.image && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload])

  React.useEffect(() => {
    const { building: buildingData, aspects } = buildingPayload
    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          aspects,
          activeLevel: buildingData[0]?.level,
        })
      )
    }
  }, [buildingPayload])

  React.useEffect(() => {
    applyFilter()
  }, [unitFilter])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: { unitFilter: unitFilterFirebase },
        envisionVR: {
          rotateCamera: rotateCameraFirebase,
          rotateCameraBy: rotateCameraByFirebase,
          zoomCamera: zoomCameraFirebase,
          activeBuilding: activeBuildingFirebase,
        },
      } = session

      dispatch(setFilter(unitFilterFirebase))
      setRemoteAppConnectionState(connected)

      setActiveBuilding(activeBuildingFirebase)
      if (activeBuildingFirebase !== '') {
        callUnityFunction('GetLevels', { Value: activeBuildingFirebase })
      }
      handleRotateCamera(rotateCameraFirebase)
      handleRotateCameraBy(rotateCameraByFirebase)
      handleZoomCamera(zoomCameraFirebase)
    }
  }, [session])

  React.useEffect(() => {
    window.addEventListener('message', postMessageEventListener, false)
    return () =>
      window.removeEventListener('message', postMessageEventListener, false)
  }, [])

  if (!hasEnvisionVR(envisionVRConfiguration))
    return <DataNotFound message="EnvisionVR has not configured properly." />

  return (
    <DataHandler
      payload={{
        ...buildingPayload,
        data: building.levels,
      }}
    >
      {!isRemoteAppConnected && (
        <>
          <div className="absolute left-5 top-5">
            <IdleTimeHandler>
              <div className="flex items-center gap-5">
                <Link to="vision">
                  <ArrowSquareSvg size="m" className="drop-shadow-70" />
                </Link>
                {/* {!hideFilter && (
                  <button
                    onClick={() => toggleFilter(!isFilterOpen)}
                    type="button"
                  >
                    <MenuToggleSvg size="m" className="drop-shadow-70" />
                  </button>
                )} */}
              </div>
            </IdleTimeHandler>
          </div>

          {/* <FilterPopup
            isOpen={isFilterOpen}
            toggle={toggleFilter}
          /> */}
        </>
      )}
      <iframe
        title="envisionVR"
        ref={iframeRef}
        src={envisionVRConfiguration.url}
        className="h-screen w-screen"
      ></iframe>
    </DataHandler>
  )
}
export default connect(
  ({
    projectIdentity,
    projectConfig: { showPrice, envisionVRConfiguration, hideFilter },
    firestore,
    interactivePlan,
    building,
    unitFilter,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    showPrice,
    envisionVRConfiguration,
    hideFilter,
    session: getSession(firestore),
    interactivePlan,
    building,
    unitFilter,
  })
)(EnvisionVR)
