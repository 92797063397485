import React from 'react'

import { SvgProps } from '@components/svg/types'

const VideoSvg = ({ width, height, className, size, styles }: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 100 100"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="3"
    >
      <g transform="translate(0,-952.36218)">
        <path d="m 22.999998,972.36219 c -8.292611,0 -14.9999995,6.70739 -14.9999995,15 l 0,30.00001 c 0,8.2926 6.7073885,15 14.9999995,15 l 54.000001,0 c 8.292609,0 15.000002,-6.7074 15.000002,-15 l 0,-30.00001 c 0,-8.29261 -6.707393,-15 -15.000002,-15 l -54,0 z m 0,2 54.000001,0 c 7.21921,0 13,5.78079 13,13 l 0,30.00001 c 0,7.2192 -5.78079,13 -13,13 l -54.000001,0 c -7.219205,0 -12.9999995,-5.7808 -12.9999995,-13 l 0,-30.00001 c 0,-7.21921 5.7807945,-13 12.9999995,-13 z m 19.84375,14 a 1.0001,1.0001 0 0 0 -0.84375,0.96875 l 0,26.00006 a 1.0001,1.0001 0 0 0 0.84375,1 1.0001,1.0001 0 0 0 0.65625,-0.125 l 22.000001,-13 a 1.0001,1.0001 0 0 0 0,-1.7188 L 43.499998,988.48719 a 1.0001,1.0001 0 0 0 -0.65625,-0.125 z m 1.15625,2.71875 19.03125,11.25006 -19.03125,11.2812 0,-22.53126 z"></path>
      </g>
    </svg>
  )
}

export default VideoSvg
