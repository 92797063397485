import 'firebase/compat/firestore'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { BrowserRouter as Router } from 'react-router-dom'

import App from '@src/app'

import Store from '@store/store'

import LoadingIndicator from './components/loading-indicator'
import './index.css'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <Provider store={Store.store}>
    <ReactReduxFirebaseProvider {...Store.FirebaseProps}>
      <Router>
        <App />
        <LoadingIndicator />
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
