import { CarouselProvider } from 'pure-react-carousel'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { setLocation } from '@store/actionSlices/location'
import type { RootStateFirebase, SessionMap } from '@store/types'

import DataHandler from '@components/data-handler'
import Footer from '@components/footer'
import GalleryHandler from '@components/gallery-handler'
import SlideTrigger from '@components/gallery-handler/slide-trigger'
import IdleTimeHandler from '@components/idle-time-handler'
import Navigation from '@components/navigation'

import {
  LocationData,
  selectFromResult,
  useGetLocationQuery,
} from '@api/location'

import getSession from '@utilities/firebase-util'

export interface LocationProps {
  session: SessionMap | undefined
  projectName: string
  location: Array<LocationData>
}

const Location = ({ session, projectName, location }: LocationProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const [activeLabel, setActiveLabel] = React.useState(0)
  const [isConnected, setIsConnected] = React.useState(false)
  const [transitionTime, setTransitionTime] = React.useState(1000)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const locationPayload = useGetLocationQuery(
    { projectName },
    { selectFromResult }
  )

  React.useEffect(() => {
    const { locations } = locationPayload

    if (location.length === 0 && locations.length > 0) {
      dispatch(setLocation(locations))
    }
  }, [locationPayload, location])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        location: {
          galleryControl: {
            activeTabIndex: activeTabIndexFirebase,
            transitionTime: transitionTimeFirebase,
            isPlaying: isPlayingFirebase,
          },
        },
      } = session
      setIsConnected(connected)
      setActiveLabel(activeTabIndexFirebase)
      setTransitionTime(transitionTimeFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  const carouselData = React.useMemo(
    () =>
      location.length > 0
        ? {
            slides: location[activeLabel].Images.map(
              (locContent) => locContent.imageSource
            ),
            labels: location[activeLabel].Images.map((locContent) => ({
              label: locContent.label,
              sublabel: locContent.distance,
            })),
          }
        : { slides: [], labels: [] },
    [location, activeLabel]
  )

  return (
    <div className="relative overflow-hidden bg-mainClassic">
      <Navigation />
      <Footer linkClasses={{ locationClass: 'underline' }} />
      <DataHandler
        payload={{
          ...locationPayload,
          data: location,
        }}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={location.length}
          dragEnabled={false}
          orientation="vertical"
          className="ipad-p:w-1100px"
          interval={transitionTime}
          isPlaying={isSlideShowPlaying}
        >
          {!isConnected && location.length > 0 && (
            <IdleTimeHandler>
              <div className="relative z-10">
                <div className="absolute left-8 flex h-screen items-center">
                  <SlideTrigger
                    type="label"
                    items={location.map((res: LocationData) => ({
                      text: res.label,
                    }))}
                    setCurrentSlide={setActiveLabel}
                    currentSlide={activeLabel}
                    dotContainerRef={dotContainerRef}
                  />
                </div>
              </div>
            </IdleTimeHandler>
          )}
          <TransitionGroup>
            <CSSTransition
              key={activeLabel}
              classNames="transition-toTop"
              timeout={500}
            >
              <GalleryHandler
                galleryName="location"
                galleryControl={session?.location.galleryControl}
                slides={carouselData.slides}
                labels={carouselData.labels}
                gradiant={0.1}
                isConnected={isConnected}
              />
            </CSSTransition>
          </TransitionGroup>
        </CarouselProvider>
      </DataHandler>
    </div>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    location,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    location,
  })
)(Location)
