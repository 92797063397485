import React from 'react'

import { SvgProps } from '@components/svg/types'

const MenuToggleSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '40'}
      height={height || '38'}
    >
      <path
        d="M 38.546 31.885 L 21.494 31.885 C 20.811 31.885 20.258 32.438 20.258 33.121 C 20.258 33.804 20.811 34.357 21.494 34.357 L 38.546 34.357 C 39.229 34.357 39.782 33.804 39.782 33.121 C 39.782 32.438 39.229 31.885 38.546 31.885 Z M 1.454 34.358 L 15.064 34.358 L 15.064 38.546 C 15.064 39.229 15.618 39.782 16.301 39.782 C 16.983 39.782 17.537 39.229 17.537 38.546 L 17.537 27.697 C 17.524 27.023 16.975 26.483 16.301 26.483 C 15.627 26.483 15.077 27.023 15.064 27.697 L 15.064 31.885 L 1.454 31.885 C 0.784 31.903 0.25 32.451 0.25 33.121 C 0.25 33.792 0.784 34.34 1.454 34.358 Z M 38.546 5.642 L 28.387 5.642 L 28.387 1.454 C 28.387 0.771 27.833 0.218 27.15 0.218 C 26.468 0.218 25.914 0.771 25.914 1.454 L 25.914 12.303 C 25.914 12.986 26.468 13.54 27.15 13.54 C 27.833 13.54 28.387 12.986 28.387 12.303 L 28.387 8.115 L 38.546 8.115 C 39.229 8.115 39.783 7.562 39.783 6.879 C 39.783 6.196 39.229 5.642 38.546 5.642 Z M 0.217 6.878 C 0.217 7.561 0.771 8.115 1.454 8.115 L 21.957 8.115 C 22.631 8.102 23.171 7.552 23.171 6.878 C 23.171 6.205 22.631 5.655 21.957 5.642 L 1.454 5.642 C 1.126 5.642 0.811 5.772 0.58 6.004 C 0.348 6.236 0.217 6.55 0.217 6.878 Z M 38.546 18.763 L 15.652 18.763 C 14.978 18.776 14.438 19.326 14.438 20 C 14.438 20.674 14.978 21.223 15.652 21.236 L 38.546 21.236 C 39.229 21.236 39.782 20.683 39.782 20 C 39.782 19.317 39.229 18.763 38.546 18.763 Z M 0.217 20 C 0.217 19.317 0.771 18.763 1.454 18.763 L 9.223 18.763 L 9.223 14.575 C 9.223 13.893 9.776 13.339 10.459 13.339 C 11.141 13.339 11.695 13.893 11.695 14.575 L 11.695 25.424 C 11.695 26.107 11.141 26.66 10.459 26.66 C 9.776 26.66 9.223 26.107 9.223 25.424 L 9.223 21.236 L 1.454 21.236 C 1.126 21.236 0.811 21.106 0.579 20.874 C 0.348 20.642 0.217 20.328 0.217 20 Z"
        fill={fill || 'hsl(0, 0%, 100%)'}
      ></path>
    </svg>
  )
}

export default MenuToggleSvg
