import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import type { ProjectIdentity, SessionMap } from '@store/types'

import { ProtectedRoutes, PublicRoutes } from '@components/route-guards'

import PageNotFound from '@pages/404'
import Area from '@pages/area'
import Brochure from '@pages/brochure'
import Design from '@pages/design'
import EnvisionVR from '@pages/envision-vr'
import ExternalViews from '@pages/external-views'
import InteractiveMap from '@pages/interactive-map'
import LevelView from '@pages/level-view'
import Location from '@pages/location'
import Login from '@pages/login'
import PanoramicView from '@pages/panoramic-view'
import Shortlisted from '@pages/shortlisted'
import SnaploaderView from '@pages/snaploader-view'
import Teams from '@pages/teams'
import ThreeDViewer from '@pages/three-d-viewer'
import VideoGallery from '@pages/video-gallery'
import Vision from '@pages/vision'

import FirebaseControlQuery from '@utilities/firebase-control-query'

import Building from './pages/building'

export interface RoutesProps {
  session: SessionMap
  mapdefault: SessionMap
  projectIdentity: ProjectIdentity
}

const Routes = ({ session, mapdefault, projectIdentity }: RoutesProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
    map: mapdefault,
  })
  const history = useHistory()
  const location = useLocation()

  const createSessionFireStore = ({
    sessionId,
    projectName,
  }: ProjectIdentity) => {
    if (sessionId && projectName && mapdefault) {
      firebaseControlQuery.createSession({ sessionId, projectName })
    }
  }

  const getTransitionBasedonRoute = () => {
    const paths = location.pathname.split('/')
    const currentPage = paths[2] ?? ''
    if (currentPage === 'vision') {
      return 'transition-flip'
    }
    if (
      currentPage === 'gallery' ||
      currentPage === 'location' ||
      currentPage === 'teams' ||
      currentPage === 'brochure' ||
      currentPage === 'video-gallery'
    ) {
      return 'transition-toTop'
    }
    return 'transition-fade'
  }

  useEffect(() => {
    if (session) {
      const { activeRoute, connected } = session
      if (connected && projectIdentity.projectName) {
        const paths = location.pathname.split('/')
        if (paths[2] !== activeRoute) {
          history.push(activeRoute)
        }
      }
    }
  }, [session, projectIdentity])

  useEffect(() => {
    if (!session) {
      const { sessionId } = projectIdentity
      if (sessionId) {
        createSessionFireStore(projectIdentity)
        history.push('vision')
      }
    }
  }, [session])

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames={getTransitionBasedonRoute()}
        timeout={500}
        unmountOnExit
      >
        <Switch location={location}>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/panoramic`}
            authenticationPath="/"
          >
            <PanoramicView />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/external-views`}
            authenticationPath="/"
          >
            <ExternalViews />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/area-view`}
            authenticationPath="/"
          >
            <Area />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/level-view`}
            authenticationPath="/"
          >
            <LevelView />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/building`}
            authenticationPath="/"
          >
            <Building />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/shortlisted`}
            authenticationPath="/"
          >
            <Shortlisted />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/gallery`}
            authenticationPath="/"
          >
            <Design />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/areaview-3d`}
            authenticationPath="/"
          >
            <ThreeDViewer />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/location`}
            authenticationPath="/"
          >
            <Location />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/vision`}
            authenticationPath="/"
          >
            <Vision />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/teams`}
            authenticationPath="/"
          >
            <Teams />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/brochure`}
            authenticationPath="/"
          >
            <Brochure />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/video-gallery`}
            authenticationPath="/"
          >
            <VideoGallery />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/interactive-map`}
            authenticationPath="/"
          >
            <InteractiveMap />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/snaploader-view`}
            authenticationPath="/"
          >
            <SnaploaderView />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/envision-vr`}
            authenticationPath="/"
          >
            <EnvisionVR />
          </ProtectedRoutes>
          <PublicRoutes
            exact
            path="/:projectid"
            authenticatedPath={`/${projectIdentity.projectName}/vision`}
          >
            <Login />
          </PublicRoutes>
          <PublicRoutes
            exact
            path="/"
            authenticatedPath={`/${projectIdentity.projectName}/vision`}
          >
            <Login />
          </PublicRoutes>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default Routes
