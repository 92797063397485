import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { RootStateFirebase, SessionMap } from '@store/types'

import IdleTimeHandler from '@components/idle-time-handler'

import getSession from '@utilities/firebase-util'

export interface footerProps {
  linkClasses?: {
    designClass?: string
    locationClass?: string
    teamClass?: string
  }
  isVisible?: boolean
  session: SessionMap | undefined
}

const Footer = ({ linkClasses, isVisible = true, session }: footerProps) => {
  const [isConnected, setIsConnected] = React.useState(false)

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  const shouldFooterVisible = () => (isConnected ? false : isVisible)

  return (
    <IdleTimeHandler>
      <ul
        className={`fixed -bottom-0.5 z-20 flex w-screen justify-between p-8 transition-opacity ${
          shouldFooterVisible() ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <li>
          <Link
            className={`inline-block border-none text-2xl text-white drop-shadow-70 ${linkClasses?.designClass}`}
            to="gallery"
          >
            Gallery
          </Link>
        </li>
        <li>
          <Link
            className={`inline-block border-none text-2xl text-white drop-shadow-70 ${linkClasses?.locationClass}`}
            to="location"
          >
            Location
          </Link>
        </li>
        <li>
          <Link
            className={`inline-block border-none text-2xl text-white drop-shadow-70 ${linkClasses?.teamClass}`}
            to="teams"
          >
            Team
          </Link>
        </li>
      </ul>
    </IdleTimeHandler>
  )
}

export default connect(({ firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
}))(Footer)
