import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { BuildingPageConfigInterface } from '@store/actionSlices/buildingPageConfig'
import { setFloorPlateGallery } from '@store/actionSlices/floorPlateGallery'
import { RootStateFirebase, SessionMap } from '@store/types'

import { DataNotFound } from '@components/data-handler/errors'
import { GalleryProvider } from '@components/gallery-handler'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'

import {
  FloorPlateGalleryData,
  GalleryData,
  selectFromResult as selectFromFloorPlateGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import { MappingCollection } from '@api/interactive-plan'

import getSession from '@utilities/firebase-util'

import FloorCarouselContent from './floor-carousel-content'
import FloorplanCanvas from './floorplan-canvas'

export interface FloorPlateProps {
  projectName: string
  session: SessionMap | undefined
  floorPlateGallery: Array<FloorPlateGalleryData>
  activeLevel: string
  activeBlock: string
  buildingPageConfig: BuildingPageConfigInterface
  floorPlan: MappingCollection
}

const FloorplateView = ({
  projectName,
  session,
  floorPlateGallery,
  activeLevel,
  activeBlock,
  buildingPageConfig,
  floorPlan,
}: FloorPlateProps) => {
  const dispatch = useDispatch()
  const isAppIdle = useIdleTimeHandler()

  const [isConnected, setIsConnected] = React.useState(false)

  const [skipFloorPlateGalleryApi, setSkipFloorPlateGalleryApi] =
    React.useState(true)
  const [floorPlateGalleryAssets, setFloorPlateGalleryAssets] = React.useState<
    Array<GalleryData>
  >([])

  const getActiveFloorPlateGallery = (
    galleryData: Array<FloorPlateGalleryData>,
    block?: string,
    level?: string
  ): Array<GalleryData> => {
    if (!block && !level) return []

    if (block && level) {
      return (
        galleryData.find(
          (value) =>
            value.block.toString() === block && value.level.toString() === level
        )?.assets || []
      )
    }

    if (level) {
      return (
        galleryData.find((value) => value.level.toString() === level)?.assets ||
        []
      )
    }

    return []
  }

  const floorPlateGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName },
    {
      selectFromResult: selectFromFloorPlateGalleryResult,
      skip: skipFloorPlateGalleryApi,
    }
  )

  React.useEffect(() => {
    if (floorPlateGallery.length === 0 && skipFloorPlateGalleryApi) {
      setSkipFloorPlateGalleryApi(false)
    }
  }, [floorPlateGallery])

  React.useEffect(() => {
    const { floorPlateGalleryData } = floorPlateGalleryPayload
    if (floorPlateGallery.length === 0 && floorPlateGalleryData.length > 0) {
      dispatch(setFloorPlateGallery(floorPlateGalleryData))
    }
  }, [floorPlateGalleryPayload])

  React.useEffect(() => {
    if (floorPlateGallery.length > 0) {
      const activeFloorPlateGallery = getActiveFloorPlateGallery(
        floorPlateGallery,
        activeBlock,
        activeLevel
      )
      setFloorPlateGalleryAssets(activeFloorPlateGallery)
    }
  }, [activeLevel, activeBlock, floorPlateGallery])

  const hasInteractiveMap = (): boolean => {
    const mapName = activeBlock ? `${activeBlock}-${activeLevel}` : activeLevel
    if (mapName in floorPlan) {
      return floorPlan[mapName]?.image !== ''
    }
    return false
  }

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  if (!hasInteractiveMap())
    return <DataNotFound message="Interactive floor plate is missing" />

  return (
    <>
      {floorPlateGalleryAssets.length > 0 ? (
        <GalleryProvider
          galleryName="floorGallery"
          naturalSlideWidth={150}
          naturalSlideHeight={125}
          dragEnabled={false}
          disableKeyboard
          className="w-full"
          originalSlides={floorPlateGalleryAssets}
          galleryControl={session?.floorGallery?.galleryControl}
          totalSlides={
            floorPlateGalleryAssets.length +
            (isConnected &&
            session?.floorGallery?.galleryControl?.transitionType !== 'fadeIn'
              ? 2
              : 1)
          }
          skipInfinite
        >
          {(slideContent) => (
            <FloorCarouselContent
              slideContent={slideContent}
              isFullWidth={buildingPageConfig.unitPanelFolded || isAppIdle}
              transitionType={
                session?.floorGallery?.galleryControl?.transitionType
              }
            />
          )}
        </GalleryProvider>
      ) : (
        <FloorplanCanvas
          session={session}
          isFullWidth={buildingPageConfig.unitPanelFolded || isAppIdle}
        />
      )}
    </>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    firestore,
    floorPlateGallery,
    building: { activeLevel, activeBlock },
    buildingPageConfig,
    interactivePlan: { floorplan: floorPlan },
  }: RootStateFirebase) => ({
    projectName,
    session: getSession(firestore),
    floorPlateGallery,
    activeLevel,
    activeBlock,
    buildingPageConfig,
    floorPlan,
  })
)(FloorplateView)
