import { fabric } from 'fabric'

import { Point, Size } from '@api/interactive-plan'

import { getRelativePostition, getScaledAttribute } from './canvas-util'

export interface PolyParams {
  coordinates: Point
  polyCoordinates?: Point
  points: Array<Point>
  bgSize: Size
  size: Size
  polySize?: Size
}
const Polygon = (): {
  getPolyAttributes: (arg: Array<Point>) => {
    width: number
    height: number
  }
  createPolyGroup: (arg: PolyParams) => fabric.Group
} => {
  const GroupBase = {
    hasBorders: false,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    objectCaching: false,
    originX: 'center',
    originY: 'center',
  }

  const PolyBase = {
    fill: 'rgba(255, 255, 255, 0.1)',
    strokeWidth: 0,
    scaleX: 1,
    scaleY: 1,
    objectCaching: false,
    transparentCorners: false,
    hasBorders: false,
    hasControls: false,
  }

  const getPolyAttributes = (points: Array<Point>) => {
    const polygon = new fabric.Polygon(points, PolyBase)
    return {
      width: polygon?.width || 0,
      height: polygon?.height || 0,
    }
  }

  const createPolyGroup = ({
    size,
    bgSize,
    polySize,
    coordinates,
    points,
    polyCoordinates,
  }: PolyParams) => {
    const polyAttrib = getPolyAttributes(points)

    const polygon = new fabric.Polygon(points, {
      ...PolyBase,
      scaleX:
        getScaledAttribute(polySize?.width || size.width, bgSize.width) /
        polyAttrib.width,
      scaleY:
        getScaledAttribute(polySize?.height || size.height, bgSize.height) /
        polyAttrib.height,
      opacity: 0.1,
    })

    const polyGroup = new fabric.Group([polygon], {
      ...GroupBase,
      name: 'PolyGroup',
      width: getScaledAttribute(polySize?.width || size.width, bgSize.width),
      height: getScaledAttribute(
        polySize?.height || size.height,
        bgSize.height
      ),
      left: getRelativePostition(
        coordinates.x,
        polyCoordinates?.x || 0,
        size.width,
        bgSize.width
      ),
      top: getRelativePostition(
        coordinates.y,
        polyCoordinates?.y || 0,
        size.height,
        bgSize.height
      ),
    })

    if (polyCoordinates) {
      polyGroup.set({
        originX: 'left',
        originY: 'top',
      })
    }

    return polyGroup
  }

  return {
    createPolyGroup,
    getPolyAttributes,
  }
}

export default Polygon
