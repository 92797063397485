import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { filterShortlist, removeShortlist } from '@store/actionSlices/shortlist'
import { RootStateFirebase } from '@store/types'

import Container from '@components/container'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import ConfirmationModal from '@components/modals'
import Navigation from '@components/navigation'
import LeftSidePanel from '@components/side-panel'
import { ShortListSvg } from '@components/svg'

import { Metas } from '@api/building'
import { Shortlist } from '@api/shortlisted'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'

import ShortlistForm from './shortlist-form'
import { FormDataType } from './shortlist-form/shortlist-form'

export interface ShortlistedProps {
  shortlist: Array<Shortlist>
  projectId: string
  showPrice: boolean
}

const Shortlisted = ({ shortlist, projectId, showPrice }: ShortlistedProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isAppIdle = useIdleTimeHandler()

  const [showConfirmationModal, toggleConfirmationModal] = React.useState(false)
  const [confirmationModalMessage, setConfirmationModalMessage] =
    React.useState('')
  const [confirmationModalAction, setConfirmationModalAction] =
    React.useState('')
  const [confirmationModalActionArgument, setConfirmationModalActionArgument] =
    React.useState<Shortlist>()

  const handleClearAllShortlist = () => {
    dispatch(removeShortlist())
    history.goBack()
  }

  const handleBackClick = () => {
    history.goBack()
  }

  const sideOption = (
    <div className="fixed right-0 flex text-2xl text-white">
      <button
        type="button"
        onClick={() => handleClearAllShortlist()}
        className="mr-2 underline"
      >
        Clear All
      </button>
      <button
        type="button"
        className="underline"
        onClick={() => handleBackClick()}
      >
        Back ({shortlist.length})
      </button>
      <ShortListSvg className="h-10" />
    </div>
  )

  const [formData, setFormData] = React.useState<FormDataType>({
    name: '',
    mobile: '',
    email: '',
    agent: '',
    agentMobile: '',
    agentEmail: '',
    documents: [],
  })

  const setUnitUrl = (metas: Metas) => {
    const { floorImage, viewLineImages } = metas

    if (floorImage) {
      return `${projectId}/floorplans/preview/${floorImage}`
    }
    if (viewLineImages && viewLineImages.length > 0) {
      const [viewLineImage] = viewLineImages
      return `${projectId}/views/${viewLineImage.src}`
    }
    return ''
  }

  const removeUnitFromShortlist = (res: Shortlist) => {
    setConfirmationModalMessage(`Do you want to remove unit ${res.name}?`)
    setConfirmationModalActionArgument(res)
    toggleConfirmationModal(true)
  }

  React.useEffect(() => {
    if (
      confirmationModalAction === 'confirmed' &&
      confirmationModalActionArgument !== undefined
    ) {
      dispatch(filterShortlist(confirmationModalActionArgument))
    }
    setConfirmationModalActionArgument(undefined)
    setConfirmationModalAction('')
  }, [confirmationModalAction])

  return (
    <>
      <Navigation
        {...{ sideOption, includePopup: false, classes: 'pt-3 pb-5 pr-3 pl-7' }}
      />
      <Container
        className={`bg-secondaryColour flex transition-size-spacing duration-300 ease-in-out ${
          !isAppIdle ? 'pt-18' : 'pt-0'
        }`}
      >
        <LeftSidePanel>
          <ShortlistForm
            shortlistUnits={shortlist}
            formData={formData}
            setFormData={setFormData}
            showPrice={showPrice}
          />
        </LeftSidePanel>
        <div
          className="h-full flex-auto overflow-y-auto bg-cover"
          style={{
            ...setBackground(
              AssetHandler({
                url: '/images/devsuite-bg.jpg',
                type: 'new',
                noSpliceUrl: true,
              }),
              {
                gradiant: 0.75,
              }
            ),
          }}
        >
          <div className="grid grid-cols-1 gap-12 p-10 px-32 lg:grid-cols-2 lg:gap-6 lg:p-4 lg:px-10 xl:grid-cols-3 2xl:grid-cols-5">
            {shortlist.map((res: Shortlist) => (
              <div
                key={res.id}
                className="relative flex h-80 w-full flex-col lg:h-56 xl:h-60"
              >
                <button
                  type="button"
                  className="absolute right-0 top-0 z-10 h-full max-h-10 w-10 bg-white text-center leading-9"
                  onClick={() => removeUnitFromShortlist(res)}
                >
                  &mdash;
                </button>
                <div className="h-72 py-5 lg:h-52">
                  <img
                    className="h-full w-full"
                    src={AssetHandler({
                      url: setUnitUrl(res.metas),
                      type: 'legacy',
                      noSpliceUrl: true,
                    })}
                    alt={res.name}
                  />
                </div>
                <div className="absolute bottom-16 right-1 rounded-2xl bg-black px-4 py-1 lg:bottom-12 xl:bottom-16">
                  <span className="text-xl text-white">
                    {res.metas.productShort}
                  </span>
                </div>
                <div className="flex justify-end">
                  <span className="text-3xl text-white lg:text-2xl">
                    Unit {res.name}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ConfirmationModal
          isVisible={showConfirmationModal}
          message={confirmationModalMessage}
          getModalAction={setConfirmationModalAction}
          toggleModal={toggleConfirmationModal}
          okButtonText="Yes"
          cancelButtonText="No"
        />
      </Container>
    </>
  )
}

export default connect(
  ({
    shortlist,
    projectIdentity: { projectId },
    projectConfig: { showPrice },
  }: RootStateFirebase) => ({
    shortlist,
    projectId,
    showPrice,
  })
)(Shortlisted)
