import { useDispatch } from 'react-redux'

import { interactiveMapApi } from '@src/api/interactive-map'
import { removeInteractiveMap } from '@src/store/actionSlices/interactiveMap'

import { removeBedConfig } from '@store/actionSlices/bedConfig'
import { removeBrochure } from '@store/actionSlices/brochure'
import { resetBuilding } from '@store/actionSlices/building'
import { removeDesign } from '@store/actionSlices/design'
import { removeExternalViews } from '@store/actionSlices/externalViews'
import { removeFloorPlateGallery } from '@store/actionSlices/floorPlateGallery'
import { removeInteractivePlan } from '@store/actionSlices/interactivePlan'
import { removeLocation } from '@store/actionSlices/location'
import { removePanoramicData } from '@store/actionSlices/panoramic'
import { removeProjectConfig } from '@store/actionSlices/projectConfig'
import { removeShortlist } from '@store/actionSlices/shortlist'
import { removeTeams } from '@store/actionSlices/teams'
import { removeFilter } from '@store/actionSlices/unitFilter'
import { removeUnitGallery } from '@store/actionSlices/unitGallery'
import { removeVideoGallery } from '@store/actionSlices/videoGallery'
import { resetVision } from '@store/actionSlices/vision'

import { bedConfigApi } from '@api/bed-config'
import { brochureApi } from '@api/brochure'
import { buildingApi } from '@api/building'
import { configApi } from '@api/config'
import { designApi } from '@api/design'
import { externalViewsApi } from '@api/external-views'
import { floorPlateGalleryApi } from '@api/floor-plate-gallery'
import { interactivePlanApi } from '@api/interactive-plan'
import { locationApi } from '@api/location'
import { panoramicApi } from '@api/panoramic'
import { teamsApi } from '@api/teams'
import { unitGalleryApi } from '@api/unit-gallery'
import { videoGalleryApi } from '@api/video-gallery'
import { visionApi } from '@api/vision'

const ResetUtil = () => {
  const dispatch = useDispatch()

  const resetStore = () => {
    dispatch(removeProjectConfig())
    dispatch(resetBuilding())
    dispatch(removeBedConfig())
    dispatch(removeDesign())
    dispatch(removeInteractivePlan())
    dispatch(removeLocation())
    dispatch(removeShortlist())
    dispatch(removeTeams())
    dispatch(removeFilter())
    dispatch(resetVision())
    dispatch(removeBrochure())
    dispatch(removePanoramicData())
    dispatch(removeVideoGallery())
    dispatch(removeFloorPlateGallery())
    dispatch(removeExternalViews())
    dispatch(removeUnitGallery())
    dispatch(removeInteractiveMap())
  }

  const resetApiState = () => {
    dispatch(configApi.util.resetApiState())
    dispatch(buildingApi.util.resetApiState())
    dispatch(bedConfigApi.util.resetApiState())
    dispatch(designApi.util.resetApiState())
    dispatch(interactivePlanApi.util.resetApiState())
    dispatch(locationApi.util.resetApiState())
    dispatch(teamsApi.util.resetApiState())
    dispatch(visionApi.util.resetApiState())
    dispatch(brochureApi.util.resetApiState())
    dispatch(panoramicApi.util.resetApiState())
    dispatch(videoGalleryApi.util.resetApiState())
    dispatch(externalViewsApi.util.resetApiState())
    dispatch(floorPlateGalleryApi.util.resetApiState())
    dispatch(unitGalleryApi.util.resetApiState())
    dispatch(interactiveMapApi.util.resetApiState())
  }

  const resetLocalStorage = () => {
    localStorage.removeItem('buildingApiData')
    localStorage.removeItem('configApiData')
    localStorage.removeItem('designApiData')
    localStorage.removeItem('interactivePlanApiData')
    localStorage.removeItem('interactiveMapData')
    localStorage.removeItem('locationApiData')
    localStorage.removeItem('teamsApiData')
    localStorage.removeItem('visionApiData')
    localStorage.removeItem('brochureApiData')
    localStorage.removeItem('bedConfigApiData')
    localStorage.removeItem('panoramicApiData')
    localStorage.removeItem('videoGalleryApiData')
    localStorage.removeItem('floorPlateGalleryApiData')
    localStorage.removeItem('externalViewsData')
    localStorage.removeItem('buildingPageConfig')
    localStorage.removeItem('releaseInfo')
    localStorage.removeItem('unitGalleryApiData')
  }

  return {
    resetStore,
    resetApiState,
    resetLocalStorage,
  }
}

export default ResetUtil
