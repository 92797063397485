import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { BuildingInterface } from '@store/actionSlices/building'
import { setPanoramicData } from '@store/actionSlices/panoramic'
import { RootStateFirebase, SessionMap } from '@store/types'

import DataHandler from '@components/data-handler'
import PannellumView from '@components/showcase-pannellum'
import {
  PannellumDataInterface,
  PannellumViewRefInterface,
} from '@components/showcase-pannellum/types'
import { ArrowSquareSvg } from '@components/svg'

import { selectFromResult, useGetPanoramicQuery } from '@api/panoramic'

import getSession from '@utilities/firebase-util'

export interface PanoramicViewProps {
  projectName: string
  panoramic: Array<PannellumDataInterface>
  session: SessionMap | undefined
  building: BuildingInterface
}

const PanoramicView = ({
  projectName,
  panoramic,
  session,
  building,
}: PanoramicViewProps) => {
  const dispatch = useDispatch()

  const pannellumViewRef = React.useRef<PannellumViewRefInterface>()

  const [isConnected, setIsConnected] = React.useState(false)
  const [scene, setScene] = React.useState('')
  const [pitch, setPitch] = React.useState<number | undefined>(undefined)
  const [yaw, setYaw] = React.useState<number | undefined>(undefined)
  const [rotationSpeed, setRotationSpeed] = React.useState<number | undefined>(
    undefined
  )

  const panoramicPayload = useGetPanoramicQuery(
    { projectName },
    { selectFromResult }
  )

  const findPanoramic = (
    type: 'unit' | 'level' | 'block',
    targetName: string
  ) =>
    panoramic.find(
      (data) => data.type === type && data.name.toString() === targetName
    )

  const getActivePanoramic = () => {
    if (building.activeUnit !== '') {
      return findPanoramic('unit', building.activeUnit.toString())
    }

    if (building.activeLevel !== '') {
      return findPanoramic('level', building.activeLevel.toString())
    }

    if (building.activeBlock !== '') {
      return findPanoramic('block', building.activeBlock.toString())
    }

    return null
  }

  React.useEffect(() => {
    const { panoramic: panoramicData, isLoaded } = panoramicPayload
    if (panoramic.length === 0 && isLoaded && panoramicData.length > 0) {
      dispatch(setPanoramicData(panoramicData))
    }
  }, [panoramicPayload, panoramic])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        panoramic: {
          galleryControl: { activeItemIndex: activeItemIndexFirebase },
          pitch: pitchFirebase,
          yaw: yawFirebase,
          rotationSpeed: rotationSpeedFirebase,
        },
      } = session
      setIsConnected(connected)
      setRotationSpeed(rotationSpeedFirebase)
      if (panoramic[activeItemIndexFirebase]) {
        setScene(panoramic[activeItemIndexFirebase].name)
      }
      setPitch(pitchFirebase)
      setYaw(yawFirebase)
    }
  }, [session])

  React.useEffect(() => {
    if (pitch !== undefined) {
      pannellumViewRef?.current?.setPitch(pitch)
    }
  }, [pitch])

  React.useEffect(() => {
    if (yaw !== undefined) {
      pannellumViewRef?.current?.setYaw(yaw)
    }
  }, [yaw])

  React.useEffect(() => {
    if (rotationSpeed !== undefined) {
      if (rotationSpeed === 0) {
        pannellumViewRef?.current?.stopAutoRotate()
      } else {
        pannellumViewRef?.current?.startAutoRotate(rotationSpeed)
      }
    }
  }, [rotationSpeed])

  React.useEffect(() => {
    if (scene !== '') {
      pannellumViewRef?.current?.loadScene(scene)
    }
  }, [scene])

  return (
    <>
      {!isConnected && (
        <div className="relative z-50">
          <Link to="building" className="absolute left-5 top-5 drop-shadow-70">
            <ArrowSquareSvg size="m" />
          </Link>
        </div>
      )}
      <DataHandler
        payload={{
          ...panoramicPayload,
          data: panoramic,
        }}
      >
        <PannellumView
          ref={pannellumViewRef}
          payload={panoramic}
          defaultScene={getActivePanoramic()?.name || ''}
        />
      </DataHandler>
    </>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    panoramic,
    firestore,
    building,
  }: RootStateFirebase) => ({
    projectName,
    panoramic,
    session: getSession(firestore),
    building,
  })
)(PanoramicView)
