import { Dot } from 'pure-react-carousel'
import React from 'react'

import { LabelInterface } from '@store/types'

export interface LabelDotInterface {
  item: LabelInterface
  index: number
  currentSlide: number
  setCurrentSlide: (arg: number) => void
}

const LabelDot = ({
  item,
  index,
  currentSlide,
  setCurrentSlide,
}: LabelDotInterface) => (
  <Dot
    className={`mb-4 w-auto rounded px-4 py-2 shadow-lg transition-all ${
      currentSlide === index
        ? 'bg-white text-xl'
        : 'bg-gray-600 bg-opacity-50 text-sm text-white'
    }`}
    slide={index}
    onClick={() => setCurrentSlide(index)}
  >
    {item.text}
  </Dot>
)

export default LabelDot
