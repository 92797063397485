import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setVideoGallery } from '@store/actionSlices/videoGallery'
import { RootStateFirebase, SessionMap } from '@store/types'

import DataHandler from '@components/data-handler'
import Footer from '@components/footer'
import { GalleryProvider } from '@components/gallery-handler'
import Navigation from '@components/navigation'

import {
  VideoGalleryData,
  selectFromResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

import getSession from '@utilities/firebase-util'

import CarouselContent from './carousel-content'

export interface VideoGalleryProps {
  projectName: string
  videoGallery: Array<VideoGalleryData>
  session: SessionMap | undefined
}

const VideoGallery = ({
  projectName,
  videoGallery,
  session,
}: VideoGalleryProps) => {
  const dispatch = useDispatch()

  const [ctrlsHidden, setCtrlsVisibility] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)

  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName },
    { selectFromResult }
  )

  React.useEffect(() => {
    const { videoGalleryData } = videoGalleryPayload

    if (videoGallery.length === 0 && videoGalleryData.length > 0) {
      dispatch(setVideoGallery(videoGalleryData))
    }
  }, [videoGalleryPayload, videoGallery])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  return (
    <div className="bg-mainColour overflow-hidden">
      <Navigation isVisible={!ctrlsHidden} />
      <DataHandler
        payload={{
          ...videoGalleryPayload,
          data: videoGallery,
        }}
      >
        <GalleryProvider
          galleryName="videoGallery"
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          dragEnabled={false}
          disableKeyboard
          className={`ipad-p:w-1100px ${ctrlsHidden ? 'relative z-50' : ''}`}
          originalSlides={videoGallery}
          galleryControl={session?.videoGallery?.galleryControl}
        >
          {(slideContent) => (
            <CarouselContent
              slideContent={slideContent}
              ctrlsHidden={isConnected ? true : ctrlsHidden}
              getCtrlVisibility={setCtrlsVisibility}
              transitionType={
                session?.videoGallery?.galleryControl?.transitionType
              }
            />
          )}
        </GalleryProvider>
      </DataHandler>
      <Footer isVisible={!ctrlsHidden} />
    </div>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    videoGallery,
    firestore,
  }: RootStateFirebase) => ({
    projectName,
    videoGallery,
    session: getSession(firestore),
  })
)(VideoGallery)
