import React from 'react'
import { useLocation } from 'react-router'

import { ChevronSvg, CompassRegularSvg } from '@components/svg'

export interface AspectProps {
  aspects: string[]
  aspect: string
  onChange: (arg: string) => void
}

const AspectList = ({ aspects, aspect, onChange }: AspectProps) => {
  const location = useLocation()
  const [isAspectsOpen, toggleAspect] = React.useState(false)
  const [selectedAspect, setSelectedAspect] = React.useState(aspect)

  const checkIsPageBuilding = () => {
    const currentPage = location.pathname.split('/')[2] ?? ''
    return currentPage === 'building'
  }

  const handleAspectSelect = (aspectOption: string) => {
    setSelectedAspect(aspectOption)
    onChange(aspectOption)
    toggleAspect(false)
  }

  React.useEffect(() => {
    if (aspect) {
      setSelectedAspect(aspect)
    }
  }, [aspect])

  return (
    <div className="z-10 mx-auto flex-initial overflow-hidden transition-all duration-300 ease-in-out">
      <div
        className="flex h-12 flex-row items-center justify-between rounded-md bg-white px-4"
        role="button"
        tabIndex={0}
        onClick={() => toggleAspect(!isAspectsOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            toggleAspect(true)
          }
        }}
      >
        <div
          className="h-4 w-4 text-gray-500"
          style={{ transform: 'rotate(-45deg)' }}
        >
          <CompassRegularSvg className="text-gray-400" width="30" height="30" />
        </div>
        <span className="w-2/3 items-center pl-2 text-gray-500 2xl:col-span-2 2xl:inline-flex">
          {selectedAspect || 'Aspect'}
        </span>
        <div className="h-7 w-7 text-gray-400">
          <ChevronSvg
            className="text-gray-500"
            rotate={isAspectsOpen ? 'top' : 'down'}
            width="30"
            height="30"
          />
        </div>
      </div>
      <div
        className={`no-scrollbar absolute block w-full overflow-y-auto rounded-md text-left transition-all duration-500 ease-in-out ${
          !isAspectsOpen
            ? 'h-0'
            : `h-44 py-2 sm:h-16 md:h-52 ipad-p:h-96 ${
                checkIsPageBuilding() ? 'xl:h-80' : 'xl:h-96'
              }`
        }`}
      >
        <div className="divide-y rounded-md bg-white px-4 py-2">
          {aspects.length &&
            aspects.map((aspectOption, index) => (
              <div
                key={aspectOption}
                className={`py-2 pl-10 pr-2 text-gray-500 ${
                  selectedAspect === aspectOption
                    ? 'active bg-gray-300 text-gray-700'
                    : 'bg-white'
                }`}
                role="button"
                tabIndex={index}
                onClick={() => handleAspectSelect(aspectOption)}
                onKeyDown={(e) =>
                  e.key === 'Enter' && handleAspectSelect(aspectOption)
                }
              >
                {aspectOption}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default AspectList
