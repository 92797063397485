import React from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'

import {
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import {
  ArrowsExpandSvg,
  BufferSvg,
  DeviceSvg,
  MinusCircleSvg,
  MuteSvg,
  PauseSvg,
  PlaySvg,
  PlayerStopSvg,
  PlusCircleSvg,
  VolumeSvg,
} from '@components/svg'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'

import ScrubBar from './scrub-bar'

export interface ProgressProps {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

export interface PlayerProps {
  galleryName: string
  videoUrl: string
  getPlayerState: (arg: React.SetStateAction<boolean>) => void
  playerControls?: PlayerControlInterface | undefined
  isActive?: boolean
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const Player = ({
  galleryName,
  videoUrl,
  getPlayerState,
  playerControls,
  isActive,
  projectIdentity,
  session,
}: PlayerProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const playerWrapperRef = React.createRef<HTMLDivElement>()
  const playerRef = React.createRef<ReactPlayer>()
  const [isPlayerVisible, setPlayerVisibility] = React.useState(false)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [isFullscreen, setFullScreen] = React.useState(false)
  const [volume, setVolume] = React.useState(0.1)
  const [muteVideo, setMuteVideo] = React.useState(false)
  const [isControlsActive, setControlStatus] = React.useState(false)
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>()
  const [videoLength, setVideoLength] = React.useState(0)
  const [isBuffing, setBuffing] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const progressInitial: ProgressProps = {
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  }
  const [progress, setProgress] = React.useState<ProgressProps>(progressInitial)
  const [scrubPositionInSeconds, setScrubPositionInSeconds] = React.useState(-1)

  const handleControlStatus = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (!isControlsActive) {
      setControlStatus(true)
    }
    const timeout = setTimeout(() => {
      setControlStatus(false)
    }, 3000)
    setTimeoutId(timeout)
  }

  const volumeUp = () => {
    if (volume < 1) {
      setVolume(Math.round((volume + 0.1) * 100) / 100)
    }
  }

  const volumeDown = () => {
    if (volume > 0) {
      setVolume(Math.round((volume - 0.1) * 100) / 100)
    }
  }

  const handleClickFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
      setFullScreen(false)
    } else {
      document.documentElement.requestFullscreen()
      setFullScreen(true)
    }
  }

  React.useEffect(() => {
    if (isActive && playerControls) {
      setIsPlaying(playerControls.playerState === 'play')
      getPlayerState(playerControls.playerState === 'play')
      setPlayerVisibility(playerControls.isPlayerVisible)
      setVolume(playerControls.volume)
      setMuteVideo(playerControls.isMuted)
      setScrubPositionInSeconds(playerControls.scrubPositionInSeconds)
    }
  }, [playerControls])

  React.useEffect(() => {
    if (session && scrubPositionInSeconds >= 0) {
      playerRef.current?.seekTo(scrubPositionInSeconds)
    }
  }, [scrubPositionInSeconds])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)
    }
  }, [session])

  React.useEffect(
    () => () => {
      setTimeoutId(undefined)
    },
    []
  )

  const handleBuffer = (videoState: boolean) => {
    setBuffing(videoState)
    if (session) {
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.isBuffering`]: videoState,
      })
    }
  }

  const handleDuration = (length: number) => {
    setVideoLength(length)
    if (session) {
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.videoLengthInSeconds`]: length,
      })
    }
  }

  const handleOnPlay = () => {
    if (session) {
      const currentTime = playerRef.current?.getCurrentTime() || 0
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.currentVideoPositionInSeconds`]:
          Math.round(currentTime),
      })
    }
  }

  const handleOnSeek = (seekValue: number) => {
    if (session) {
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.currentVideoPositionInSeconds`]:
          seekValue,
      })
    }
  }

  return (
    <>
      {!isPlayerVisible && (
        <div className="flex h-full items-center justify-center">
          <button
            type="button"
            onClick={() => {
              setPlayerVisibility(true)
              setIsPlaying(true)
            }}
            className="drop-shadow-70"
            data-testid="play-button"
          >
            <PlaySvg className="h-32 w-32 text-white" />
          </button>
        </div>
      )}

      {isPlayerVisible && (
        <div
          data-testid="react-player-container"
          className="relative z-10 w-full"
          ref={playerWrapperRef}
        >
          <ReactPlayer
            ref={playerRef}
            onStart={() => {
              getPlayerState(true)
            }}
            url={videoUrl}
            playing={isPlaying}
            width="w-full"
            height="100vh"
            style={{ background: '#000000' }}
            volume={volume}
            muted={muteVideo}
            onProgress={(p: ProgressProps) => setProgress(p)}
            onDuration={(length) => handleDuration(length)}
            onBuffer={() => handleBuffer(true)}
            onBufferEnd={() => handleBuffer(false)}
            onPlay={() => handleOnPlay()}
            onSeek={(seekValue) => handleOnSeek(seekValue)}
            loop
          />
          {isBuffing && (
            <div className="absolute bottom-1/2 left-1/2">
              <BufferSvg className="h-20 w-20 text-white drop-shadow-40" />
            </div>
          )}
          <div
            onMouseMove={handleControlStatus}
            onFocus={handleControlStatus}
            className={`absolute top-32 z-50 w-full transition-all delay-500 ease-in-out ${
              isControlsActive ? 'opacity-100' : 'opacity-0 '
            }`}
          >
            <div className="m-auto grid max-w-lg grid-cols-5 place-content-center rounded-full bg-black text-white">
              <button
                id="btnFullScreen"
                type="button"
                className="my-5 flex cursor-pointer justify-center"
                onClick={() => handleClickFullscreen()}
              >
                {isFullscreen ? (
                  <DeviceSvg size="s" rotate="top" />
                ) : (
                  <ArrowsExpandSvg size="m" />
                )}
              </button>
              <button
                type="button"
                className="my-5 flex cursor-pointer justify-center"
                onClick={() => {
                  getPlayerState(false)
                  setIsPlaying(false)
                  setPlayerVisibility(false)
                  setVolume(0.1)
                  setProgress(progressInitial)
                }}
              >
                <PlayerStopSvg size="m" />
              </button>

              <button
                type="button"
                className="my-5 flex cursor-pointer justify-center"
                onClick={() => {
                  setIsPlaying(!isPlaying)
                  getPlayerState(!isPlaying)
                }}
              >
                {isPlaying ? (
                  <PauseSvg size="m" />
                ) : (
                  <PlaySvg size="m" stroke={1} />
                )}
              </button>
              <div className="flex justify-center">
                <button
                  type="button"
                  className={`my-5 flex cursor-pointer justify-center transition-all delay-300 ease-in-out ${
                    volume === 0 ? 'opacity-50' : 'opacity-100'
                  }`}
                  disabled={volume === 0}
                  onClick={volumeDown}
                >
                  <MinusCircleSvg size="m" />
                </button>
                <button
                  type="button"
                  className={`my-5 flex cursor-pointer justify-center transition-all delay-300 ease-in-out ${
                    volume === 1 ? 'opacity-50' : 'opacity-100'
                  }`}
                  disabled={volume === 1}
                  onClick={volumeUp}
                >
                  <PlusCircleSvg size="m" />
                </button>
              </div>
              <button
                type="button"
                className="my-5 flex cursor-pointer justify-center"
                onClick={() => setMuteVideo(!muteVideo)}
              >
                {muteVideo ? <MuteSvg size="m" /> : <VolumeSvg size="m" />}
              </button>
            </div>
          </div>
          {!isConnected && videoLength > 0 && (
            <div
              className={`absolute bottom-0 z-50 h-36 w-full bg-gradient-to-t from-gray-800 transition-all delay-500 ease-in-out ${
                isPlaying ? 'pt-24' : 'pt-8'
              } px-8 text-white ${
                isControlsActive ? 'opacity-100' : 'opacity-0'
              }`}
              onMouseMove={handleControlStatus}
              onFocus={handleControlStatus}
            >
              <ScrubBar
                playerReference={playerRef}
                videoLengthInMilliseconds={videoLength * 1000}
                isPlaying={isPlaying}
                isBuffing={isBuffing}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default connect(({ firestore, projectIdentity }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Player)
