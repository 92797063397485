import React from 'react'

import { SvgProps } from '@components/svg/types'

const ChevronSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    top: '0deg',
    down: '180deg',
    right: '90deg',
    left: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '48'}
      height={height || '48'}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ChevronSvg
