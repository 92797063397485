import { fabric } from 'fabric'

import { Point, Size } from '@api/interactive-plan'

import { getRelativePostition, getScaledAttribute } from './canvas-util'

export interface RectParams {
  size: Size
  bgSize: Size
  coordinates: Point
  rectSize?: Size
  rectAngle?: number
  rectCoordinates?: Point
}

const Rect = (): {
  createRect: (arg: RectParams) => fabric.Rect
} => {
  const RectBase = {
    fill: '#cccccc',
    width: 200,
    height: 5,
    objectCaching: false,
    stroke: 'none',
    strokeWidth: 0,
  }

  const createRect = ({
    size,
    bgSize,
    rectSize,
    rectAngle,
    coordinates,
    rectCoordinates,
  }: RectParams) => {
    const rect = new fabric.Rect({
      ...RectBase,
      left: getRelativePostition(
        coordinates.x,
        rectCoordinates?.x || 0,
        size.width,
        bgSize.width
      ),
      top: getRelativePostition(
        coordinates.y,
        rectCoordinates?.y || 0,
        size.height,
        bgSize.height
      ),
      width: getScaledAttribute(rectSize?.width || 0, bgSize.width),
      height: getScaledAttribute(rectSize?.height || 0, bgSize.height),
      angle: rectAngle,
    })
    return rect
  }

  return {
    createRect,
  }
}

export default Rect
