import React from 'react'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'

export interface ContainerProps {
  children?: React.ReactNode
  style?: React.CSSProperties
  background?: {
    url: string
    type: 'legacy' | 'new'
    noSpliceUrl: boolean
    staticUrl?: boolean
  }
  gradiant?: number
  className?: string
}

const Container = ({
  style,
  children,
  background,
  gradiant = 0.5,
  className = '',
}: ContainerProps) => (
  <div
    className={`background-cover bg-mainColour ${className}`}
    style={{
      ...setBackground(background ? AssetHandler(background) : '', {
        gradiant,
      }),
      ...style,
    }}
  >
    {children}
  </div>
)

export default Container
